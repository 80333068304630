import colors from "../../colors";
import fonts from "../../fonts";

const global = {
  button: {
    "& > *": {
      margin: "0px",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    outline: "none !important",
    textTransform: "none",
  },
  iconbutton: { "& > *": { margin: "0px" }, outline: "none !important" },
  list: { "& > *": { margin: "0px" }, outline: "none !important" },
};

export const dialog = {
  toolbar: {
    position: "relative",
    backgroundColor: "#303064",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
};

export const button = {
  type1: {
    ...global.button,
    width: "100%",
    padding: "0px 20px",
    textAlign: "center",
    height: "36px",
    minWidth: "100px",
    border: `2px solid ${colors.company1}`,
    backgroundColor: colors.company2,
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.company4,
      borderColor: colors.company3,
    },
  },
};

export const textfield = {
  type1: {
    width: "100%",
    "& .MuiInput-underline:after": {
      border: "none",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": { border: "none" },
      "&:hover fieldset": { border: "none" },
      "&.Mui-focused fieldset": {
        border: "none",
      },
      backgroundColor: "#ffffff",
      padding: 0,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 10px 7px",
    },
    "& .MuiButtonBase-root": {
      margin: "0px",
    },
    "& .MuiInputBase-input": {
      fontFamily: "NanumSquareR",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      borderRadius: 5,
    },
    "& .MuiInputBase-input[type=password]": {
      fontFamily: "NanumGothic",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      borderRadius: 5,
    },
  },
  type2: {
    width: "100%",
    "& .MuiInput-underline:after": {
      border: `1px solid ${colors.company}`,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": { border: `1px solid ${colors.company}` },
      "&:hover fieldset": { border: `1px solid ${colors.company}` },
      "&.Mui-focused fieldset": {
        border: `1px solid ${colors.company}`,
      },
      backgroundColor: "#ffffff",
      padding: 0,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 10px 7px",
    },
    "& .MuiButtonBase-root": {
      margin: "0px",
    },
    "& .MuiInputBase-input": {
      fontFamily: "NanumSquareR",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      borderRadius: 5,
    },
    "& .MuiInputBase-input[type=password]": {
      fontFamily: "NanumGothic",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      borderRadius: 5,
    },
  },
};

export const paper = {
  type1: {
    width: "100%",
    margin: "0 5px",
    // borderRadius: 5,
    backgroundColor: colors.company1,
  },
};

export const datepicker = {
  type1: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: colors.company4,
      "&.Mui-focused": {
        borderColor: colors.company3,
      },
    },
    "& .MuiInputBase-root ": {
      width: "100%",
      borderRadius: "10px",
      color: colors.white,
      borderColor: colors.company4,
      backgroundColor: colors.company1,
      "&:hover fieldset": {
        borderColor: colors.company3,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.company3,
      },
      ...fonts.b_14_w,
    },
    "& .MuiInputBase-input ": {
      padding: "10px 10px 8px",
      borderRadius: "10px",
      backgroundColor: colors.company1,
    },
    "& .MuiButtonBase-root": {
      color: colors.white,
    },
  },
};
