import colors from "./colors";

const styles = {
  app: {
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    backgroundColor: colors.company,
  },
};

export default styles;
