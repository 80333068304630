import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  Button,
  IconButton,
  Typography,
  List,
  ListItem,
  Backdrop,
  CircularProgress,
  Popover,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

import { MessageDlg } from "..";

import fonts from "../../fonts";
import icons from "../../icons";
import colors from "../../colors";

import { containers } from "./styles";
import { button, combobox, list, datepicker } from "./styles";
import { iconbutton } from "./styles";

import Language from "../../language";
import { logout } from "../../redux";

import { sendBackend, setDateFormat } from "../../utils";

import { Buffer } from "buffer";
import moment from "moment-timezone";

let apiCallTime = undefined;
function CameraReportDlg(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = Language();

  const [deviceList, setDeviceList] = useState([]);

  const [selectedDevice, setSelectedDevice] = useState(undefined);

  const [startDate, setStartDate] = useState(
    setDateFormat(moment().local().add(-6, "days"))
  );
  const [endDate, setEndDate] = useState(setDateFormat(moment().local()));

  const [reportList, setReportList] = useState([]);

  const [selectedPage, setSelectedPage] = useState(1);
  const [endPage, setEndPage] = useState(1);

  const [deviceListRef, setDeviceListRef] = useState(undefined);
  const openDeviceList = Boolean(deviceListRef);

  const messageDlgTitle = language.warning;
  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgContents, setMessageDlgContents] = useState("");

  const [backDrop, setBackDrop] = useState(false);

  const auth = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    if (!auth.isLoggedIn) return onClose();
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (props.device) {
      const device =
        Array.isArray(props.device) && props.device.length > 0
          ? props.device[0]
          : props.device;

      setSelectedDevice(device);
      setTimeout(() =>
        onSearchReportData({ device, startDate, endDate, page: 1 })
      );
    }
  }, [props.device]);

  const onSearchDeviceList = (callback) => {
    if (!auth || !auth.uuid) return onClose();

    setBackDrop(true);
    const runTime = new Date().getTime();
    const apiPath = "api/search/device";
    sendBackend(apiPath, { uuid: auth.uuid }, (success, res) => {
      setBackDrop(false);
      if (!success || !res || !res.data) {
        if (res.data && res.data.code === 3000) {
          dispatch(logout());
          return navigate("/login", { replace: true });
        }
        return;
      }

      if (apiCallTime !== runTime) return;

      if (res.data.deviceList) setDeviceList(res.data.deviceList);

      if (callback) callback();
    });

    apiCallTime = runTime;
  };

  const onSearchReportData = (params) => {
    if (!auth || !auth.uuid) return onClose();

    params.startDate = moment(params.startDate).utc();
    params.endDate = moment(params.endDate).utc().add(1, "days");

    if (params.endDate.valueOf() < params.startDate.valueOf()) {
      setMessageDlgContents(language.errorMsg.period);
      setOpenMessageDlg(true);
      return;
    }

    setBackDrop(true);
    const runTime = new Date().getTime();
    const apiPath = "api/search/cameraReport";
    sendBackend(apiPath, { uuid: auth.uuid, ...params }, (success, res) => {
      setBackDrop(false);
      if (!success || !res || !res.data) {
        if (res.data && res.data.code === 3000) {
          dispatch(logout());
          return navigate("/login", { replace: true });
        }
        return;
      }

      if (apiCallTime !== runTime) return;

      if (res.data.endPage) setEndPage(res.data.endPage);

      if (Array.isArray(res.data.reportList)) {
        if (res.data.reportList.length < 1) return;

        const _reportList = res.data.reportList.map((report) => {
          if (report.created_date) {
            report.datetime = moment.utc(report.created_date).local();
            report.created_date = report.datetime.format("YYYY-MM-DD");
          }

          report.min = report.min ? (parseInt(report.min) / 100).toFixed(2) : 0;
          report.max = report.max ? (parseInt(report.max) / 100).toFixed(2) : 0;
          report.avg = report.avg ? (parseInt(report.avg) / 100).toFixed(2) : 0;

          switch (report.state) {
            case "0":
              report.state = language.normal;
              break;
            case "1":
              report.state = language.warning;
              break;
            case "2":
              report.state = language.error;
              break;
            default:
              report.state = "-";
              break;
          }

          if (report.ir_data && report.ir_contentType) {
            let base64Flag = `data:${report.ir_contentType}; base64,`;
            let imageStr = Buffer.from(report.ir_data).toString("base64");
            report.ir_data = base64Flag + imageStr;
          }

          if (report.vs_data && report.vs_contentType) {
            let base64Flag = `data:${report.vs_contentType}; base64,`;
            let imageStr = Buffer.from(report.vs_data).toString("base64");
            report.vs_data = base64Flag + imageStr;
          }

          return report;
        });

        setReportList(_reportList);
      }
    });

    apiCallTime = runTime;
  };

  const handleDeviceList = (e) => {
    const target = e.currentTarget;
    onSearchDeviceList(setTimeout(() => setDeviceListRef(target)));
  };

  const onChangeDevice = (params) => {
    setSelectedDevice(params);
    setDeviceListRef(undefined);
    setReportList([]);
    setSelectedPage(1);
    setTimeout(() =>
      onSearchReportData({ device: params, startDate, endDate, page: 1 })
    );
  };

  const onChangeStartDate = (val) => {
    let end = endDate;
    const start = setDateFormat(moment(val));

    if (start.valueOf() > endDate.valueOf())
      end = setDateFormat(moment(val).add(1, "days"));

    setStartDate(start);
    setEndDate(end);
  };

  const onChangeEndDate = (val) => {
    let start = startDate;
    const end = setDateFormat(moment(val));

    if (end.valueOf() < startDate.valueOf())
      start = setDateFormat(moment(val).add(-1, "days"));

    setStartDate(start);
    setEndDate(setDateFormat(moment(val)));
  };

  const onChangePrevPage = () => {
    if (selectedPage < 2) return;

    setSelectedPage(selectedPage - 1);
    setReportList([]);
    setTimeout(() =>
      onSearchReportData({
        device: selectedDevice,
        startDate,
        endDate,
        page: selectedPage - 1,
      })
    );
  };

  const onChangeNextPage = () => {
    if (selectedPage === endPage) return;

    setSelectedPage(selectedPage + 1);
    setReportList([]);
    setTimeout(() =>
      onSearchReportData({
        device: selectedDevice,
        startDate,
        endDate,
        page: selectedPage + 1,
      })
    );
  };

  const onCloseMessageDlg = () => {
    setMessageDlgContents("");
    setOpenMessageDlg(false);
  };

  const onClose = () => {
    props.onClose();
  };

  const renderDevicePopover = () => {
    return (
      <Popover
        anchorEl={deviceListRef}
        open={openDeviceList}
        onClose={() => setDeviceListRef(undefined)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock={true}
        style={{ marginTop: "2px" }}
      >
        <List sx={containers.comboboxView}>
          {Array.isArray(deviceList) &&
            deviceList.map((val, idx) => {
              return (
                <ListItem key={`device-combo-${idx}`} sx={list.item1}>
                  <Button
                    sx={combobox.item1}
                    onClick={() => onChangeDevice(val)}
                  >
                    <Typography style={fonts.n_14_w}>
                      {val.device_name}
                    </Typography>
                  </Button>
                </ListItem>
              );
            })}
        </List>
      </Popover>
    );
  };

  const renderControl = () => {
    const deviceName = selectedDevice
      ? `${language.deviceName} [${selectedDevice.device_name}]`
      : language.deviceList;

    return (
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: "10px 0px" }}
      >
        <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
          <Grid container alignItems="center" style={{ minWidth: "230px" }}>
            {/* 뒤로가기 */}
            <Grid item xl={2} lg={2} md={2}>
              <IconButton sx={iconbutton.type1} onClick={onClose}>
                <ArrowBackRoundedIcon />
              </IconButton>
            </Grid>
            {/* 장비 목록 드롭다운 */}
            <Grid item xl={10} lg={10} md={10}>
              <Button sx={combobox.type1} onClick={handleDeviceList}>
                <Typography style={fonts.n_14_w}>{deviceName}</Typography>
                <ArrowDropDownRoundedIcon sx={icons.white_32} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  sx={datepicker.type1}
                  value={startDate.toDate()}
                  format="yyyy-MM-dd"
                  onChange={onChangeStartDate}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item style={{ padding: "0px 10px" }}>
              <Typography style={fonts.b_16_w}>{"-"}</Typography>
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  sx={datepicker.type1}
                  value={endDate.toDate()}
                  format="yyyy-MM-dd"
                  onChange={onChangeEndDate}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item style={{ paddingLeft: "20px" }}>
              <Button
                sx={button.type4}
                onClick={() => {
                  setReportList([]);
                  setSelectedPage(1);
                  setTimeout(() =>
                    onSearchReportData({
                      device: selectedDevice,
                      startDate,
                      endDate,
                      page: 1,
                    })
                  );
                }}
              >
                <Typography style={{ ...fonts.n_14_w, paddingTop: "3px" }}>
                  {language.search}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderReportItem = (report, idx) => {
    const path = {};
    const state = { style: { ...fonts.b_16_bu } };
    const value = {};

    if (report) {
      state.value = report.state ? report.state : "-";
      value.created_date = report.created_date ? report.created_date : "-";
      value.min = report.min ? report.min : "0";
      value.max = report.max ? report.max : "0";
      value.avg = report.avg ? report.avg : "0";
      path.vs = report.vs_data ? report.vs_data : "/img/default_img.JPG";
      path.ir = report.ir_data ? report.ir_data : "/img/default_img.JPG";
    } else {
      value.created_date = "-";
      value.min = "0";
      value.max = "0";
      value.avg = "0";
      path.vs = "/img/default_img.JPG";
      path.ir = "/img/default_img.JPG";
      state.value = "-";
      state.style.color = colors.gray1;
    }

    return (
      <Grid
        key={`report-${idx}`}
        // xl={3}
        lg={4}
        md={6}
        sm={12}
        xs={12}
        item
        style={{ paddingLeft: "20px", paddingTop: "20px" }}
      >
        <Grid
          container
          direction="column"
          style={{ height: "100%", width: "100%" }}
        >
          <Grid item style={containers.deviceTitlePaper}>
            <Grid container direction="column">
              <Grid item container alignItems="center">
                <Grid item style={{ paddingTop: "2px" }}>
                  <Typography style={fonts.n_14_w}>
                    {language.state}:
                  </Typography>
                </Grid>
                <Grid item style={{ paddingLeft: "10px" }}>
                  <Typography style={state.style}>{state.value}</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                style={{ paddingTop: "10px" }}
              >
                <Grid item xs>
                  <Typography style={fonts.n_14_w}>
                    {language.createdDate}: {value.created_date}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={fonts.n_14_w}>
                    {language.min}: {value.min} ℃
                  </Typography>
                </Grid>
                <Grid item style={{ paddingLeft: "10px" }}>
                  <Typography style={fonts.n_14_w}>
                    {language.max}: {value.max} ℃
                  </Typography>
                </Grid>
                <Grid item style={{ paddingLeft: "10px" }}>
                  <Typography style={fonts.n_14_w}>
                    {language.avg}: {value.avg} ℃
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs item container style={containers.deviceContentsPaper}>
            <Grid item xs style={{ paddingRight: 5 }}>
              <img
                src={path.vs}
                style={
                  endPage > 1
                    ? { ...icons.camera, maxHeight: "185px" }
                    : icons.camera
                }
              />
            </Grid>
            <Grid item xs style={{ paddingLeft: 5 }}>
              <img
                src={path.ir}
                style={
                  endPage > 1
                    ? { ...icons.camera, maxHeight: "185px" }
                    : icons.camera
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderReportView = () => {
    const defaultList = new Array(6).fill(0);

    return (
      <Grid xs item style={{ overflow: "hidden", ...containers.reportView }}>
        <Grid
          container
          direction="row"
          style={{
            height: "100%",
            overflow: "auto",
            paddingRight: 20,
            paddingBottom: 20,
          }}
        >
          {Object.keys(defaultList).map((key) => {
            return renderReportItem(reportList[key], key);
          })}
        </Grid>
      </Grid>
    );
  };

  const renderPageControl = () => {
    return (
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        style={{ paddingTop: "20px" }}
      >
        <Grid item style={{ padding: "10px 5px" }}>
          <Button
            sx={button.type2_unfocused}
            onClick={() => onChangePrevPage()}
          >
            {language.prev}
          </Button>
        </Grid>
        <Grid item style={{ padding: "10px 20px" }}>
          <Typography
            style={fonts.b_16_w}
          >{`${selectedPage} / ${endPage}`}</Typography>
        </Grid>
        <Grid item style={{ padding: "10px 0px 10px 5px" }}>
          <Button
            sx={button.type2_unfocused}
            onClick={() => onChangeNextPage()}
          >
            {language.next}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={containers.contents}
    >
      {openMessageDlg && (
        <MessageDlg
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          contents={messageDlgContents}
          onClose={onCloseMessageDlg}
        />
      )}
      {openDeviceList && renderDevicePopover()}
      {/*설정 바 */}
      {renderControl()}
      {/*상태 그래프 */}
      {renderReportView()}
      {/*페이지 조절 */}
      {endPage > 1 && renderPageControl()}
      <Backdrop open={backDrop} style={{ zIndex: 1001, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default CameraReportDlg;
