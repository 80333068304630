import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  Button,
  IconButton,
  Typography,
  TextField,
  Paper,
  List,
  ListItem,
  Backdrop,
  CircularProgress,
  Popover,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";

import { logout, setHeaderVisibility, setHeaderMoving } from "../../../redux";

import {
  Header,
  AgGrid,
  SensorGraphDlg,
  MessageDlg,
} from "../../../components";

import icons from "../../../icons";
import fonts from "../../../fonts";
import styles from "../styles";

import { button, combobox, list, table, datepicker } from "../components";
import { textfield, paper } from "../components";

import { sendBackend, setDateFormat } from "../../../utils";

import moment from "moment-timezone";
import Language from "../../../language";

let _viewType = 0;
let apiCallTime = undefined;
function Report(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = Language();

  const columnDefs = {
    sortable: true,
    resizable: true,
  };
  const defaultColumnDefs = [
    { headerName: language.createdDate, field: "created_date" },
    { headerName: language.deviceName, field: "device_name" },
  ];

  const [viewType, setViewType] = useState(0);
  _viewType = viewType;

  const [gridData, setGridData] = useState([]);
  const [gridColumnDefs, setGridColumnDefs] = useState(defaultColumnDefs);

  const [deviceList, setDeviceList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState([]);

  const [deviceListRef, setDeviceListRef] = useState(undefined);
  const openDeviceList = Boolean(deviceListRef);

  const [startDate, setStartDate] = useState(
    setDateFormat(moment().local().add(-6, "days"))
  );
  const [endDate, setEndDate] = useState(setDateFormat(moment().local()));

  const [search, setSearch] = useState("");

  const [selectedPage, setSelectedPage] = useState(1);
  const [endPage, setEndPage] = useState(1);

  const messageDlgTitle = language.warning;
  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgContents, setMessageDlgContents] = useState("");

  const [backDrop, setBackDrop] = useState(false);

  const auth = useSelector((state) => state.auth, shallowEqual);
  const header = useSelector((state) => state.header, shallowEqual);

  useEffect(() => {
    if (!auth.isLoggedIn) return navigate("/login", { replace: true });
    setViewType(0);
    onSearchDeviceList();
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (!header.visibility) dispatch(setHeaderVisibility(true));
  }, [header.visibility]);

  useEffect(() => {
    if (header.state !== 3)
      dispatch(setHeaderMoving({ state: 3, moving: false }));
  }, [header.state]);

  useEffect(() => {
    const useBackListener = () => {
      if (_viewType !== 0) setViewType(0);
    };

    window.addEventListener("popstate", useBackListener);

    return () => {
      window.removeEventListener("popstate", useBackListener);
    };
  }, []);

  const onSearchDeviceList = (callback) => {
    if (!auth || !auth.uuid) return dispatch(logout());

    setBackDrop(true);
    const runTime = new Date().getTime();
    const apiPath = "api/search/device";
    sendBackend(apiPath, { uuid: auth.uuid }, (success, res) => {
      setBackDrop(false);
      if (!success || !res || !res.data) {
        if (res.data && res.data.code === 3000) {
          dispatch(logout());
          return navigate("/login", { replace: true });
        }
        return;
      }

      if (apiCallTime !== runTime) return;

      if (res.data.deviceList) setDeviceList(res.data.deviceList);
      if (callback) callback();
      else if (Array.isArray(res.data.deviceList)) {
        if (res.data.deviceList.length < 1) return;
        setSelectedDevice([res.data.deviceList[0]]);
        onSearchSensorList({ deviceList: [res.data.deviceList[0]] });
      }
    });

    apiCallTime = runTime;
  };

  const onSearchSensorList = (params) => {
    if (!auth || !auth.uuid) return dispatch(logout());

    if (!params.deviceList || params.deviceList.length < 1)
      return setGridColumnDefs(defaultColumnDefs);

    setBackDrop(true);
    const runTime = new Date().getTime();
    const apiPath = "api/search/sensor";
    sendBackend(apiPath, { uuid: auth.uuid, ...params }, (success, res) => {
      setBackDrop(false);
      if (!success || !res || !res.data) {
        if (res.data && res.data.code === 3000) {
          dispatch(logout());
          return navigate("/login", { replace: true });
        }
        return;
      }

      if (apiCallTime !== runTime) return;

      if (res.data.sensorList) {
        const _sensorInfo = {};
        res.data.sensorList.forEach((item) => {
          _sensorInfo[item.sensor] = {
            unit: item.unit,
            type: item.type,
          };
        });

        const _gridColumnDefs = [...defaultColumnDefs];
        res.data.sensorList.forEach((item) => {
          _gridColumnDefs.push({
            headerName: language[item.sensor],
            field: item.sensor,
            width: 150,
            cellRenderer: (value) => {
              if (!value || !value.data || !value.colDef) return;
              const sensor = value.colDef.field;
              if (!sensor) return;
              if (!value.data[sensor]) return;
              if (!_sensorInfo[sensor]) return;

              const dataInfo = _sensorInfo[sensor];
              let data = value.data[sensor];
              switch (dataInfo.type) {
                case "bool":
                  return data;
                case "int":
                  return `${parseInt(data)}${
                    dataInfo.unit ? ` ${dataInfo.unit}` : ""
                  }`;
                case "float":
                  return `${parseFloat(data)}${
                    dataInfo.unit ? ` ${dataInfo.unit}` : ""
                  }`;
                default:
                  return "-";
              }
            },
          });
        });

        setGridColumnDefs(_gridColumnDefs);
        onSearchTable({
          ...params,
          startDate,
          endDate,
          page: 1,
          search,
        });
      }
    });

    apiCallTime = runTime;
  };

  const onSearchTable = (params) => {
    if (!auth || !auth.uuid) return dispatch(logout());

    if (!params.deviceList || params.deviceList.length < 1)
      return setGridData([]);

    params.startDate = moment(params.startDate).utc();
    params.endDate = moment(params.endDate).utc().add(1, "days");
    params.tz = moment.tz.guess();

    setBackDrop(true);
    const runTime = new Date().getTime();
    const apiPath = "api/search/report";
    sendBackend(
      apiPath,
      { uuid: auth.uuid, ...params, local: "Asia/Seoul" },
      (success, res) => {
        setBackDrop(false);
        if (!success || !res || !res.data) {
          if (res.data && res.data.code === 3000) {
            dispatch(logout());
            return navigate("/login", { replace: true });
          }
          return;
        }

        if (apiCallTime !== runTime) return;

        if (res.data.reportList) {
          let reportList = res.data.reportList.map((item) => {
            item.datetime = moment.utc(item.created_date).local();
            item.created_date = item.datetime.format("YYYY-MM-DD HH:mm:ss");

            if (!item.sensor || !item.state) return item;

            item.sensor = item.sensor.split(",");
            item.state = item.state.split(",");

            item.sensor.forEach((sensor, idx) => {
              item[sensor] = item.state[idx];
            });

            return item;
          });
          setGridData(reportList);
        }
        if (res.data.totalPage) setEndPage(res.data.totalPage);
      }
    );

    apiCallTime = runTime;
  };

  const handleDeviceList = (e) => {
    const target = e.currentTarget;
    onSearchDeviceList(setTimeout(() => setDeviceListRef(target)));
  };

  const onChangeDevice = (params) => {
    let selected = [];
    if (!params) {
      if (deviceList.length !== selectedDevice.length) {
        selected = [...deviceList];
      }
    } else {
      selected = [...selectedDevice];

      const checked = selected.findIndex(
        (item) => item.device_pkey === params.device_pkey
      );

      if (checked !== -1) selected.splice(checked, 1);
      else selected.push(params);
    }

    setGridData([]);
    setSelectedPage(1);
    setSelectedDevice(selected);
    setDeviceListRef(undefined);
    onSearchSensorList({ deviceList: selected });
  };

  const onChangePrevPage = () => {
    if (selectedPage < 2) return;

    setGridData([]);
    setSelectedPage(selectedPage - 1);
    onSearchTable({
      deviceList: selectedDevice,
      startDate,
      endDate,
      page: selectedPage - 1,
      search,
    });
  };

  const onChangeNextPage = () => {
    if (selectedPage === endPage) return;

    setGridData([]);
    setSelectedPage(selectedPage + 1);
    onSearchTable({
      deviceList: selectedDevice,
      startDate,
      endDate,
      page: selectedPage + 1,
      search,
    });
  };

  const onOpenSensorGraph = () => {
    if (!Array.isArray(selectedDevice) || selectedDevice.length !== 1) {
      setMessageDlgContents(language.errorMsg.devcieSelect);
      setOpenMessageDlg(true);
      return;
    }

    window.history.pushState(null, null, window.location.href);
    setViewType(1);
  };

  const onClickInspection = () => {
    setMessageDlgContents(language.errorMsg.inspection);
    setOpenMessageDlg(true);
  };

  const onCloseSensorGraph = () => {
    setViewType(0);
  };

  const onCloseMessageDlg = () => {
    setMessageDlgContents("");
    setOpenMessageDlg(false);
  };

  const renderControlView = () => {
    const deviceName = `${language.deviceList} [${selectedDevice.length} / ${deviceList.length}]`;

    const onChangeStartDate = (val) => {
      let end = endDate;
      const start = setDateFormat(moment(val));

      if (start.valueOf() > endDate.valueOf())
        end = setDateFormat(moment(val).add(1, "days"));

      setStartDate(start);
      setEndDate(end);
    };

    const onChangeEndDate = (val) => {
      let start = startDate;
      const end = setDateFormat(moment(val));

      if (end.valueOf() < startDate.valueOf())
        start = setDateFormat(moment(val).add(-1, "days"));

      setStartDate(start);
      setEndDate(setDateFormat(moment(val)));
    };
    return (
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Button sx={combobox.type1} onClick={handleDeviceList}>
            <Typography style={{ ...fonts.n_14_w, paddingTop: "2px" }}>
              {deviceName}
            </Typography>
            <ArrowDropDownRoundedIcon sx={icons.white_32} />
          </Button>
        </Grid>
        <Grid item style={{ paddingLeft: "10px" }}>
          <Button sx={button.type4} onClick={onOpenSensorGraph}>
            <Grid container direction="row" alignItems="center">
              <Grid item style={{ paddingTop: "7px", paddingRight: "10px" }}>
                <img src="img/graph_24.svg" style={icons.white_18} />
              </Grid>
              <Grid item style={{ paddingRight: "8px" }}>
                <Typography style={{ ...fonts.n_14_w, paddingTop: "3px" }}>
                  {language.graph}
                </Typography>
              </Grid>
            </Grid>
          </Button>
        </Grid>
        <Grid
          xs
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                sx={datepicker.type1}
                value={startDate.toDate()}
                format="yyyy-MM-dd"
                onChange={onChangeStartDate}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item style={{ padding: "0px 20px" }}>
            <Typography style={fonts.b_16_w}>{`-`}</Typography>
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                sx={datepicker.type1}
                value={endDate.toDate()}
                format="yyyy-MM-dd"
                onChange={onChangeEndDate}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item style={{ paddingLeft: "20px" }}>
            <Button
              sx={button.type4}
              onClick={() => {
                setGridData([]);
                setSelectedPage(1);
                onSearchTable({
                  deviceList: selectedDevice,
                  startDate,
                  endDate,
                  page: 1,
                  search,
                });
              }}
            >
              <Typography style={{ ...fonts.n_14_w, paddingTop: "3px" }}>
                {language.search}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderTableView = () => {
    return (
      <Grid xs item container direction="column">
        <Grid item style={{ paddingTop: "10px" }}>
          {renderControlView()}
        </Grid>
        <Grid item xs style={{ padding: "10px 0px 20px" }}>
          <AgGrid
            tableStyle={table.type1}
            tableContentsStyle={table.contents1}
            rowData={gridData}
            defaultColDef={columnDefs}
            columnDefs={gridColumnDefs}
            suppressSizeToFit={true}
            suppressMovableColumns={true}
            suppressRowClickSelection={true}
            overlayLoadingTemplate={"<span></span>"}
            overlayNoRowsTemplate={"<span></span>"}
            renderControl={renderTableControlView}
          />
        </Grid>
        {endPage > 1 && (
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item style={{ padding: "10px 5px" }}>
              <Button
                sx={button.type2_unfocused}
                onClick={() => onChangePrevPage()}
              >
                {language.prev}
              </Button>
            </Grid>
            <Grid item style={{ padding: "10px 20px" }}>
              <Typography
                style={fonts.b_16_w}
              >{`${selectedPage} / ${endPage}`}</Typography>
            </Grid>
            <Grid item style={{ padding: "10px 0px 10px 5px" }}>
              <Button
                sx={button.type2_unfocused}
                onClick={() => onChangeNextPage()}
              >
                {language.next}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderTableControlView = (gridRef) => {
    return (
      <Grid container direction="row" alignItems="center" sx={table.toolbar1}>
        {/* 버튼 리스트 */}
        <Grid item xl lg md sm={12} xs={12}>
          {/* <Grid container alignItems="center">
            <Grid item style={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <Button sx={button.table} onClick={onClickInspection}>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <FileDownloadRoundedIcon
                      sx={{ mt: 1, mr: 1 }}
                      style={fonts.b_20_w}
                    />
                  </Grid>
                  <Grid item style={{ paddingTop: "1px" }}>
                    <Typography style={fonts.b_14_w}>
                      {language.export}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid item style={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <Button sx={button.table} onClick={onClickInspection}>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <LocalPrintshopRoundedIcon
                      sx={{ mt: 1, mr: 1 }}
                      style={fonts.b_20_w}
                    />
                  </Grid>
                  <Grid item style={{ paddingTop: "1px" }}>
                    <Typography style={fonts.b_14_w}>
                      {language.print}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid> */}
        </Grid>
        <Grid
          item
          xl
          lg
          md={6}
          sm={12}
          xs={12}
          style={{ padding: "10px 20px 0px 10px" }}
        >
          {/* 검색 창 */}
          {/* <Paper sx={paper.type1}>
            <Grid
              container
              alignItems="stretch"
              justifyContent="flex-end"
              style={{ height: "100%" }}
            >
              <Grid item xs>
                <TextField
                  value={search}
                  placeholder={language.search}
                  sx={textfield.type1}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ visibility: search ? "none" : "hidden" }}
                        onClick={() => setSearch("")}
                      >
                        <CloseRoundedIcon style={icons.gray_20} />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    setGridData([]);
                    setSelectedPage(1);
                    onSearchTable({
                      deviceList: selectedDevice,
                      startDate,
                      endDate,
                      page: 1,
                      search,
                    });
                  }}
                >
                  <SearchRoundedIcon style={fonts.b_20_w} />
                </IconButton>
              </Grid>
            </Grid>
          </Paper> */}
        </Grid>
      </Grid>
    );
  };

  const renderDevicePopoverItem = (value, idx) => {
    let isSelected = false;
    const iconStyle = { paddingRight: "10px" };
    const deviceName = value ? value.device_name : language.all;

    if (value) {
      const checked = selectedDevice.findIndex(
        (item) => item.device_pkey === value.device_pkey
      );
      if (checked !== -1) isSelected = true;
    } else {
      if (deviceList.length !== selectedDevice.length) isSelected = false;
      else isSelected = true;
    }

    return (
      <ListItem key={`device-combo-${idx}`} sx={list.item1}>
        <Button sx={combobox.item1} onClick={() => onChangeDevice(value)}>
          {isSelected ? (
            <CheckBoxRoundedIcon sx={{ ...icons.gray_24, ...iconStyle }} />
          ) : (
            <CheckBoxOutlineBlankRoundedIcon
              sx={{ ...icons.gray_24, ...iconStyle }}
            />
          )}
          <Typography style={fonts.n_14_w}>{deviceName}</Typography>
        </Button>
      </ListItem>
    );
  };

  const renderDevicePopover = () => {
    return (
      <Popover
        anchorEl={deviceListRef}
        open={openDeviceList}
        onClose={() => setDeviceListRef(undefined)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock={true}
        style={{ marginTop: "2px" }}
      >
        <List sx={list.type1}>
          {renderDevicePopoverItem(undefined, -1)}
          {Array.isArray(deviceList) &&
            deviceList.map((value, idx) => {
              return renderDevicePopoverItem(value, idx);
            })}
        </List>
      </Popover>
    );
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={styles.screens}
    >
      {openMessageDlg && (
        <MessageDlg
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          contents={messageDlgContents}
          onClose={onCloseMessageDlg}
        />
      )}
      {openDeviceList && renderDevicePopover()}
      <Grid item>
        <Header pageName={language.report} />
      </Grid>
      {viewType === 0 && renderTableView()}
      {viewType === 1 && (
        <Grid item xs>
          <SensorGraphDlg
            device={selectedDevice}
            sensor={undefined}
            onClose={onCloseSensorGraph}
          />
        </Grid>
      )}
      <Backdrop open={backDrop} style={{ zIndex: 1001, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default Report;
