import axios from "axios";
import CryptoJS from "crypto-js";
import { getLocale } from "./functions";

import moment from "moment-timezone";
import "moment/min/locales.min";
moment.locale(getLocale());

axios.defaults.withCredentials = true; // 쿠키 데이터를 전송받기 위해
const request = (method, url, data) => {
  return axios({
    method,
    url: process.env.REACT_APP_ORIGIN_URL + url,
    data,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
};

// export const requestFile = (method, url, action, data) => {
//   const bodyFormData = new FormData();
//   bodyFormData.append("info", JSON.stringify(data.info));
//   bodyFormData.append(action, data.file);
//   return axios({
//     method,
//     url: DOMAIN + url,
//     data: bodyFormData,
//     headers: { "Content-Type": "multipart/form-data" },
//     maxContentLength: Infinity,
//     maxBodyLength: Infinity,
//   });
// };

// export const requestDownLoadFile = (method, url, data) => {
//   return axios({
//     method,
//     url: DOMAIN + url,
//     data,
//     headers: {
//       "Content-Type": "application/json",
//     },
//     responseType: "blob",
//     maxContentLength: Infinity,
//     maxBodyLength: Infinity,
//   });
// };

export const sendBackend = async (apiPath, queryData, callback) => {
  try {
    queryData = CryptoJS.AES.encrypt(
      JSON.stringify(queryData),
      process.env.REACT_APP_PUBLIC_API_SECRET_KEY
    ).toString();

    let reqData = { data: queryData };

    const res = await request("post", apiPath, reqData);
    if (res) {
      if (res.data) {
        let success = res.data.success ? true : false;
        callback(success, res.data);
      } else {
        callback(false, res);
      }
    } else {
      callback(false, {});
    }
  } catch (err) {
    if (err) {
      if (err.response) {
        callback(false, err.response);
      } else {
        callback(false, err);
      }
    } else {
      callback(false, {});
    }
  }
};
