import colors from "../colors";

// wh_32_w -> 너비,높이_크기_색상
const styles = {
  logo: { height: "64px" },
  gray_24: {
    fontSize: "24px",
    color: colors.gray,
  },
  gray_20: {
    fontSize: "20px",
    color: colors.gray,
  },
  white_16: {
    fontSize: "16px",
    color: colors.white,
  },
  white_18: {
    fontSize: "18px",
    color: colors.white,
  },
  white_20: {
    fontSize: "20px",
    color: colors.white,
  },
  white_24: {
    fontSize: "24px",
    color: colors.white,
  },
  white_32: {
    fontSize: "32px",
    color: colors.white,
  },
  company_20: {
    fontSize: "20px",
    color: colors.company,
  },
  company3_20: {
    fontSize: "20px",
    color: colors.company3,
  },
  paragraph_20: {
    width: "20px",
    height: "20px",
    border: `1px solid ${colors.company4}`,
    borderRadius: "50%",
    borderColor: colors.company4,
    color: colors.company3,
  },
  weather_r: {
    width: "70px",
    height: "100%",
    color: colors.red,
  },
  weather_y: {
    width: "70px",
    height: "100%",
    color: colors.yellow,
  },
  sensor1: {
    width: "20px",
    height: "20px",
    paddingRight: "5px",
  },
  camera: {
    display: "flex",
    width: "100%",
    height: "100%",
    border: "1px solid",
    borderColor: "#1F252B",
    backgroundColor: "#333333",
  },
};

export default styles;
