import React, { useState, useEffect } from "react";

import { Grid, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import Wave from "react-wavify";
import moment from "moment-timezone";

import Language from "../../language";
import fonts from "../../fonts";
import colors from "../../colors";

const Header = (props) => {
  const language = Language();

  const [dateTime, setDateTime] = useState(moment().local());
  const [dateTimer, setDateTimer] = useState(1000);

  const [realTimeView, setRealTimeView] = useState(0);

  useEffect(() => {
    const start = setInterval(() => {
      if (dateTimer > 0) setDateTimer(dateTimer - 1);
      else if (dateTimer === 0) setDateTimer(1000);

      setDateTime(moment().local());

      if (dateTimer > 0 && dateTimer % 2 === 0)
        setRealTimeView(realTimeView < 5 ? realTimeView + 1 : 0);
    }, 100);

    return () => clearInterval(start);
  }, [dateTimer]);

  const renderWave = () => {
    return (
      <div className="ocean">
        <div className="waveBox">
          <Wave
            fill={colors.company4}
            style={{ width: "100%", height: "60px", position: "absolute" }}
            options={{
              height: 20,
              amplitude: 20,
              speed: 0.2,
              points: 5,
            }}
          />
          <Wave
            fill={colors.company1}
            style={{ width: "100%", height: "60px", position: "absolute" }}
            options={{
              height: 30,
              amplitude: 20,
              speed: 0.1,
              points: 5,
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <Grid container direction="column" alignItems="streach">
      <Grid item container direction="row" alignItems="center">
        <Grid item>
          <Typography style={fonts.b_16_w}>{language.service}</Typography>
        </Grid>
        <Grid item style={{ padding: "0px 20px" }}>
          <Typography style={fonts.b_16_w}>{">>"}</Typography>
        </Grid>
        <Grid item>
          <Typography style={fonts.b_16_w}>{props.pageName}</Typography>
        </Grid>
      </Grid>
      <Grid item style={{ padding: "5px 0px" }}>
        <Typography style={fonts.b_20_w}>
          {dateTime.format("YYYY.MM.DD - HH : mm : ss")}
        </Typography>
      </Grid>
      {props.isRealTime && (
        <Grid item container direction="row" alignItems="center">
          <Grid item style={{ paddingRight: "20px" }}>
            <Typography style={fonts.b_14_w}>{language.realTime}</Typography>
          </Grid>
          {[...Array(6).keys()].map((k) => {
            const _width = 6 + k * 2;
            const _color =
              realTimeView === k ? colors.company3 : colors.company1;

            return (
              <Grid key={`realtime-${k}`}>
                <CircleIcon style={{ width: _width, color: _color }} />
              </Grid>
            );
          })}
        </Grid>
      )}
      {props.isRealTime && (
        <Grid item style={{ padding: "10px 0px" }}>
          {renderWave()}
        </Grid>
      )}
    </Grid>
  );
};

export default Header;
