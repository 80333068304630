import React, { useState, useRef, useEffect } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  Grid,
  Button,
  IconButton,
  Typography,
  Popover,
  Paper,
} from "@mui/material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import MonitorIcon from "@mui/icons-material/Monitor";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import BuildRoundedIcon from "@mui/icons-material/BuildRounded";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import VideocamIcon from '@mui/icons-material/Videocam';
// import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import PersonIcon from "@mui/icons-material/Person";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

import { logout, setFullScreen } from "../../../redux";

import icons from "../../../icons";
import fonts from "../../../fonts";

import Language from "../../../language";

import styles from "../styles";
import { iconbutton, button } from "../components";
// import { containers, images } from "../../../styles";

function Header(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const language = Language();

  const [isFullScreen, setIsFullScreen] = useState(false);

  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const profileOpen = Boolean(profileAnchorEl);
  const profile_id = profileOpen ? "profile-popover" : undefined;

  const auth = useSelector((state) => state.auth, shallowEqual);
  const header = useSelector((state) => state.header, shallowEqual);

  const onChangePage = (_pathname) => {
    if (location.pathname !== _pathname) navigate(_pathname);
    else navigate(0);
  };

  const handleLogOut = () => {
    setProfileAnchorEl(null);
    dispatch(logout());
  };

  const handleProfileOpen = (e) => {
    setProfileAnchorEl(e.currentTarget);
  };

  const handleProfileClose = (e) => {
    setProfileAnchorEl(null);
  };

  const profilePopover = () => {
    return (
      <>
        <Popover
          id={profile_id}
          open={profileOpen}
          anchorEl={profileAnchorEl}
          onClose={handleProfileClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableScrollLock={true}
          style={{ marginTop: "5px" }}
        >
          <Paper
            style={{
              width: "200px",
              // height: "360px",
              height: "100%",
              padding: "5px",
            }}
          >
            <Grid container direction="column" alignItems="stretch">
              <Grid item xs>
                <Button
                  style={{
                    width: "100%",
                    padding: "0px",
                    textTransform: "none",
                    boxShadow: "none",
                  }}
                  onClick={handleLogOut}
                >
                  <Typography sx={fonts.b_16_b}>{language.logout}</Typography>
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Popover>
      </>
    );
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      style={styles.header}
    >
      {profileOpen && profilePopover()}
      <Grid
        xs
        item
        container
        direction="row"
        alignItems="center"
        style={{ padding: "0px 40px", minWidth: "400px" }}
      >
        <Grid item>
          <IconButton
            sx={
              header.state === 0
                ? iconbutton.type3_focused
                : iconbutton.type3_unfocused
            }
            onClick={() => onChangePage("/dashboard")}
          >
            <HomeRoundedIcon style={icons.white_24} />
          </IconButton>
        </Grid>
        <Grid item style={{ paddingLeft: "20px" }}>
          <IconButton
            sx={
              header.state === 1
                ? iconbutton.type3_focused
                : iconbutton.type3_unfocused
            }
            onClick={() => onChangePage("/monitoring")}
          >
            <VideocamIcon style={icons.white_24} />
          </IconButton>
        </Grid>
        <Grid item style={{ paddingLeft: "20px" }}>
          <IconButton
            sx={
              header.state === 2
                ? iconbutton.type3_focused
                : iconbutton.type3_unfocused
            }
            onClick={() => onChangePage("/notification")}
          >
            <NotificationsRoundedIcon style={icons.white_24} />
          </IconButton>
        </Grid>
        <Grid item style={{ paddingLeft: "20px" }}>
          <IconButton
            sx={
              header.state === 3
                ? iconbutton.type3_focused
                : iconbutton.type3_unfocused
            }
            onClick={() => onChangePage("/report")}
          >
            <DescriptionRoundedIcon style={icons.white_24} />
          </IconButton>
        </Grid>
        {auth && auth.auth_type > 0 && (
          <Grid item style={{ paddingLeft: "20px" }}>
            <IconButton
              sx={
                header.state === 4
                  ? iconbutton.type3_focused
                  : iconbutton.type3_unfocused
              }
              onClick={() => onChangePage("/device")}
            >
              <BuildRoundedIcon style={icons.white_24} />
            </IconButton>
          </Grid>
        )}
        {auth && auth.auth_type > 1 && (
          <Grid item style={{ paddingLeft: "20px" }}>
            <IconButton
              sx={
                header.state === 5
                  ? iconbutton.type3_focused
                  : iconbutton.type3_unfocused
              }
              onClick={() => onChangePage("/person")}
            >
              <AdminPanelSettingsIcon style={icons.white_24} />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Grid
        xs
        item
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ minWidth: "400px" }}
      >
        <Grid item>
          <Typography style={fonts.b_18_w}>{language.service}</Typography>
        </Grid>
      </Grid>
      <Grid
        xs
        item
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        style={{ padding: "5px 40px", minWidth: "400px" }}
      >
        {/* <Grid item>
          <IconButton
            onClick={() => {
              setIsFullScreen(!isFullScreen);
              if (!isFullScreen) props.onFullEnter();
              else props.onFullExit();
            }}
          >
            {isFullScreen ? (
              <FullscreenExitIcon style={icons.white_24} />
            ) : (
              <FullscreenIcon style={icons.white_20} />
            )}
          </IconButton>
        </Grid> */}
        {auth.isLoggedIn && (
          <Grid item>
            <Button
              sx={{ ...button.type6, textTransform: "none" }}
              onClick={handleProfileOpen}
            >
              <Grid
                container
                direction="row"
                // alignItems="center"
                style={styles.contents}
              >
                <Grid item>
                  <PersonIcon style={icons.white_24} />
                </Grid>
                <Grid item style={{ paddingTop: "5px", paddingLeft: "5px" }}>
                  <Typography style={fonts.b_14_w}>{auth.name}</Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default Header;
