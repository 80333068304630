import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  List,
  Button,
  Typography,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import CircleIcon from "@mui/icons-material/Circle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WbSunnyIcon from "@mui/icons-material/WbSunny";

import {
  logout,
  setHeaderVisibility,
  setHeaderMoving,
  updateLatestData,
} from "../../../redux";

import { Header, SensorGraphDlg, GoogleMap } from "../../../components";

import styles from "../styles";
import icons from "../../../icons";
import fonts from "../../../fonts";
import colors from "../../../colors";

import { button, list, iconbutton } from "../components";

import { sendBackend, onChangeStringToType } from "../../../utils";

import moment from "moment-timezone";
import Language from "../../../language";

let _viewType = 0;
let alarmDatetime = undefined;
let apiCallTime = undefined;
function Dashboard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = Language();

  const [dateTimer, setDateTimer] = useState(-1);

  const [viewType, setViewType] = useState(0);
  _viewType = viewType;

  const [deviceList, setDeviceList] = useState([]);
  const [sensorList, setSensorList] = useState([]);
  const [alarmList, setAlarmList] = useState([]);

  const [latestList, setLatestList] = useState([]);
  const [nowWeather, setNowWeather] = useState(undefined);

  const [selectedDevice, setSelectedDevice] = useState(undefined);
  const [selectedSensor, setSelectedSensor] = useState(undefined);

  const [backDrop, setBackDrop] = useState(false);

  const auth = useSelector((state) => state.auth, shallowEqual);
  const header = useSelector((state) => state.header, shallowEqual);
  const dashboard = useSelector((state) => state.dashboard, shallowEqual);

  useEffect(() => {
    if (!auth.isLoggedIn) return navigate("/login", { replace: true });
    alarmDatetime = undefined;
    setViewType(0);
    setDateTimer(1000);
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (!header.visibility) dispatch(setHeaderVisibility(true));
  }, [header.visibility]);

  useEffect(() => {
    if (header.state !== 0)
      dispatch(setHeaderMoving({ state: 0, moving: false }));
  }, [header.state]);

  useEffect(() => {
    if (dashboard) {
      setDeviceList(dashboard.deviceList);
      setAlarmList(dashboard.alarmList);
      setLatestList(dashboard.latestList);
      setNowWeather(dashboard.nowWeather);
      setSensorList(dashboard.sensorList);
      setSelectedDevice(dashboard.selectedDevice);
    }
  }, []);

  useEffect(() => {
    const useBackListener = () => {
      if (_viewType !== 0) setViewType(0);
    };

    window.addEventListener("popstate", useBackListener);

    return () => {
      window.removeEventListener("popstate", useBackListener);
    };
  }, []);

  useEffect(() => {
    const start = setInterval(() => {
      if (dateTimer > 0) setDateTimer(dateTimer - 1);
      else if (dateTimer === 0) setDateTimer(1000);

      if (viewType !== 0) return;
      if (dateTimer > 0 && dateTimer % 5 === 0) onSearchDashboard();
    }, 100);

    return () => clearInterval(start);
  }, [dateTimer]);

  const onSearchDashboard = () => {
    if (!auth || !auth.uuid) return dispatch(logout());

    const params = {};
    params.tz = moment.tz.guess();
    params.created_date = alarmDatetime;

    const runTime = new Date().getTime();
    const apiPath = "api/search/dashboard";
    sendBackend(apiPath, { uuid: auth.uuid, ...params }, (success, res) => {
      if (!success || !res || !res.data) {
        if (res.data && res.data.code === 3000) {
          dispatch(logout());
          return navigate("/login", { replace: true });
        }
        return;
      }

      if (apiCallTime !== runTime) return;

      const latest = {};
      if (Array.isArray(res.data.deviceList)) {
        if (!selectedDevice && res.data.deviceList.length > 0)
          setSelectedDevice(res.data.deviceList[0]);

        latest.deviceList = [...res.data.deviceList];
        setDeviceList(res.data.deviceList);
      }

      if (Array.isArray(res.data.alarmList)) {
        if (res.data.alarmList.length > 0)
          alarmDatetime =
            res.data.alarmList[res.data.alarmList.length - 1].created_date;

        res.data.alarmList = res.data.alarmList.map((alarm) => {
          if (!alarm.datetime) {
            alarm.datetime = moment.utc(alarm.created_date).local();
            alarm.created_date = alarm.datetime.format(
              "YYYY년 MM월 DD일  HH시 mm분 ss초"
            );
          }

          switch (`${alarm.state}`) {
            case "51":
              alarm.state = "발생했습니다.";
              break;
            case "52":
              alarm.state = "해제되지 않았습니다.";
              break;
            case "53":
              alarm.state = "해제되었습니다.";
              break;
          }

          if (!alarm.sensor_name) return alarm;

          alarm.contents = `[${alarm.created_date}] [${alarm.device_name}]의 ${
            language[alarm.sensor_name.toLowerCase()]
          } 센서에서 알람이 ${alarm.state}`;
          return alarm;
        });

        const _alarmList = [...res.data.alarmList, ...alarmList];
        let uniqueArr = _alarmList.filter((element, index) => {
          const idx = _alarmList.findIndex((alarm) => {
            return (
              alarm.device_pkey === element.device_pkey &&
              alarm.created_date === element.created_date &&
              alarm.sensor_name === element.sensor_name &&
              alarm.sensor_value === element.sensor_value
            );
          });
          return index === idx;
        });

        uniqueArr = uniqueArr.sort(
          (a, b) => b.datetime.valueOf() - a.datetime.valueOf()
        );

        latest.alarmList = [...uniqueArr];
        setAlarmList(uniqueArr);
      }

      if (Array.isArray(res.data.latestList)) {
        latest.latestList = [...res.data.latestList];
        setLatestList(res.data.latestList);
      }

      if (res.data.nowWeather) {
        latest.nowWeather = res.data.nowWeather;
        setNowWeather(res.data.nowWeather);
      }

      if (!selectedDevice) {
        if (Array.isArray(res.data.deviceList))
          onChangeDevice(res.data.deviceList[0]);

        // dispatch(updateLatestData(latest));
        return;
      } else latest.selectedDevice = selectedDevice;

      if (Array.isArray(res.data.sensorList)) {
        latest.sensorList = res.data.sensorList;
        setSensorList(res.data.sensorList);
      }

      // dispatch(updateLatestData(latest));
    });

    apiCallTime = runTime;
  };

  const onChangeDevice = (device) => {
    setSelectedDevice(device);
    apiCallTime = undefined;
  };

  const onCloseSensorGraph = () => {
    setViewType(0);
  };

  const renderDeviceItem = (device, idx) => {
    const style = {};
    if (selectedDevice && selectedDevice.device_pkey === device.device_pkey) {
      style.paper = colors.company4;
      style.marker = colors.company3;
    } else {
      style.paper = colors.company2;
      style.marker = colors.white;
    }

    const deviceName = device && device.device_name ? device.device_name : "";
    const devicelocation = device && device.location ? device.location : "";

    return (
      <div
        key={`device-${idx}`}
        style={{ paddingTop: idx > 0 ? "5px" : "0px" }}
      >
        <Button
          sx={{
            ...list.item2,
            backgroundColor: style.paper,
          }}
          onClick={() => onChangeDevice(device)}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item style={{ paddingTop: "5px", paddingRight: "5px" }}>
              <LocationOnIcon
                style={{ ...icons.white_24, color: style.marker }}
              />
            </Grid>
            <Grid xs item container direction="column" alignItems="stretch">
              <Grid item>
                <Typography style={{ ...fonts.n_14_w, textAlign: "left" }}>
                  {`${language.deviceName}: [${deviceName}]`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={{ ...fonts.n_14_w, textAlign: "left" }}
                >{`${language.location}: ${devicelocation}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Button>
      </div>
    );
  };

  const renderSensorItem = (sensor) => {
    const _sensorList = [];
    sensorList.forEach((sensor) => {
      if (selectedDevice.device_pkey === sensor.device_pkey)
        return _sensorList.push(sensor);
    });

    return (
      <Grid container direction="row" justifyContent="center">
        {_sensorList.map((value, idx) => {
          if (!sensor[value.sensor]) return;

          const sensorValue = `${onChangeStringToType(
            value.type,
            sensor[value.sensor],
            [language.normal, language.error]
          )}${value.unit ? ` ${value.unit}` : ""}`;

          return (
            <Grid item key={`sensor-${idx}`} style={{ padding: "5px" }}>
              <Button
                sx={button.type5}
                onClick={() => {
                  window.history.pushState(null, null, window.location.href);
                  setSelectedSensor(value);
                  setViewType(1);
                }}
              >
                <Grid container direction="column">
                  <Grid item container direction="row" alignItems="center">
                    <Grid item>
                      <img src={value.path} style={icons.sensor1} />
                    </Grid>
                    <Grid
                      item
                      style={{ paddingLeft: "5px", marginTop: "-5px" }}
                    >
                      <Typography style={fonts.n_12_w}>
                        {language[value.sensor]}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs style={fonts.n_18_o}>
                    {sensorValue}
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderSensorState = () => {
    const deviceName = selectedDevice ? selectedDevice.device_name : "";

    const sensor = {};
    let deviceDate = "";
    if (selectedDevice && Array.isArray(latestList)) {
      const idx = latestList.findIndex((latest) => {
        if (selectedDevice.device_pkey === latest.device_pkey) return latest;
      });

      if (idx !== -1) {
        deviceDate = moment.utc(latestList[idx].created_date).local();

        sensor.item = latestList[idx].sensor.split(",");
        sensor.state = latestList[idx].state.split(",");

        sensor.item.forEach((item, idx) => {
          sensor[item] = sensor.state[idx];
        });

        delete sensor.item;
        delete sensor.state;
      }
    }

    return (
      <Grid xs item container direction="column" alignItems="stretch">
        <Grid item container direction="row" alignItems="center">
          <Grid item>
            <CircleIcon style={icons.paragraph_20} />{" "}
          </Grid>
          <Grid item style={{ paddingLeft: "10px" }}>
            <Typography style={fonts.n_18_w}>{language.sensorState}</Typography>
          </Grid>
          <Grid
            xs
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <Typography style={fonts.n_12_w}>
                {`${language.deviceName}: [${deviceName}]`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={fonts.n_12_w}>
                {deviceDate ? deviceDate.format("YYYY-MM-DD HH:mm:ss") : "-"}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              sx={iconbutton.type2}
              onClick={() => {
                window.history.pushState(null, null, window.location.href);
                setViewType(1);
              }}
            >
              <img src="img/graph_24.svg" style={icons.white_18} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          xs
          item
          style={{ paddingTop: "10px", overflow: "auto", minHeight: "300px" }}
        >
          {renderSensorItem(sensor)}
        </Grid>
      </Grid>
    );
  };

  const getWeatherIcon = () => {
    if (!nowWeather) return;

    switch (nowWeather.PTY) {
      case 0:
        break;
      case 1:
      case 2:
      case 5:
      case 6:
        return "img/Rain.png";
        break;
      default:
        return "img/Snow.png";
    }
  };

  const renderWeather = () => {
    const weatherIcon = getWeatherIcon(nowWeather);
    const temperature = nowWeather && nowWeather.T1H ? nowWeather.T1H : 0;
    const humidity = nowWeather && nowWeather.REH ? nowWeather.REH : 0;
    const precipitation = nowWeather && nowWeather.RN1 ? nowWeather.RN1 : 0;

    return (
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        style={{ paddingTop: "10px" }}
      >
        <Grid item container direction="row" alignItems="center">
          <Grid item>
            <CircleIcon style={icons.paragraph_20} />
          </Grid>
          <Grid item style={{ paddingLeft: "10px" }}>
            <Typography style={fonts.n_18_w}>{language.weather}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{ padding: "10px 0px" }}
        >
          <Grid item style={{ padding: "10px 20px" }}>
            {weatherIcon && <img src={weatherIcon} style={icons.weather_y} />}
            {nowWeather && nowWeather.PTY === 0 && (
              <WbSunnyIcon sx={icons.weather_y} />
            )}
            {/* {!nowWeather && <NotInterestedIcon sx={icons.weather_r} />} */}
            {!nowWeather && <WbSunnyIcon sx={icons.weather_y} />}
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  style={fonts.n_14_w}
                >{`${language.temperature}: ${temperature} ℃`}</Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={fonts.n_14_w}
                >{`${language.humidity}: ${humidity} %`}</Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={fonts.n_14_w}
                >{`${language.precipitation}: ${precipitation} mm`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderDashboard = () => {
    return (
      <Grid
        xs
        item
        container
        direction="column"
        alignItems="stretch"
        style={styles.contents}
      >
        <Grid xs item container direction="column-reverse">
          <Grid item style={styles.alarmPaper}>
            <List sx={list.type1}>
              {Array.isArray(alarmList) &&
                alarmList.map((alarm, idx) => {
                  if (!alarm.contents) return;

                  return (
                    <Grid item key={`alarm-item-${idx}`}>
                      <Typography style={fonts.n_14_w}>
                        {alarm.contents}
                      </Typography>
                    </Grid>
                  );
                })}
            </List>
          </Grid>
          <Grid xs item container direction="row" style={styles.dashboardPaper}>
            <Grid xs item style={{ padding: "10px", maxWidth: "420px" }}>
              <Grid
                container
                direction="column"
                alignItems="stretch"
                style={styles.devicePaper}
              >
                <Grid item container direction="row" alignItems="center">
                  <Grid item style={{ paddingLeft: "5px" }}>
                    <CircleIcon style={icons.paragraph_20} />
                  </Grid>
                  <Grid xs item style={{ paddingLeft: "10px" }}>
                    <Typography style={fonts.n_18_w}>
                      {language.deviceList}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item style={{ paddingTop: "10px" }}>
                  <div style={styles.deviceListPaper}>
                    <List sx={list.type1}>
                      {Array.isArray(deviceList) &&
                        deviceList.map((val, idx) => {
                          return renderDeviceItem(val, idx);
                        })}
                    </List>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs item style={{ padding: "10px" }}>
              <div style={styles.googleMapPaper}>
                <GoogleMap
                  device={selectedDevice}
                  deviceList={deviceList}
                  onClickMarker={onChangeDevice}
                />
              </div>
            </Grid>
            <Grid xs item style={{ padding: "10px", maxWidth: "500px" }}>
              <Grid
                container
                direction="column"
                alignItems="stretch"
                style={styles.detailPaper}
              >
                {renderSensorState()}
                {renderWeather()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={styles.screens}
    >
      <Grid item>
        <Header isRealTime={viewType === 0} pageName={language.home} />
      </Grid>
      {viewType === 0 && renderDashboard()}
      {viewType === 1 && (
        <Grid item xs>
          <SensorGraphDlg
            device={selectedDevice}
            sensor={selectedSensor}
            onClose={onCloseSensorGraph}
          />
        </Grid>
      )}
      <Backdrop open={backDrop} style={{ zIndex: 1001, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default Dashboard;
