import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  Button,
  IconButton,
  Typography,
  TextField,
  Paper,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import RefreshIcon from "@mui/icons-material/Refresh";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { logout, setHeaderVisibility, setHeaderMoving } from "../../../redux";

import {
  Header,
  AgGrid,
  DeviceDlg,
  MessageDlg,
  YesNoDlg,
} from "../../../components";

import icons from "../../../icons";
import fonts from "../../../fonts";

import styles from "../styles";
import { button, combobox, list, table, datepicker } from "../components";
import { textfield, paper } from "../components";

import { sendBackend, setDateFormat } from "../../../utils";

import moment from "moment-timezone";
import Language from "../../../language";

let apiCallTime = undefined;
function Device(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = Language();

  const columnDefs = {
    sortable: true,
    resizable: true,
  };
  const gridColumnDefs = [
    {
      headerName: language.deviceName,
      field: "device_name",
      checkboxSelection: true,
    },
    { headerName: language.serialNumber, field: "serial_num", width: 150 },
    { headerName: language.api, field: "sitename", width: 150 },
    { headerName: language.purchaseDate, field: "purchase_date", width: 150 },
    { headerName: language.repairDate, field: "repair_date", width: 150 },
    {
      headerName: language.batteryChangeDate,
      field: "battery_change_date",
      width: 150,
    },
    { headerName: language.repairCount, field: "repair_count", width: 150 },
    {
      headerName: language.batteryChangeCount,
      field: "battery_change_count",
      width: 150,
    },
    // { headerName: language.info, field: "info", width: 450 },
    { headerName: language.location, field: "location", width: 450 },
  ];

  const [gridData, setGridData] = useState([]);

  const [selectedDevice, setSelectedDevice] = useState(undefined);

  const [search, setSearch] = useState("");
  const [selectedPage, setSelectedPage] = useState(1);
  const [endPage, setEndPage] = useState(1);

  const [openDeviceDlg, setOpenDeviceDlg] = useState(false);
  const [deviceDlgTitle, setDeviceDlgTitle] = useState("");

  const messageDlgTitle = language.warning;
  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgContents, setMessageDlgContents] = useState("");

  const [openYesNoDlg, setOpenYesNoDlg] = useState(false);
  const [yesnoDlgTitle, setYesNoDlgTitle] = useState("");
  const [yesnoDlgContents, setYesNoDlgContents] = useState("");

  const [backDrop, setBackDrop] = useState(false);

  const auth = useSelector((state) => state.auth, shallowEqual);
  const header = useSelector((state) => state.header, shallowEqual);

  useEffect(() => {
    if (!auth.isLoggedIn) return navigate("/login", { replace: true });
    if (auth && auth.auth_type < 1)
      return navigate("/dashboard", { replace: true });

    onSearchTable({ search: "", page: 1 });
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (!header.visibility) dispatch(setHeaderVisibility(true));
  }, [header.visibility]);

  useEffect(() => {
    if (header.state !== 4)
      dispatch(setHeaderMoving({ state: 4, moving: false }));
  }, [header.state]);

  const onSearchTable = (params) => {
    if (!auth || !auth.uuid) return dispatch(logout());

    params.tz = moment.tz.guess();

    setBackDrop(true);
    const runTime = new Date().getTime();
    const apiPath = "api/search/device";
    sendBackend(apiPath, { uuid: auth.uuid, ...params }, (success, res) => {
      setBackDrop(false);
      if (!success || !res || !res.data) {
        if (res.data && res.data.code === 3000) {
          dispatch(logout());
          return navigate("/login", { replace: true });
        }
        return;
      }

      if (apiCallTime !== runTime) return;
      if (res.data.endPage) setEndPage(res.data.endPage);

      if (!Array.isArray(res.data.deviceList)) return;

      const deviceList = res.data.deviceList.map((item) => {
        let datetime = moment.utc(item.purchase_date).local();
        item.purchase_date = datetime.format("YYYY-MM-DD");
        datetime = moment.utc(item.repair_date).local();
        item.repair_date = datetime.format("YYYY-MM-DD");
        datetime = moment.utc(item.battery_change_date).local();
        item.battery_change_date = datetime.format("YYYY-MM-DD");

        if (!item.info) item.info = "-";
        return item;
      });

      setGridData(deviceList);
    });
    apiCallTime = runTime;
  };

  const onInsertDevice = (ref) => {
    if (!ref) return;
    ref.current.api.deselectAll();
    setSelectedDevice([]);
    setDeviceDlgTitle(language.insert);
    setOpenDeviceDlg(true);
  };

  const onUpdateDevice = (ref) => {
    if (!ref) return;
    const selected = ref.current.api.getSelectedRows();
    if (!Array.isArray(selected) || selected.length !== 1) {
      setMessageDlgContents(language.errorMsg.devcieSelect);
      setOpenMessageDlg(true);
      return;
    }

    setSelectedDevice(selected);
    setDeviceDlgTitle(language.update);
    setOpenDeviceDlg(true);
  };

  const onDeleteDevice = (ref) => {
    if (!ref) return;

    const selected = ref.current.api.getSelectedRows();
    if (!Array.isArray(selected) || selected.length < 1) {
      setMessageDlgContents(language.errorMsg.devcieSelect);
      setOpenMessageDlg(true);
      return;
    }

    let device = "";
    selected.forEach((item, idx) => {
      if (idx !== 0) device += ", ";

      device += item.device_name;
    });

    setSelectedDevice(selected);
    setYesNoDlgTitle(language.delete);
    setYesNoDlgContents(language.msg.deleteMsg.replace("X", device));
    setOpenYesNoDlg(true);
  };

  const onChangePrevPage = () => {
    if (selectedPage < 2) return;

    setGridData([]);
    setSelectedPage(selectedPage - 1);
  };

  const onChangeNextPage = () => {
    if (selectedPage === endPage) return;

    setGridData([]);
    setSelectedPage(selectedPage + 1);
  };

  const handleRowDoubleClicked = (e) => {
    if (!e) return;
    e.api.deselectAll();

    setSelectedDevice([e.data]);
    setDeviceDlgTitle(language.update);
    setOpenDeviceDlg(true);
  };

  const onCloseMessageDlg = () => {
    setMessageDlgContents("");
    setOpenMessageDlg(false);
  };

  const onApplyYesNoDlg = () => {
    onCloseYesNoDlg();
    if (yesnoDlgTitle !== language.delete) return;

    if (!auth || !auth.uuid) return dispatch(logout());

    setBackDrop(true);
    const runTime = new Date().getTime();
    const apiPath = "api/delete/device";

    sendBackend(
      apiPath,
      { uuid: auth.uuid, deviceList: selectedDevice },
      (success, res) => {
        setBackDrop(false);
        if (!success || !res || !res.data) {
          if (res.data && res.data.code === 3000) {
            dispatch(logout());
            if (props.onClose) props.onClose();
            return;
          }

          return;
        }

        if (apiCallTime !== runTime) return;

        setGridData([]);
        setSearch("");
        setSelectedPage(1);
        onSearchTable({ search: "", page: 1 });
      }
    );

    apiCallTime = runTime;
  };

  const onCloseYesNoDlg = () => {
    setYesNoDlgTitle("");
    setYesNoDlgContents("");
    setOpenYesNoDlg(false);
  };

  const handleDeviceDlgApply = () => {
    setSearch("");
    setSelectedPage(1);
    setGridData([]);
    onSearchTable({ search: "", page: 1 });
    handleDeviceDlgClose();
  };

  const handleDeviceDlgClose = () => {
    setOpenDeviceDlg(false);
  };

  const renderTableView = () => {
    return (
      <Grid xs item container direction="column">
        <Grid item xs style={{ padding: "20px 0px 20px" }}>
          <AgGrid
            tableStyle={table.type1}
            tableContentsStyle={table.contents1}
            rowData={gridData}
            defaultColDef={columnDefs}
            columnDefs={gridColumnDefs}
            suppressSizeToFit={true}
            suppressMovableColumns={true}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            overlayLoadingTemplate={"<span></span>"}
            overlayNoRowsTemplate={"<span></span>"}
            renderControl={renderTableControlView}
            onRowDoubleClicked={handleRowDoubleClicked}
          />
        </Grid>
        {endPage > 1 && (
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item style={{ padding: "10px 5px" }}>
              <Button
                sx={button.type2_unfocused}
                onClick={() => onChangePrevPage()}
              >
                {language.prev}
              </Button>
            </Grid>
            <Grid item style={{ padding: "10px 20px" }}>
              <Typography
                style={fonts.b_16_w}
              >{`${selectedPage} / ${endPage}`}</Typography>
            </Grid>
            <Grid item style={{ padding: "10px 0px 10px 5px" }}>
              <Button
                sx={button.type2_unfocused}
                onClick={() => onChangeNextPage()}
              >
                {language.next}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderTableControlView = (gridRef) => {
    return (
      <Grid container direction="row" alignItems="center" sx={table.toolbar1}>
        {/* 버튼 리스트 */}
        <Grid item xl lg md sm={12} xs={12}>
          <Grid container alignItems="center">
            {/* 추가 버튼 */}
            <Grid item style={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <Button sx={button.table} onClick={() => onInsertDevice(gridRef)}>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <AddRoundedIcon
                      sx={{ mt: 1, mr: 1 }}
                      style={fonts.b_20_w}
                    />
                  </Grid>
                  <Grid item style={{ paddingTop: "1px" }}>
                    <Typography style={fonts.b_14_w}>
                      {language.insert}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            {/* 수정 버튼 */}
            <Grid item style={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <Button sx={button.table} onClick={() => onUpdateDevice(gridRef)}>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <CreateRoundedIcon
                      sx={{ mt: 1, mr: 1 }}
                      style={fonts.b_20_w}
                    />
                  </Grid>
                  <Grid item style={{ paddingTop: "1px" }}>
                    <Typography style={fonts.b_14_w}>
                      {language.update}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            {/* 삭제 버튼 */}
            <Grid item style={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <Button sx={button.table} onClick={() => onDeleteDevice(gridRef)}>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <DeleteIcon sx={{ mt: 1, mr: 1 }} style={fonts.b_20_w} />
                  </Grid>
                  <Grid item style={{ paddingTop: "1px" }}>
                    <Typography style={fonts.b_14_w}>
                      {language.delete}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            {/* 내보내기 버튼 */}
            {/* <Grid item style={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <Button sx={button.table}>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <FileDownloadRoundedIcon
                      sx={{ mt: 1, mr: 1 }}
                      style={fonts.b_20_w}
                    />
                  </Grid>
                  <Grid item style={{ paddingTop: "1px" }}>
                    <Typography style={fonts.b_14_w}>
                      {language.refresh}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid> */}
            {/* 인쇄버튼 */}
            {/* <Grid item style={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <Button sx={button.table}>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <LocalPrintshopRoundedIcon
                      sx={{ mt: 1, mr: 1 }}
                      style={fonts.b_20_w}
                    />
                  </Grid>
                  <Grid item style={{ paddingTop: "1px" }}>
                    <Typography style={fonts.b_14_w}>
                      {language.print}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
        {/* <Grid
          item
          xl={4}
          lg={4}
          md={5}
          sm={12}
          xs={12}
          style={{ padding: "10px 20px 0px 10px" }}
        >
          <Paper sx={paper.type1}>
            <Grid
              container
              alignItems="stretch"
              justifyContent="flex-end"
              style={{ height: "100%" }}
            >
              <Grid item xs>
                <TextField
                  value={search}
                  placeholder={language.search}
                  sx={textfield.type1}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ visibility: search ? "none" : "hidden" }}
                        onClick={() => setSearch("")}
                      >
                        <CloseRoundedIcon style={icons.gray_20} />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    setGridData([]);
                    setSelectedPage(1);
                    onSearchTable({ search, page: 1 });
                  }}
                >
                  <SearchRoundedIcon style={fonts.b_20_w} />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}
      </Grid>
    );
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={styles.screens}
    >
      {openMessageDlg && (
        <MessageDlg
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          contents={messageDlgContents}
          onClose={onCloseMessageDlg}
        />
      )}
      {openYesNoDlg && (
        <YesNoDlg
          isOpen={openYesNoDlg}
          title={yesnoDlgTitle}
          contents={yesnoDlgContents}
          apply={language.delete}
          close={language.close}
          onApply={onApplyYesNoDlg}
          onClose={onCloseYesNoDlg}
        />
      )}
      {openDeviceDlg && (
        <DeviceDlg
          isOpen={openDeviceDlg}
          title={deviceDlgTitle}
          device={selectedDevice}
          onApply={handleDeviceDlgApply}
          onClose={handleDeviceDlgClose}
        />
      )}
      <Grid item>
        <Header pageName={language.deviceManagement} />
      </Grid>
      {renderTableView()}
      <Backdrop open={backDrop} style={{ zIndex: 1001, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default Device;
