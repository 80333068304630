import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  Typography,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";

import CryptoJS from "crypto-js";

import { MessageDlg } from "../../../components";

import styles from "../styles";
import icons from "../../../icons";
import fonts from "../../../fonts";
import { button, textfield } from "../components";

import Language from "../../../language";
import { login, setHeaderVisibility, setHeaderMoving } from "../../../redux";
import { sendBackend } from "../../../utils";

function Login(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = Language();

  const [userID, setUserID] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const messageTitle = "";
  const [openMessageDlg, setOpenMessageDlg] = useState(false);

  const [backDrop, setBackDrop] = useState(false);

  const auth = useSelector((state) => state.auth, shallowEqual);
  const header = useSelector((state) => state.header, shallowEqual);

  useEffect(() => {
    if (!auth.isLoggedIn) return navigate("/login", { replace: true });
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (header.visibility) dispatch(setHeaderVisibility(false));
  }, [header.visibility]);

  useEffect(() => {
    if (header.state !== -1)
      dispatch(setHeaderMoving({ state: -1, moving: false }));
  }, [header.state]);

  const onChangeUserID = (e) => {
    setUserID(e.target.value);
  };

  const onChangeUserPassword = (e) => {
    if (e.target.value) e.target.value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, "");
    setUserPassword(e.target.value);
  };

  const onClickLoginButton = () => {
    let uuid = "";
    if (userID.length < 4 || userPassword.length < 4) {
      return setOpenMessageDlg(true);
    }

    try {
      uuid = CryptoJS.AES.encrypt(
        JSON.stringify({ user_id: userID, password: userPassword }),
        process.env.REACT_APP_PUBLIC_API_SECRET_KEY
      ).toString();
    } catch (e) {
      return setOpenMessageDlg(true);
    }

    setBackDrop(true);
    const apiPath = "api/auth/login";
    const queryData = { uuid };
    sendBackend(apiPath, queryData, (success, res) => {
      setBackDrop(false);
      if (success && res && res.data) {
        dispatch(
          login({
            ...queryData,
            name: res.data.name,
            auth_type: res.data.auth_type,
          })
        );
        dispatch(setHeaderMoving(true));
        dispatch(setHeaderVisibility(true));
        setTimeout(() => navigate("/dashboard", { replace: true }));
        return;
      }
      return setOpenMessageDlg(true);
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onClickLoginButton();
    }
  };

  const handleCloseMessageDlg = () => {
    setOpenMessageDlg(false);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={styles.contents}
    >
      {openMessageDlg && (
        <MessageDlg
          isOpen={openMessageDlg}
          title={messageTitle}
          contents={language.loginFailed}
          closeName={language.close}
          onClose={handleCloseMessageDlg}
        />
      )}
      <Grid item style={{ padding: 10 }}>
        <img src="img/HWAY_logo.png" alt="logo" style={icons.logo} />
      </Grid>
      <Grid
        xs
        item
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={styles.contents}
      >
        <Grid item style={styles.loginTitle}>
          <Typography style={fonts.login_title}>{language.service}</Typography>
        </Grid>
        <Grid item style={styles.loginPaper}>
          <Grid container direction="column" alignItems="stretch">
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              style={{ padding: "5px 0px" }}
            >
              <Grid item style={{ padding: "0px 5px" }}>
                <PersonIcon style={{ ...icons.white_32, paddingTop: "5px" }} />
              </Grid>
              <Grid item xs style={{ padding: "0px 8px" }}>
                <TextField
                  value={userID}
                  placeholder={language.id}
                  onChange={onChangeUserID}
                  sx={textfield.type1}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="stretch"
            justifyContent="center"
          >
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
              style={{ padding: "5px 0px" }}
            >
              <Grid item style={{ padding: "0px 5px" }}>
                <LockIcon style={{ ...icons.white_32, paddingTop: "5px" }} />
              </Grid>
              <Grid item xs style={{ padding: "0px 8px" }}>
                <TextField
                  type={"password"}
                  value={userPassword}
                  placeholder={language.password}
                  onChange={onChangeUserPassword}
                  sx={textfield.type1}
                  onKeyUp={handleKeyPress}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ padding: "20px 8px 0px" }}>
            <Button
              onClick={onClickLoginButton}
              style={{ ...fonts.b_16_w, ...button.login }}
            >
              {language.login}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop open={backDrop} style={{ zIndex: 1001, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default Login;
