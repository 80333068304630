import colors from "../../colors";

const styles = {
  contents: { height: "100%", width: "100%" },
  header: { backgroundColor: colors.company2 },
  screens: {
    minHeight: "100%",
    padding: "20px 40px 40px 40px",
    overflow: "auto",
  },
  loginTitle: { padding: "10px 20px" },
  loginPaper: {
    backgroundColor: colors.company2,
    padding: "40px 120px",
    borderRadius: 10,
    border: `1px solid #4D4D8F`,
  },
  alarmPaper: {
    border: `2px solid ${colors.company1}`,
    borderRadius: "5px",
    padding: "10px",
    backgroundColor: colors.company2,
    minHeight: "115px",
    maxHeight: "115px",
  },
  dashboardPaper: {
    height: "100%",
    border: `2px solid ${colors.company1}`,
    borderRadius: "5px",
    marginBottom: "15px",
    backgroundColor: colors.company4,
  },
  devicePaper: {
    height: "100%",
    padding: "10px",
    minWidth: "300px",
    border: `2px solid ${colors.company1}`,
    borderRadius: "5px",
    backgroundColor: colors.company2,
  },
  deviceListPaper: {
    borderRadius: 8,
    height: "100%",
    backgroundColor: colors.company2,
  },
  googleMapPaper: { height: "100%", minHeight: "200px" },
  detailPaper: {
    height: "100%",
    padding: "10px",
    minWidth: "300px",
    border: `2px solid ${colors.company1}`,
    borderRadius: "5px",
    backgroundColor: colors.company2,
  },
  deviceTitlePaper: {
    padding: "10px 10px 10px 20px",
    backgroundColor: colors.company,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomWidth: 0,
    border: `2px solid ${colors.company1}`,
    backgroundColor: colors.company4,
  },
  deviceContentsPaper: {
    padding: "10px",
    backgroundColor: colors.company,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    border: `2px solid ${colors.company1}`,
    backgroundColor: colors.company4,
    borderTopWidth: 0,
  },
};

export default styles;
