import colors from "../colors";

const styles = {
  login_title: {
    fontFamily: "NanumSquareR",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "center",
    fontSize: "30px",
    color: colors.white,
  },
  default: {
    fontFamily: "NanumSquareR",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
  },
  n_12_w: {
    fontFamily: "NanumSquareR",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "12px",
    letterSpacing: "normal",
    color: colors.white,
  },
  n_14_w: {
    fontFamily: "NanumSquareR",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "14px",
    letterSpacing: "normal",
    color: colors.white,
  },
  n_14_gr: {
    fontFamily: "NanumSquareR",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "14px",
    letterSpacing: "normal",
    color: colors.gray,
  },
  n_14_r: {
    fontFamily: "NanumSquareR",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "14px",
    letterSpacing: "normal",
    color: colors.red,
  },
  n_14_b: {
    fontFamily: "NanumSquareR",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "14px",
    letterSpacing: "normal",
    color: colors.black,
  },
  n_14_y: {
    fontFamily: "NanumSquareR",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "14px",
    letterSpacing: "normal",
    color: colors.yellow,
  },
  n_14_g: {
    fontFamily: "NanumSquareR",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "14px",
    letterSpacing: "normal",
    color: colors.green,
  },
  b_14_w: {
    fontFamily: "NanumSquareR",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "14px",
    letterSpacing: "normal",
    color: colors.white,
  },
  n_15_w: {
    fontFamily: "NanumSquareR",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "15px",
    letterSpacing: "normal",
    color: colors.white,
  },
  n_16_w: {
    fontFamily: "NanumSquareR",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "16px",
    letterSpacing: "normal",
    color: colors.white,
  },
  b_16_bu: {
    fontFamily: "NanumSquareR",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "16px",
    letterSpacing: "normal",
    color: colors.blue1,
  },
  n_16_company: {
    fontFamily: "NanumSquareR",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "16px",
    letterSpacing: "normal",
    color: colors.company,
  },
  b_16_w: {
    fontFamily: "NanumSquareR",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "16px",
    letterSpacing: "normal",
    color: colors.white,
  },
  b_16_b: {
    fontFamily: "NanumSquareR",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "16px",
    letterSpacing: "normal",
    color: colors.black,
  },
  n_18_o: {
    fontFamily: "NanumSquareR",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "18px",
    letterSpacing: "normal",
    color: colors.orange,
  },
  n_18_w: {
    fontFamily: "NanumSquareR",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "18px",
    letterSpacing: "normal",
    color: colors.white,
  },
  b_18_w: {
    fontFamily: "NanumSquareR",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "18px",
    letterSpacing: "normal",
    color: colors.white,
  },
  b_18_b: {
    fontFamily: "NanumSquareR",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "18px",
    letterSpacing: "normal",
    color: colors.black,
  },
  b_20_w: {
    fontFamily: "NanumSquareR",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "20px",
    letterSpacing: "normal",
    color: colors.white,
  },
  b_20_b: {
    fontFamily: "NanumSquareR",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    fontSize: "20px",
    letterSpacing: "normal",
    color: colors.black,
  },
};

export default styles;
