import colors from "../../colors";

const global = {
  button: {
    "& > *": {
      margin: "0px",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    outline: "none !important",
    textTransform: "none",
  },
  iconbutton: { "& > *": { margin: "0px" }, outline: "none !important" },
  list: { "& > *": { margin: "0px" }, outline: "none !important" },
};

export const dialog = {
  toolbar: {
    position: "relative",
    backgroundColor: "#303064",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
};

export const button = {
  type1: {
    ...global.button,
    width: "100%",
    padding: "0px 20px",
    textAlign: "center",
    height: "36px",
    minWidth: "100px",
    border: `3px solid ${colors.company1}`,
    backgroundColor: colors.company2,
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.company4,
      borderColor: colors.company3,
    },
  },
};
