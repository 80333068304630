import React, { useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { Grid } from "@mui/material";

const AgGrid = (props) => {
  const gridRef = useRef();

  return (
    <Grid container direction="column" sx={props.tableStyle}>
      <Grid item>{props.renderControl && props.renderControl(gridRef)}</Grid>
      <Grid item xs sx={props.tableContentsStyle}>
        <div className="ag-theme-type-0">
          <div className="ag-theme-alpine">
            <AgGridReact {...props} ref={gridRef} />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default AgGrid;
