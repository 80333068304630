import colors from "../../colors";
import fonts from "../../fonts";

const global = {
  button: {
    "& > *": {
      margin: "0px",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    outline: "none !important",
    textTransform: "none",
  },
  iconbutton: { "& > *": { margin: "0px" }, outline: "none !important" },
  list: { "& > *": { margin: "0px" }, outline: "none !important" },
};

export const button = {
  login: {
    width: "100%",
    height: "70px",
    border: `1px solid #222246`,
    color: "#ffffff",
    backgroundColor: colors.company1,
  },
  type1_focused: {
    ...global.button,
    borderRadius: "10px",
    border: `2px solid ${colors.company4}`,
    padding: "6px 10px",
    width: "100%",
    minWidth: "60px",
    color: colors.white,
    backgroundColor: colors.company3,
    "&:hover": {
      backgroundColor: colors.company,
      borderColor: colors.company4,
    },
  },
  type1_unfocused: {
    ...global.button,
    borderRadius: "10px",
    border: `2px solid ${colors.company4}`,
    padding: "6px 10px",
    width: "100%",
    minWidth: "60px",
    color: colors.white,
    backgroundColor: colors.company,
    "&:hover": {
      backgroundColor: colors.company,
      borderColor: colors.company3,
    },
  },
  type2_unfocused: {
    ...global.button,
    borderRadius: "10px",
    border: `2px solid ${colors.company1}`,
    padding: "10px 40px",
    width: "100%",
    minWidth: "60px",
    color: colors.white,
    backgroundColor: colors.company2,
    "&:hover": {
      backgroundColor: colors.company4,
      borderColor: colors.company3,
    },
  },
  type3_unfocused: {
    ...global.button,
    borderRadius: "10px",
    border: `2px solid ${colors.company1}`,
    padding: "2px 5px 2px 2px",
    width: "100%",
    color: colors.white,
    backgroundColor: colors.company2,
    "&:hover": {
      backgroundColor: colors.company4,
      borderColor: colors.company3,
    },
  },
  type4: {
    ...global.button,
    width: "100%",
    padding: "0px 20px",
    textAlign: "center",
    height: "36px",
    minWidth: "100px",
    border: `2px solid ${colors.company1}`,
    backgroundColor: colors.company2,
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.company4,
      borderColor: colors.company3,
    },
  },

  type5: {
    border: 2,
    borderRadius: "5%",
    borderColor: colors.company,
    padding: "10px",
    width: "120px",
    height: "80px",
    color: colors.white,
    backgroundColor: colors.company4,
    "&:hover": {
      backgroundColor: colors.company,
      borderColor: colors.company3,
    },
  },

  // table control button
  table: {
    ...global.button,
    padding: "2px 10px",
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.company1,
    },
  },

  type6: {
    padding: "0px 10px 0px 6px",
    height: "30px",
    textAlign: "center",
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.company1,
    },
  },
};

export const iconbutton = {
  type1: {
    ...global.iconbutton,
    padding: "6px 8px",
    color: colors.white,
    backgroundColor: colors.company,
    "&:hover": {
      backgroundColor: colors.company1,
    },
  },
  type2: {
    ...global.iconbutton,
    padding: "6px 8px",
    color: colors.white,
    backgroundColor: "none",
    "&:hover": {
      backgroundColor: colors.company1,
    },
  },
  type3_focused: {
    ...global.iconbutton,
    padding: "3px",
    color: colors.white,
    backgroundColor: colors.company3,
    "&:hover": {
      backgroundColor: colors.company1,
    },
  },
  type3_unfocused: {
    padding: "3px",
    color: colors.white,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: colors.company1,
    },
  },
};

export const combobox = {
  type1: {
    ...global.button,
    width: "100%",
    padding: "0px 10px 0px 20px",
    textAlign: "center",
    border: `2px solid ${colors.company1}`,
    backgroundColor: colors.company2,
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.company4,
      borderColor: colors.company3,
    },
  },
  item1: {
    ...global.button,
    height: "100%",
    width: "100%",
    minWidth: "150px",
    maxWidth: "220px",
    textAlign: "center",
    justifyContent: "left",
    borderRadius: "0px",
    backgroundColor: colors.company1,
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.company4,
    },
  },
};

export const list = {
  type1: {
    ...global.list,
    height: "100%",
    padding: "0px",
    width: "100%",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "16px",
      backgroundColor: colors.company4,
    },
    "&::-webkit-scrollbar-thumb": {
      // backgroundColor: "rgba(0,0,0,.1)",
      // outline: "1px solid slategrey",
      border: "4px solid transparent",
      borderRadius: "8px",
      backgroundColor: colors.company1,
      backgroundClip: "padding-box",
    },
    "&::-webkit-scrollbar-track": {
      // boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      // webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      // borderRadius: "10px",
      border: "2px solid transparent",
      backgroundColor: colors.company2,
      backgroundClip: "padding-box",
    },
  },
  item1: {
    padding: "0px",
    height: "32px",
  },
  item2: {
    padding: "5px",
    textTransform: "none",
    width: "100%",
    borderRadius: "6px",
    color: colors.white,
    border: `2px solid ${colors.company1}`,
    backgroundColor: colors.company2,
    "&:hover": {
      borderColor: colors.company3,
    },
  },
};

export const table = {
  type1: {
    height: "100%",
    borderRadius: 5,
    backgroundColor: "#222246",
    border: `1px solid ${colors.company1}`,
  },
  toolbar1: { height: "100%" },
  contents1: {
    padding: "10px 20px",
  },
};

export const datepicker = {
  type1: {
    "& .MuiInputBase-root ": {
      width: "180px",
      borderRadius: "10px",
      color: colors.white,
      borderColor: colors.company4,
      backgroundColor: colors.company1,
      "&:hover fieldset": {
        borderColor: colors.company3,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.company3,
      },
      ...fonts.b_14_w,
    },
    "& .MuiInputBase-input ": {
      padding: "10px 10px 8px",
      borderRadius: "10px",
      backgroundColor: colors.company1,
    },
    "& .MuiButtonBase-root": {
      color: colors.white,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: colors.company4,
      "&.Mui-focused": {
        borderColor: colors.company3,
      },
    },
  },
};

export const textfield = {
  type1: {
    width: "100%",
    "& .MuiInput-underline:after": {
      border: "none",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": { border: "none" },
      "&:hover fieldset": { border: "none" },
      "&.Mui-focused fieldset": {
        border: "none",
      },
      backgroundColor: "#ffffff",
      padding: 0,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 10px 7px",
    },
    "& .MuiButtonBase-root": {
      margin: "0px",
    },
    "& .MuiInputBase-input": {
      fontFamily: "NanumSquareR",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      borderRadius: 5,
    },
    "& .MuiInputBase-input[type=password]": {
      fontFamily: "NanumGothic",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      borderRadius: 5,
    },
  },
};

export const paper = {
  type1: {
    width: "100%",
    margin: "0 5px",
    // borderRadius: 5,
    backgroundColor: colors.company1,
  },
};

export const popover = {
  type1: {
    minWidth: "100px",
  },
};
