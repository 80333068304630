import colors from "../../colors";

export const svgMarker = {
  path: `M10,3C7.24,3,5,5.09,5,7.67s3,7.47,5,9.33c2.5-2.33,5-6.76,5-9.33S12.76,3,10,3z M10,9C8.9,9,8,8.1,8,7
    c0-1.1,0.9-2,2-2s2,0.9,2,2C12,8.1,11.1,9,10,9z`,
  fillColor: colors.white,
  fillOpacity: 0.8,
  strokeWeight: 0,
  rotation: 0,
  scale: 2,
};
