import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  Button,
  IconButton,
  Typography,
  List,
  ListItem,
  Backdrop,
  CircularProgress,
  Popover,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

import { LineChart, MessageDlg } from "..";

import fonts from "../../fonts";
import icons from "../../icons";
import colors from "../../colors";

import { containers } from "./styles";
import { button, combobox, list, datepicker } from "./styles";
import { iconbutton } from "./styles";

import Language from "../../language";
import { logout } from "../../redux";

import { sendBackend, setDateFormat } from "../../utils";

import { Buffer } from "buffer";
import moment from "moment-timezone";

let apiCallTime = undefined;
function CameraGraphDlg(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = Language();

  const [deviceList, setDeviceList] = useState([]);

  const [selectedDevice, setSelectedDevice] = useState(undefined);

  const [startDate, setStartDate] = useState(
    setDateFormat(moment().local().add(-6, "days"))
  );
  const [endDate, setEndDate] = useState(setDateFormat(moment().local()));

  const [latestData, setLatestData] = useState(undefined);
  const [stateData, setStateData] = useState([]);
  const [temperatureData, setTemperatureData] = useState([]);

  const [deviceListRef, setDeviceListRef] = useState(undefined);
  const openDeviceList = Boolean(deviceListRef);

  const messageDlgTitle = language.warning;
  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgContents, setMessageDlgContents] = useState("");

  const [backDrop, setBackDrop] = useState(false);

  const auth = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    if (!auth.isLoggedIn) return onClose();
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (props.device) {
      const device =
        Array.isArray(props.device) && props.device.length > 0
          ? props.device[0]
          : props.device;

      setSelectedDevice(device);
      setTimeout(() => onSearchGraphData({ device, startDate, endDate }));
    }
  }, [props.device]);

  const onSearchDeviceList = (callback) => {
    if (!auth || !auth.uuid) return onClose();

    setBackDrop(true);
    const runTime = new Date().getTime();
    const apiPath = "api/search/device";
    sendBackend(apiPath, { uuid: auth.uuid }, (success, res) => {
      setBackDrop(false);
      if (!success || !res || !res.data) {
        if (res.data && res.data.code === 3000) {
          dispatch(logout());
          return navigate("/login", { replace: true });
        }
        return;
      }

      if (apiCallTime !== runTime) return;

      if (res.data.deviceList) setDeviceList(res.data.deviceList);

      if (callback) callback();
    });

    apiCallTime = runTime;
  };

  const onSearchGraphData = (params) => {
    if (!auth || !auth.uuid) return onClose();

    params.startDate = moment(params.startDate).utc();
    params.endDate = moment(params.endDate).utc().add(1, "days");

    if (params.endDate.valueOf() < params.startDate.valueOf()) {
      setMessageDlgContents(language.errorMsg.period);
      setOpenMessageDlg(true);
      return;
    }

    setBackDrop(true);
    const runTime = new Date().getTime();
    const apiPath = "api/search/cameraGraph";

    sendBackend(apiPath, { uuid: auth.uuid, ...params }, (success, res) => {
      setBackDrop(false);
      if (!success || !res || !res.data) {
        if (res.data && res.data.code === 3000) {
          dispatch(logout());
          return navigate("/login", { replace: true });
        }
        return;
      }

      if (apiCallTime !== runTime) return;

      if (res.data.latest) {
        const latest = res.data.latest;
        latest.min = latest.min ? (parseInt(latest.min) / 100).toFixed(2) : 0;
        latest.max = latest.max ? (parseInt(latest.max) / 100).toFixed(2) : 0;
        latest.avg = latest.avg ? (parseInt(latest.avg) / 100).toFixed(2) : 0;

        switch (latest.state) {
          case "0":
            latest.state = language.normal;
            break;
          case "1":
            latest.state = language.warning;
            break;
          case "2":
            latest.state = language.error;
            break;
          default:
            latest.state = "-";
            break;
        }

        if (latest.data && latest.contentType) {
          let base64Flag = `data:${latest.contentType}; base64,`;
          let imageStr = Buffer.from(latest.data).toString("base64");
          latest.path = base64Flag + imageStr;
        }
        setLatestData(latest);
      }

      if (Array.isArray(res.data.graphData) && res.data.graphData.length > 0) {
        const _stateData = [];
        const _temperatureData = [];
        res.data.graphData.forEach((data) => {
          if (data.created_date) {
            data.datetime = moment.utc(data.created_date).local();
            data.created_date = data.datetime.format("YYYY-MM-DD");
          }

          _stateData.push({
            created_date: data.created_date,
            state: data.state ? parseInt(data.state) : 0,
          });

          if (!data.temperature_value) return;
          const temp = data.temperature_value.split(",");
          if (temp.length !== 3) return;

          _temperatureData.push({
            created_date: data.created_date,
            min: temp[0] ? (parseInt(temp[0]) / 100).toFixed(2) : 0,
            max: temp[1] ? (parseInt(temp[1]) / 100).toFixed(2) : 0,
            avg: temp[2] ? (parseInt(temp[2]) / 100).toFixed(2) : 0,
          });
        });

        setStateData(_stateData);
        setTemperatureData(_temperatureData);
      } else {
        const days = params.endDate.diff(params.startDate, "days");
        if (!days) return;

        const _graphData = [];
        const created_date = params.startDate.local();
        for (const time of new Array(days)) {
          _graphData.push({
            created_date: created_date.format("YYYY-MM-DD"),
          });
          created_date.add(1, "days");
        }

        setStateData([..._graphData]);
        setTemperatureData([..._graphData]);
      }
    });

    apiCallTime = runTime;
  };

  const handleDeviceList = (e) => {
    const target = e.currentTarget;
    onSearchDeviceList(setTimeout(() => setDeviceListRef(target)));
  };

  const onChangeDevice = (params) => {
    setSelectedDevice(params);
    setDeviceListRef(undefined);
    setLatestData(undefined);
    setStateData([]);
    setTemperatureData([]);
    setTimeout(() => onSearchGraphData({ device: params, startDate, endDate }));
  };

  const onChangeStartDate = (val) => {
    let end = endDate;
    const start = setDateFormat(moment(val));

    if (start.valueOf() > endDate.valueOf())
      end = setDateFormat(moment(val).add(1, "days"));

    setStartDate(start);
    setEndDate(end);
  };

  const onChangeEndDate = (val) => {
    let start = startDate;
    const end = setDateFormat(moment(val));

    if (end.valueOf() < startDate.valueOf())
      start = setDateFormat(moment(val).add(-1, "days"));

    setStartDate(start);
    setEndDate(setDateFormat(moment(val)));
  };

  const onCloseMessageDlg = () => {
    setMessageDlgContents("");
    setOpenMessageDlg(false);
  };

  const onClose = () => {
    props.onClose();
  };

  const renderDevicePopover = () => {
    return (
      <Popover
        anchorEl={deviceListRef}
        open={openDeviceList}
        onClose={() => setDeviceListRef(undefined)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock={true}
        style={{ marginTop: "2px" }}
      >
        <List sx={containers.comboboxView}>
          {Array.isArray(deviceList) &&
            deviceList.map((val, idx) => {
              return (
                <ListItem key={`device-combo-${idx}`} sx={list.item1}>
                  <Button
                    sx={combobox.item1}
                    onClick={() => onChangeDevice(val)}
                  >
                    <Typography style={fonts.n_14_w}>
                      {val.device_name}
                    </Typography>
                  </Button>
                </ListItem>
              );
            })}
        </List>
      </Popover>
    );
  };

  const renderControl = () => {
    const deviceName = selectedDevice
      ? `${language.deviceName} [${selectedDevice.device_name}]`
      : language.deviceList;

    return (
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: "10px 0px" }}
      >
        <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
          <Grid container alignItems="center" style={{ minWidth: "230px" }}>
            {/* 뒤로가기 */}
            <Grid item xl={2} lg={2} md={2}>
              <IconButton sx={iconbutton.type1} onClick={onClose}>
                <ArrowBackRoundedIcon />
              </IconButton>
            </Grid>
            {/* 장비 목록 드롭다운 */}
            <Grid item xl={10} lg={10} md={10}>
              <Button sx={combobox.type1} onClick={handleDeviceList}>
                <Typography style={fonts.n_14_w}>{deviceName}</Typography>
                <ArrowDropDownRoundedIcon sx={icons.white_32} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  sx={datepicker.type1}
                  value={startDate.toDate()}
                  format="yyyy-MM-dd"
                  onChange={onChangeStartDate}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item style={{ padding: "0px 10px" }}>
              <Typography style={fonts.b_16_w}>{"-"}</Typography>
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  sx={datepicker.type1}
                  value={endDate.toDate()}
                  format="yyyy-MM-dd"
                  onChange={onChangeEndDate}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item style={{ paddingLeft: "20px" }}>
              <Button
                sx={button.type4}
                onClick={() => {
                  setLatestData(undefined);
                  setStateData([]);
                  setTemperatureData([]);
                  setTimeout(() =>
                    onSearchGraphData({
                      device: selectedDevice,
                      startDate,
                      endDate,
                    })
                  );
                }}
              >
                <Typography style={{ ...fonts.n_14_w, paddingTop: "3px" }}>
                  {language.search}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderLatestCamera = () => {
    const path =
      latestData && latestData.path ? latestData.path : "/img/default_img.JPG";
    return <img src={path} style={icons.camera} />;
  };

  const renderLatestState = () => {
    const stateValue = latestData ? latestData.state : "-";
    const minValue = latestData ? latestData.min : "0";
    const maxValue = latestData ? latestData.max : "0";
    const avgValue = latestData ? latestData.avg : "0";

    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ padding: "10px 0px", height: "100%" }}
      >
        <Grid item>
          <Typography style={fonts.b_18_w}>{language.state}</Typography>
        </Grid>
        <Grid
          item
          style={{ ...containers.stateView, backgroundColor: colors.white }}
        >
          <Typography style={fonts.b_18_b}>{stateValue}</Typography>
        </Grid>
        <Grid item style={{ paddingTop: "10px" }}>
          <Typography style={fonts.b_18_w}>{language.max}</Typography>
        </Grid>
        <Grid
          item
          style={{ ...containers.stateView, backgroundColor: colors.red1 }}
        >
          <Typography style={fonts.b_18_b}>{maxValue} ℃</Typography>
        </Grid>
        <Grid item style={{ paddingTop: "10px" }}>
          <Typography style={fonts.b_18_w}>{language.min}</Typography>
        </Grid>
        <Grid
          item
          style={{ ...containers.stateView, backgroundColor: colors.blue1 }}
        >
          <Typography style={fonts.b_18_b}>{minValue} ℃</Typography>
        </Grid>
        <Grid item style={{ paddingTop: "10px" }}>
          <Typography style={fonts.b_18_w}>{language.avg}</Typography>
        </Grid>
        <Grid
          item
          style={{
            ...containers.stateView,
            backgroundColor: colors.yellow1,
          }}
        >
          <Typography style={fonts.b_18_b}>{avgValue} ℃</Typography>
        </Grid>
      </Grid>
    );
  };

  const renderStateGraph = () => {
    return (
      <LineChart
        data={stateData}
        margin={{ top: 20, right: 40, left: 20, bottom: 0 }}
        option={{
          xaxis: {
            dataKey: "created_date",
            domain: ["auto", "auto"],
            scale: "auto",
            type: "category",
            dy: 5,
            dx: -5,
          },
          yaxis: {
            tickFormatter: (val) => {
              switch (val) {
                case 0:
                  return language.normal;
                case 1:
                  return language.warning;
                case 2:
                  return language.error;
                default:
                  return "";
              }
            },
            dataKey: "state",
            domain: [-1, 3],
          },
          line: {
            count: 1,
            dataKey: ["state"],
            option: [
              {
                stroke: "#ffffff",
                strokeWidth: 3,
                isAnimationActive: false,
              },
            ],
          },
          cartesianGrid: { strokeDasharray: "3 3" },
          tooltip: {
            cursor: { fill: "#000000" },
            labelStyle: { color: "#ffffff" },
            contentStyle: { backgroundColor: "#000000" },
            isAnimationActive: false,
            labelFormatter: (val) => moment(val).format("YYYY-MM-DD HH:mm:ss"),
            formatter: (val) => {
              switch (val) {
                case 0:
                  return language.normal;
                case 1:
                  return language.warning;
                case 2:
                  return language.error;
                default:
                  return "-";
              }
            },
          },
        }}
      />
    );
  };

  const renderTempGraph = () => {
    return (
      <LineChart
        data={temperatureData}
        margin={{ top: 20, right: 40, left: 20, bottom: 0 }}
        option={{
          xaxis: {
            dataKey: "created_date",
            domain: ["auto", "auto"],
            scale: "auto",
            type: "category",
            dy: 5,
            dx: -5,
          },
          yaxis: {},
          line: {
            count: 3,
            dataKey: ["min", "max", "avg"],
            option: [
              {
                stroke: colors.blue1,
                strokeWidth: 3,
                isAnimationActive: false,
              },
              {
                stroke: colors.red1,
                strokeWidth: 3,
                isAnimationActive: false,
              },
              {
                stroke: colors.yellow1,
                strokeWidth: 3,
                isAnimationActive: false,
              },
            ],
          },
          cartesianGrid: { strokeDasharray: "3 3" },
          tooltip: {
            cursor: { fill: "#000000" },
            labelStyle: { color: "#ffffff" },
            contentStyle: { backgroundColor: "#000000" },
            isAnimationActive: false,
            labelFormatter: (val) => moment(val).format("YYYY-MM-DD HH:mm:ss"),
          },
        }}
      />
    );
  };

  const renderGraph = () => {
    return (
      <Grid xs item style={{ overflow: "hidden", ...containers.reportView }}>
        <Grid
          container
          direction="column"
          style={{
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            paddingRight: 20,
            paddingBottom: 20,
          }}
        >
          <Grid
            container
            alignItems="stretch"
            style={{ paddingTop: "20px", paddingLeft: "20px", height: "100%" }}
          >
            <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
              {renderLatestCamera()}
            </Grid>
            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
              {renderLatestState()}
            </Grid>
            <Grid item xl={7} lg={7} md={4} sm={12} xs={12}>
              {renderStateGraph()}
            </Grid>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{ paddingTop: "20px" }}
            >
              {renderTempGraph()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={containers.contents}
    >
      {openMessageDlg && (
        <MessageDlg
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          contents={messageDlgContents}
          onClose={onCloseMessageDlg}
        />
      )}
      {openDeviceList && renderDevicePopover()}
      {/*설정 바 */}
      {renderControl()}
      {/*상태 그래프 */}
      {renderGraph()}
      <Backdrop open={backDrop} style={{ zIndex: 1001, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default CameraGraphDlg;
