import colors from "../../colors";
import fonts from "../../fonts";

const global = {
  button: {
    "& > *": {
      margin: "0px",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    outline: "none !important",
    textTransform: "none",
  },
  iconbutton: { "& > *": { margin: "0px" }, outline: "none !important" },
  list: { "& > *": { margin: "0px" }, outline: "none !important" },
};

export const containers = {
  contents: { height: "100%", width: "100%" },
  periodBar: {
    padding: "0 10px",
    minHeight: "58px",
    maxHeight: "200px",
    borderRadius: 8,
    backgroundColor: colors.company2,
    border: `2px solid ${colors.company1}`,
  },
  periodView: {
    padding: "3px 0px 1px",
    minWidth: "300px",
    textAlign: "center",
    // border: `2px solid ${colors.company4}`,
    // borderRadius: "10px",
    // backgroundColor: colors.company,
  },
  graphView: {
    marginTop: "20px",
    borderRadius: "10px",
    backgroundColor: colors.company2,
    border: `2px solid ${colors.company1}`,
  },
  comboboxView: {
    height: "100%",
    width: "100%",
    padding: "0px",
  },
  stateView: {
    width: "60%",
    padding: "10px 20px",
    textAlign: "center",
    backgroundColor: "#acacac",
    borderRadius: 20,
  },
  reportView: {
    borderRadius: 8,
    height: "100%",
    backgroundColor: colors.company2,
    border: `2px solid ${colors.company1}`,
  },
  deviceTitlePaper: {
    padding: "10px 10px 10px 20px",
    backgroundColor: colors.company,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomWidth: 0,
    border: `2px solid ${colors.company1}`,
    backgroundColor: colors.company4,
  },
  deviceContentsPaper: {
    padding: "10px",
    backgroundColor: colors.company,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    border: `2px solid ${colors.company1}`,
    backgroundColor: colors.company4,
    borderTopWidth: 0,
  },
};

export const iconbutton = {
  type1: {
    ...global.iconbutton,
    padding: "6px 8px",
    color: colors.white,
    backgroundColor: colors.company,
    "&:hover": {
      backgroundColor: colors.company1,
    },
  },
};

export const combobox = {
  type1: {
    ...global.button,
    width: "100%",
    padding: "0px 10px 0px 20px",
    textAlign: "center",
    border: `2px solid ${colors.company1}`,
    backgroundColor: colors.company2,
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.company4,
      borderColor: colors.company3,
    },
  },
  item1: {
    ...global.button,
    height: "100%",
    width: "100%",
    minWidth: "200px",
    maxWidth: "220px",
    textAlign: "center",
    justifyContent: "left",
    borderRadius: "0px",
    backgroundColor: colors.company1,
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.company4,
    },
  },
  item2: {
    ...global.button,
    height: "100%",
    width: "100%",
    minWidth: "150px",
    maxWidth: "220px",
    textAlign: "center",
    justifyContent: "left",
    borderRadius: "0px",
    backgroundColor: colors.company1,
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.company4,
    },
  },
};

export const button = {
  type1_focused: {
    ...global.button,
    borderRadius: "10px",
    border: `2px solid ${colors.company4}`,
    padding: "6px 10px",
    width: "100%",
    minWidth: "60px",
    color: colors.white,
    backgroundColor: colors.company3,
    "&:hover": {
      backgroundColor: colors.company,
      borderColor: colors.company4,
    },
  },
  type1_unfocused: {
    ...global.button,
    borderRadius: "10px",
    border: `2px solid ${colors.company4}`,
    padding: "6px 10px",
    width: "100%",
    minWidth: "60px",
    color: colors.white,
    backgroundColor: colors.company,
    "&:hover": {
      backgroundColor: colors.company,
      borderColor: colors.company3,
    },
  },
  type2_unfocused: {
    ...global.button,
    borderRadius: "10px",
    border: `2px solid ${colors.company1}`,
    padding: "10px 40px",
    width: "100%",
    minWidth: "60px",
    color: colors.white,
    backgroundColor: colors.company2,
    "&:hover": {
      backgroundColor: colors.company4,
      borderColor: colors.company3,
    },
  },
  type3_unfocused: {
    ...global.button,
    borderRadius: "10px",
    border: `2px solid ${colors.company1}`,
    padding: "2px 5px 2px 2px",
    width: "100%",
    color: colors.white,
    backgroundColor: colors.company2,
    "&:hover": {
      backgroundColor: colors.company4,
      borderColor: colors.company3,
    },
  },
  type4: {
    ...global.button,
    width: "100%",
    padding: "0px 20px",
    textAlign: "center",
    height: "36px",
    minWidth: "100px",
    border: `2px solid ${colors.company1}`,
    backgroundColor: colors.company2,
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.company4,
      borderColor: colors.company3,
    },
  },

  // table control button
  table: {
    ...global.button,
    padding: "2px 10px",
    color: colors.white,
    "&:hover": {
      backgroundColor: colors.company1,
    },
  },
};

export const datepicker = {
  type1: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "2px",
      borderColor: colors.company4,
      "&.Mui-focused": {
        borderColor: colors.company3,
      },
    },
    "& .MuiInputBase-root ": {
      width: "180px",
      borderRadius: "10px",
      color: colors.white,
      borderColor: colors.company4,
      backgroundColor: colors.company1,
      "&:hover fieldset": {
        borderColor: colors.company3,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.company3,
      },
      ...fonts.b_14_w,
    },
    "& .MuiInputBase-input ": {
      padding: "10px 10px 8px",
      borderRadius: "10px",
      backgroundColor: colors.company1,
    },
    "& .MuiButtonBase-root": {
      color: colors.white,
    },
  },
};

export const list = {
  type1: {
    ...global.list,
    height: "100%",
    padding: "0px",
    width: "100%",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "16px",
      backgroundColor: colors.company4,
    },
    "&::-webkit-scrollbar-thumb": {
      // backgroundColor: "rgba(0,0,0,.1)",
      // outline: "1px solid slategrey",
      border: "4px solid transparent",
      borderRadius: "8px",
      backgroundColor: colors.company1,
      backgroundClip: "padding-box",
    },
    "&::-webkit-scrollbar-track": {
      // boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      // webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      // borderRadius: "10px",
      border: "2px solid transparent",
      backgroundColor: colors.company2,
      backgroundClip: "padding-box",
    },
  },
  item1: {
    padding: "0px",
    height: "32px",
  },
};
