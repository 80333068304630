import React, { useCallback, useEffect, useRef, useState } from "react";

// import GoogleMapReact from "google-map-react";
// import GoogleMapReact from "google-maps-react-markers";

import { IconButton } from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";

import icons from "../../icons";
import colors from "../../colors";

import { svgMarker } from "./marker";

const mapKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
const mapID = process.env.REACT_APP_GOOGLE_MAP_ID;

let fixedMarker = undefined;
function GoogleMap(props) {
  const mapElement = useRef(null);
  const [googleMap, setGoogleMap] = useState(undefined);
  const [googleMaps, setGoogleMaps] = useState(undefined);
  const [mapMarkers, setMapMarkers] = useState([]);

  const [device, setDevice] = useState(undefined);
  const [deviceList, setDeviceList] = useState([]);

  const loadScript = useCallback((url) => {
    const firstScript = window.document.getElementsByTagName("script")[0];
    const newScript = window.document.createElement("script");
    newScript.src = url;
    newScript.async = true;
    newScript.defer = true;
    firstScript?.parentNode?.insertBefore(newScript, firstScript);
  }, []);

  // script에서 google map api를 가져온 후에 실행될 callback 함수
  const initMap = useCallback(() => {
    const { google } = window;
    if (!mapElement.current || !google) return;

    const location = props.location
      ? props.location
      : { lat: 37.5656, lng: 126.9769 };

    const map = new google.maps.Map(mapElement.current, {
      zoom: 14,
      center: location,
      mapId: mapID,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    });

    map.addListener("click", (e) =>
      onClickMap({ map, maps: google.maps, ...e })
    );

    fixedMarker = new google.maps.Marker({
      position: location,
      map,
      icon: {
        ...svgMarker,
        fillColor: colors.company3,
        anchor: new google.maps.Point(0, 20),
      },
    });

    setGoogleMap(map);
    setGoogleMaps(google.maps);
  }, []);

  useEffect(() => {
    const script = window.document.getElementsByTagName("script")[0];
    const includeCheck = script.src.startsWith(
      "https://maps.googleapis.com/maps/api"
    );

    // script 중복 호출 방지
    if (includeCheck) return initMap();

    window.initMap = initMap;
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${mapKey}&callback=initMap&language=ko`
    );
  }, [initMap, loadScript]);

  useEffect(() => {
    if (!props.device) return;
    if (!googleMaps || !googleMap) return;

    if (fixedMarker) {
      fixedMarker.setMap(null);
      fixedMarker = undefined;
    }

    setDevice(props.device);
    for (const marker of mapMarkers) {
      if (marker.device_pkey === props.device.device_pkey)
        marker.setIcon({
          ...svgMarker,
          fillColor: colors.company3,
          anchor: new googleMaps.Point(0, 20),
        });
      else
        marker.setIcon({
          ...svgMarker,
          fillColor: colors.white,
          anchor: new googleMaps.Point(0, 20),
        });
    }
  }, [props.device]);

  useEffect(() => {
    if (!Array.isArray(props.deviceList)) return;
    if (!Array.isArray(deviceList)) return;

    if (fixedMarker) {
      fixedMarker.setMap(null);
      fixedMarker = undefined;
    }

    const _deviceList = deviceList.filter((oldDevice, index) => {
      const idx = props.deviceList.findIndex(
        (newDevice) => oldDevice.device_pkey === newDevice.device_pkey
      );

      return index !== idx;
    });

    if (!googleMaps || !googleMap) return;
    if (deviceList.length !== props.deviceList.length)
      return setDeviceList(props.deviceList);

    if (_deviceList.length > 0) setDeviceList(props.deviceList);
  }, [props.deviceList]);

  useEffect(() => {
    if (!Array.isArray(deviceList)) return;
    if (!googleMaps || !googleMap) return;

    const markers = [];
    const markerPosition = [];
    deviceList.forEach((_device) => {
      const place = new googleMaps.LatLng(_device.lat, _device.lng);
      markerPosition.push(place);
      const marker = new googleMaps.Marker({
        position: { lat: _device.lat, lng: _device.lng },
        map: googleMap,
        icon:
          props.device && props.device.device_pkey === _device.device_pkey
            ? {
                ...svgMarker,
                fillColor: colors.company3,
                anchor: new googleMaps.Point(0, 20),
              }
            : {
                ...svgMarker,
                fillColor: colors.white,
                anchor: new googleMaps.Point(0, 20),
              },
        device_pkey: _device.device_pkey,
      });

      markers.push(marker);
      googleMaps.event.addListener(marker, "click", () =>
        onClickMarker(_device)
      );
    });

    const bounds = new googleMaps.LatLngBounds();
    for (const position of markerPosition) {
      bounds.extend(position);
    }

    googleMap.fitBounds(bounds);
    if (googleMap.zoom > 14) googleMap.setZoom(14);
    setMapMarkers(markers);
  }, [deviceList]);

  const onClickMarker = (device) => {
    if (props.onClickMarker) props.onClickMarker(device);
  };

  const onClickMap = ({ map, maps, latLng }) => {
    if (!map || !maps) return;
    if (!props.onClickMap) return;

    if (fixedMarker) fixedMarker.setMap(null);

    const location = { lat: latLng.lat(), lng: latLng.lng() };
    fixedMarker = new maps.Marker({
      position: location,
      map,
      icon: {
        ...svgMarker,
        fillColor: colors.company3,
        anchor: new maps.Point(0, 20),
      },
    });

    if (props.onClickMap) props.onClickMap(location);
  };

  return <div ref={mapElement} style={{ height: "100%" }} />;
}

export default GoogleMap;
