import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Grid,
  Button,
  IconButton,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";

import { logout, setHeaderVisibility, setHeaderMoving } from "../../../redux";

import {
  Header,
  CameraGraphDlg,
  CameraReportDlg,
  MessageDlg,
} from "../../../components";

import styles from "../styles";
import icons from "../../../icons";
import fonts from "../../../fonts";
import { iconbutton } from "../components";

import { sendBackend } from "../../../utils";

import { Buffer } from "buffer";
import moment from "moment-timezone";
import Language from "../../../language";

let _viewType = 0;
let apiCallTime = undefined;
function Monitoring(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = Language();

  const [dateTimer, setDateTimer] = useState(-1);

  const [viewType, setViewType] = useState(0);
  _viewType = viewType;

  const [deviceList, setDeviceList] = useState([]);

  const [selectedDevice, setSelectedDevice] = useState(undefined);

  const messageDlgTitle = language.warning;
  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgContents, setMessageDlgContents] = useState("");

  const [backDrop, setBackDrop] = useState(false);

  const auth = useSelector((state) => state.auth, shallowEqual);
  const header = useSelector((state) => state.header, shallowEqual);

  useEffect(() => {
    if (!auth.isLoggedIn) return navigate("/login", { replace: true });
    setViewType(0);
    setDateTimer(1000);
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (!header.visibility) dispatch(setHeaderVisibility(true));
  }, [header.visibility]);

  useEffect(() => {
    if (header.state !== 1)
      dispatch(setHeaderMoving({ state: 1, moving: false }));
  }, [header.state]);

  useEffect(() => {
    const start = setInterval(() => {
      if (dateTimer > 0) setDateTimer(dateTimer - 1);
      else if (dateTimer === 0) setDateTimer(1000);

      if (viewType !== 0) return;
      if (dateTimer > 0 && dateTimer % 5 === 0) onSearchMonitoring();
    }, 100);

    return () => clearInterval(start);
  }, [dateTimer]);

  useEffect(() => {
    const useBackListener = () => {
      if (_viewType !== 0) setViewType(0);
    };

    window.addEventListener("popstate", useBackListener);

    return () => {
      window.removeEventListener("popstate", useBackListener);
    };
  }, []);

  const onSearchMonitoring = () => {
    if (!auth || !auth.uuid) return dispatch(logout());

    const runTime = new Date().getTime();
    const apiPath = "api/search/cameraState";
    sendBackend(apiPath, { uuid: auth.uuid }, (success, res) => {
      if (!success || !res || !res.data) {
        if (res.data && res.data.code === 3000) {
          dispatch(logout());
          return navigate("/login", { replace: true });
        }
        return;
      }

      if (apiCallTime !== runTime) return;

      if (Array.isArray(res.data.deviceList)) {
        const _deviceList = res.data.deviceList.map((device) => {
          if (device.created_date) {
            device.datetime = moment.utc(device.created_date).local();
            device.created_date = device.datetime.format("YYYY-MM-DD HH:mm:ss");
          } else device.created_date = "-";

          device.min = device.min ? parseInt(device.min) : 0;
          device.max = device.max ? parseInt(device.max) : 0;
          device.avg = device.avg ? parseInt(device.avg) : 0;

          if (device.ir_data && device.ir_contentType) {
            let base64Flag = `data:${device.vs_contentType}; base64,`;
            let imageStr = Buffer.from(device.ir_data).toString("base64");
            device.ir_data = base64Flag + imageStr;
          }

          if (device.vs_data && device.vs_contentType) {
            let base64Flag = `data:${device.vs_contentType}; base64,`;
            let imageStr = Buffer.from(device.vs_data).toString("base64");
            device.vs_data = base64Flag + imageStr;
          }

          return device;
        });

        setDeviceList(_deviceList);
      }
    });

    apiCallTime = runTime;
  };

  const onOpenCameraReport = (device) => {
    window.history.pushState(null, null, window.location.href);
    setSelectedDevice(device);
    setViewType(1);
  };

  const onCloseCameraReport = () => {
    setSelectedDevice(undefined);
    setViewType(0);
  };

  const onOpenCameraGraph = (device) => {
    window.history.pushState(null, null, window.location.href);
    setSelectedDevice(device);
    setViewType(2);
  };

  const onCloseCameraGraph = () => {
    setSelectedDevice(undefined);
    setViewType(0);
  };

  const onCloseMessageDlg = () => {
    setMessageDlgContents("");
    setOpenMessageDlg(false);
  };

  const renderDeviceItem = (device, idx) => {
    const deviceName = device.device_name ? device.device_name : "-";
    const createdDate = device.created_date ? device.created_date : "-";
    const deviceMin = device.min ? (device.min / 100).toFixed(2) : "0";
    const deviceMax = device.max ? (device.max / 100).toFixed(2) : "0";
    const deviceAvg = device.avg ? (device.avg / 100).toFixed(2) : "0";

    const vsPath = device.vs_data ? device.vs_data : "/img/default_img.JPG";
    const irPath = device.ir_data ? device.ir_data : "/img/default_img.JPG";

    return (
      <Grid
        key={`device-${idx}`}
        // xl={3}
        lg={4}
        md={6}
        sm={12}
        xs={12}
        item
        style={{
          paddingLeft: 20,
          paddingTop: 20,
          maxHeight: "350px",
        }}
      >
        <Grid
          container
          direction="column"
          style={{ height: "100%", width: "100%" }}
        >
          <Grid item style={styles.deviceTitlePaper}>
            <Grid container direction="column">
              <Grid item>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography style={fonts.b_16_w}>
                      {language.deviceName}: [{deviceName}]
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        <IconButton
                          sx={iconbutton.type2}
                          onClick={() => onOpenCameraReport(device)}
                        >
                          <DescriptionRoundedIcon style={icons.white_18} />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton
                          sx={iconbutton.type2}
                          onClick={() => onOpenCameraGraph(device)}
                        >
                          <img src="img/graph.svg" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ paddingTop: "10px" }}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography style={fonts.n_14_w}>{createdDate}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={fonts.n_14_w}>
                      {language.min}: {deviceMin} ℃
                    </Typography>
                  </Grid>
                  <Grid item style={{ paddingLeft: "10px" }}>
                    <Typography style={fonts.n_14_w}>
                      {language.max}: {deviceMax} ℃
                    </Typography>
                  </Grid>
                  <Grid item style={{ paddingLeft: "10px" }}>
                    <Typography style={fonts.n_14_w}>
                      {language.avg}: {deviceAvg} ℃
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs item container style={styles.deviceContentsPaper}>
            <Grid item xs style={{ paddingRight: 5 }}>
              <img src={vsPath} style={icons.camera} />
            </Grid>
            <Grid item xs style={{ paddingLeft: 5 }}>
              <img src={irPath} style={icons.camera} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderRealTime = () => {
    return (
      <Grid xs item style={{ overflow: "hidden", ...styles.deviceListPaper }}>
        <Grid
          container
          direction="row"
          style={{
            height: "100%",
            overflow: "auto",
            paddingRight: 20,
            paddingBottom: 20,
          }}
        >
          {Array.isArray(deviceList) &&
            deviceList.map((device, idx) => {
              return renderDeviceItem(device, idx);
            })}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={styles.screens}
    >
      {openMessageDlg && (
        <MessageDlg
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          contents={messageDlgContents}
          onClose={onCloseMessageDlg}
        />
      )}
      <Grid item>
        <Header isRealTime={viewType === 0} pageName={language.monitoring} />
      </Grid>
      {viewType === 0 && renderRealTime()}
      {viewType === 1 && (
        <Grid xs item>
          <CameraReportDlg
            device={selectedDevice}
            onClose={onCloseCameraReport}
          />
        </Grid>
      )}
      {viewType === 2 && (
        <Grid xs item>
          <CameraGraphDlg
            device={selectedDevice}
            onClose={onCloseCameraGraph}
          />
        </Grid>
      )}
      <Backdrop open={backDrop} style={{ zIndex: 1001, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default Monitoring;
