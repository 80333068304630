import { combineReducers } from "@reduxjs/toolkit";

import authSlice from "./auth.slice";
import headerSlice from "./header.slice";
import dashboardSlice from "./dashboard.slice";

export const { login, logout } = authSlice.actions;
export const { setHeaderMoving, setHeaderVisibility, setFullScreen } =
  headerSlice.actions;
export const { updateLatestData } = dashboardSlice.actions;

export default combineReducers({
  auth: authSlice.reducer,
  header: headerSlice.reducer,
  dashboard: dashboardSlice.reducer,
});
