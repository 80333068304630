export const i18n_ko = {
  title: "IOT 통합 관제",
  realTime: "실시간 업데이트",
  home: "홈",
  monitoring: "모니터링",
  notification: "경보",
  report: "보고서",
  deviceManagement: "장비 관리",
  personManagement: "사용자 관리",
  deviceList: "장비 목록",
  id: "아이디",
  password: "비밀번호",
  login: "로그인",
  logout: "로그아웃",
  loginFailed: "아이디와 비밀번호를 확인해주세요.",
  deviceName: "장비 이름",
  info: "정보",
  sensorState: "센서 상태",
  sensorAbnormal: "이상",
  sensorNormal: "정상",
  arc: "아크",
  battery: "배터리",
  co: "일산화탄소",
  flood: "수위(침수)",
  float: "수위(상승)",
  humidity: "습도",
  methane: "메탄",
  rssi: "통신",
  tamper: "진동 감지",
  temperature: "온도",
  weather: "날씨",
  precipitation: "강수량 (1 시간)",
  deviceNumber: "장비 번호",
  serialNumber: "일련 번호",
  purchaseDate: "구입 일자",
  repairDate: "수리 일자",
  batteryChangeDate: "배터리 변경 일자",
  repairCount: "수리 횟수",
  batteryChangeCount: "배터리 변경 횟수",
  dayAgo: "일",
  weekAgo: "주",
  monthAgo: "월",
  period: "기간",
  toDay: "오늘",
  prev: "이전",
  next: "다음",
  prevDay: "이전 날",
  nextDay: "다음 날",
  sensorList: "센서 목록",
  all: "전체",
  dataRefresh: "데이터 갱신",
  export: "내보내기",
  print: "인쇄",
  createdDate: "생성 일자",
  contents: "내용",
  search: "검색",
  graph: "그래프",
  api: "API",
  refresh: "새로고침",
  insert: "추가",
  update: "수정",
  delete: "삭제",
  address: "주소",
  apply: "적용",
  close: "닫기",
  warning: "경고",
  min: "최소",
  max: "최대",
  avg: "평균",
  state: "상태",
  normal: "정상",
  warning: "경고",
  error: "오류",
  name: "이름",
  company: "회사",
  department: "부서",
  description: "설명",
  authType: "권한",
  updatedDate: "수정 일자",
  connectedDate: "접속 일자",
  save: "저장",
  location: "위치",
  auth: {
    user: "사용자",
    operator: "운영자",
    administrator: "관리자",
  },
  msg: {
    saveMsg: "저장 하시겠습니까?",
    deleteMsg: "'X' (을)를 삭제 하시겠습니까?",
  },
  errorMsg: {
    inspection: "점검 중입니다.",
    deviceSelect: "하나의 장비를 선택해주세요.",
    deviceSelect1: "장비를 선택해주세요.",
    personSelect: "사용자를 한 명만 선택해주세요.",
    personSelect1: "사용자를 선택해주세요.",
    period: "기간을 확인해주세요.",
    inputID: "사용자의 아이디를 입력해주세요. (최소 4자)",
    inputPassword: "사용자의 비밀번호를 입력해주세요. (최소 4자)",
    inputName: "사용자의 이름을 입력해주세요.",
    inputCompany: "사용자의 회사를 입력해주세요.",
    inputAuth: "사용자의 권한을 입력해주세요.",
    idExists: "사용자의 아이디가 이미 존재합니다.",
    deviceName: "장비의 이름을 입력해주세요.",
    serialNumber: "장비의 일련 번호를 입력해주세요.",
    deviceAPI: "장비의 API를 입력해주세요.",
  },
  Default: {
    title: "IOT 통합 관제",
  },
  Login: {
    id: "아이디",
    password: "비밀번호",
    login: "로그인",
    close: "닫기",
    checkedMsg: "아이디와 비밀번호를 확인해주세요.",
  },
  Header: {
    logout: "로그아웃",
    welcome: "환영합니다",
  },
  Sensor: {
    arc: "아크",
    battery: "배터리",
    co: "일산화탄소",
    flood: "수위",
    humidity: "습도",
    methane: "메탄",
    rssi: "통신",
    tamper: "진동 감지",
    temperature: "온도",
  },
  Graph: {
    device_list: "장치 목록",
    device_number: "장치 번호",
    sensor_list: "센서 목록",
    device_name: "장치 이름",
    serial_name: "일렬 번호",
    purchase_date: "구입 날짜",
    repair_date: "수리 날짜",
    battery_date: "배터리 날짜",
    repair_count: "수리 횟수",
    battery_change_count: "배터리 교체 횟수",
    period: "기간",
    day_age: "날짜",
    week_age: "주",
    month_age: "달",
    monthly: "월간",
    weekly: "주간",
    daily: "일간",
    time: "시간",
    toDay: "오늘",
    prev: "이전",
    next: "다음",
    search: "검색",
    close: "닫기",
    selected_all: "전부 선택",
    clear_all: "전부 초기화",
    normal: "정상",
    error: "오류",
  },
  Home: {
    title: "홈",
    home: {
      real_time: "실시간 업데이트",
      device_list: "장치 목록",
      device_number: "장치 번호",
      sensor_status: "센서 상태",
      weather_title: "날씨",
      location_title: "계양동, 인천",
      info: "정보",
      t1h: "온도",
      rn1: "1시간 당 강수량",
      reh: "습도",
      event_on: "이벤트 켜짐",
      event_clear: "이벤트 초기화",
      sensor: "센서",
      close: "닫기",
      no_data: "데이터 없음",
      normal: "정상",
      error: "오류",
    },
  },
  Monitoring: {
    title: "모니터링",
    home: {
      real_time: "실시간 업데이트",
      device_number: "장치 번호",
      create_date: "생성일",
      min: "최소",
      max: "최대",
      avg: "평균",
      history: "히스토리",
      next: "다음",
      prev: "이전",
      normal: "정상",
      warning: "경고",
      error: "오류",
    },
    report: {
      device_list: "장치 목록",
      device_number: "장치 이름",
      create_date: "생성일",
      min: "최소",
      max: "최대",
      avg: "평균",
      normal: "정상",
      warning: "경고",
      error: "오류",
      search: "검색",
      next: "다음",
      prev: "이전",
      close: "닫기",
      searchMsg: "장치를 선택하세요.",
    },
    graph: {
      device_list: "장치 목록",
      device_number: "장치 번호",
      create_date: "생성일",
      min: "최소",
      max: "최대",
      avg: "평균",
      normal: "정상",
      warning: "경고",
      error: "오류",
      search: "검색",
      status: "상태",
      searchMsg: "장치를 선택하세요.",
    },
  },
  Event: {
    title: "이벤트",
    export: "내보내기",
    print: "인쇄",
    search: "검색",
    prev: "이전",
    next: "다음",
    event_list: "이벤트 목록",
    event_on: "이벤트 켜짐",
    event_clear: "이벤트 초기화",
    create_date: "생성일",
    device_number: "장치 번호",
    sensor: "센서",
    value: "값",
    min_value: "최소값",
    max_value: "최대값",
    status: "상태",
    close: "닫기",
    device_list: "장치 목록",
    sensor_list: "센서 목록",
    selected_all: "전부 선택",
    clear_all: "전부 초기화",
    inspectionMsg: "검사중",
  },
  Management: {
    title: "관리",
    refresh: "새로고침",
    add: "추가",
    modified: "수정",
    remove: "삭제",
    export: "내보내기",
    print: "인쇄",
    search: "검색",
    device_number: "장치 번호",
    device_name: "장치 이름",
    serial_number: "일렬번호",
    api: "API",
    purchase_date: "구입일",
    repair_date: "수리 날짜",
    battery_date: "배터리 날짜",
    repair_count: "수리 횟수",
    battery_change_count: "배터리 교체 횟수",
    info: "정보",
    no_selected_msg: "장치를 선택해주세요.",
    mul_selected_msg: "하나의 장치를 선택해주세요.",
    retry_msg: "다시 시도하세요.",
    remove_check_msg: "장치를 삭제하시겠습니까?",
    inspectionMsg: "검사중",
    close: "닫기",
    dialog: {
      info_placeholder: "최대 500자",
      latitude: "위도",
      longitude: "경도",
      save: "저장",
      cancel: "취소",
      save_check_msg: "저장하시겠습니까?",
      yes: "예",
      no: "아니오",
      close: "닫기",
    },
  },
  Report: {
    title: "보고서",
    graph: "그래프",
    export: "내보내기",
    print: "인쇄",
    search: "검색",
    prev: "이전",
    next: "다음",
    device_list: "장치 목록",
    sensor_list: "센서 목록",
    selected_all: "전부 선택",
    clear_all: "전부 초기화",
    close: "닫기",
    event_on: "이벤트 켜짐",
    event_clear: "이벤트 초기화",
    inspectionMsg: "검사중",
  },
  DeviceManagement: {
    title: "장치 관리",
    device_number: "장치 번호",
    device_name: "장치 이름",
    device_serial_number: "일렬 번호",
    device_api: "API",
    device_purchase_date: "구입 날짜",
    device_repair_date: "수리 날짜",
    device_battery_date: "배터리 날짜",
    device_repair_count: "수리 횟수",
    device_battery_change_count: "배터리 교체 횟수",
    device_info: "정보",
    device_latitude: "위도",
    device_longitude: "경도",
    created_date: "생성 날짜",
    updated_date: "업데이트 날짜",
    connected_date: "연결 날짜",
    refresh: "새로고침",
    insert: "삽입",
    update: "업데이트",
    delete: "삭제",
    export: "내보내기",
    print: "인쇄",
    search: "검색",
    ok: "Ok",
    confirm: "확인",
    apply: "적용",
    close: "닫기",
    yes: "예",
    no: "아니오",
    select_one_msg: "한 명의 사용자를 선택하세요.",
    no_select_msg: "사용자를 선택하세요.",
    delete_user_msg: "정말 삭제하시겠습니까?",
    inspection_msg: "검사중",
    apply_msg: "신청하시겠습니까?",
    device_number_msg: "장치 번호를 입력하세요.",
    device_name_msg: "장치 이름을 입력하세요.",
    device_serial_number_msg: "장치 일렬 번호를 입력하세요.",
  },
  UserManagement: {
    title: "사용자 관리",
    id: "아이디",
    password: "비밀번호",
    name: "이름",
    company: "회사",
    department: "부서",
    description: "설명",
    auth_type: "인증 방식",
    created_date: "생성일",
    updated_date: "업데이트 날짜",
    connected_date: "연결 날짜",
    refresh: "새로고침",
    insert: "삽입",
    update: "업데이트",
    delete: "삭제",
    export: "내보내기",
    print: "인쇄",
    search: "검색",
    auth0: "손님",
    auth1: "운영자",
    auth2: "관리자",
    ok: "Ok",
    confirm: "확인",
    apply: "적용",
    close: "닫기",
    yes: "예",
    no: "아니오",
    select_one_msg: "한 명의 사용자를 선택하세요.",
    no_select_msg: "사용자를 선택하세요.",
    delete_user_msg: "정말 삭제하시겠습니까?",
    inspection_msg: "검사중",
    apply_msg: "신청하시겠습니까?",
    id_msg: "아이디를 입력하세요. (최소 4자 이상)",
    password_msg: "비밀번호를 입력하세요. (최소 4자 이상)",
    name_msg: "이름을 입력하세요.",
  },
};
