import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import Draggable from "react-draggable";
// import Geocode from "react-geocode";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  TextField,
  Button,
  Paper,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import fonts from "../../fonts";
import icons from "../../icons";
import colors from "../../colors";

import { GoogleMap, MessageDlg, YesNoDlg } from "..";
import { dialog, button, textfield, paper, datepicker } from "./styles";

import uuid from "react-uuid";
import moment from "moment-timezone";
import Language from "../../language";

import { logout } from "../../redux";
import { sendBackend, setDateFormat } from "../../utils";

function PaperComponent(props) {
  const ref = useRef(undefined);
  return (
    <Draggable
      handle="#device-management-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={ref}
    >
      <div
        ref={ref}
        {...props}
        style={{ borderRadius: 8, backgroundColor: "#ffffff" }}
      />
    </Draggable>
  );
}

// Geocode.setApiKey(process.env.REACT_APP_GEOCODING_KEY);
// Geocode.setLanguage("ko");

let apiCallTime = undefined;
function DeviceDlg(props) {
  const dispatch = useDispatch();
  const language = Language();

  const defaultCenter = { lat: 37.55639890332151, lng: 126.73082332633109 };

  const [devicePkey, setDevicePkey] = useState(undefined);

  const [searchAddress, setSearchAddress] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [deviceAPI, setDeviceAPI] = useState("");
  const [purchaseDate, setPurchaseDate] = useState(
    setDateFormat(moment().local())
  );
  const [repairDate, setRepairDate] = useState(setDateFormat(moment().local()));
  const [batteryChangeDate, setBatteryChangeDate] = useState(
    setDateFormat(moment().local())
  );
  const [repairCount, setRepairCount] = useState(0);
  const [batteryChangeCount, setBatteryChangeCount] = useState(0);

  const [deviceLat, setDeviceLat] = useState(defaultCenter.lat);
  const [deviceLng, setDeviceLng] = useState(defaultCenter.lng);
  const [deviceLocation, setDeviceLocation] = useState("");
  const [deviceInfo, setDeviceInfo] = useState("");

  const [isUpdate, setIsUpdate] = useState(false);
  const [showInput, setShowInput] = useState(true);

  const messageDlgTitle = language.warning;
  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgContents, setMessageDlgContents] = useState("");

  const yesNoTitle = language.save;
  const yesNoContents = language.msg.saveMsg;
  const [openYesNoDlg, setOpenYesNoDlg] = useState(false);

  const [backDrop, setBackDrop] = useState(false);

  const auth = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    if (!auth.isLoggedIn) return handleClose();
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (Array.isArray(props.device) && props.device.length > 0) {
      const _device = props.device[0];
      setIsUpdate(true);
      setDevicePkey(_device.device_pkey);
      setDeviceName(_device.device_name);
      setSerialNumber(_device.serial_num);
      setDeviceAPI(_device.sitename);
      setPurchaseDate(moment(_device.purchase_date).local());
      setRepairDate(moment(_device.repair_date).local());
      setBatteryChangeDate(moment(_device.battery_change_date).local());
      setRepairCount(_device.repair_count);
      setBatteryChangeCount(_device.battery_change_count);

      setDeviceLat(_device.lat);
      setDeviceLng(_device.lng);
      setDeviceLocation(_device.location);
      setDeviceInfo(_device.info);
    } else {
      const pkey = uuid();
      setDevicePkey(pkey);

      setDeviceLat(37.55639890332151);
      setDeviceLng(126.73082332633109);
    }
  }, []);

  const onChangeDeviceName = (e) => {
    setDeviceName(e.target.value);
  };

  const onChangeSerialNumber = (e) => {
    setSerialNumber(e.target.value);
  };

  const onChangeDeviceAPI = (e) => {
    setDeviceAPI(e.target.value);
  };

  const onChangeRepairCount = (e) => {
    setRepairCount(parseInt(e.target.value));
  };

  const onChangeBatteryChangeCount = (e) => {
    setBatteryChangeCount(parseInt(e.target.value));
  };

  const onSearchAddress = (txt) => {
    setSearchAddress(txt);
    if (!txt || txt.length < 1) return;
  };

  const onClickMap = (params) => {
    const lat = params.lat ? params.lat : 37.55639890332151;
    const lng = params.lng ? params.lng : 126.73082332633109;

    setDeviceLat(lat);
    setDeviceLng(lng);
  };

  const handleApply = () => {
    if (deviceName.length < 1) {
      setMessageDlgContents(language.errorMsg.deviceName);
      setOpenMessageDlg(true);
      return;
    }
    if (serialNumber.length < 1) {
      setMessageDlgContents(language.errorMsg.serialNumber);
      setOpenMessageDlg(true);
      return;
    }
    if (deviceAPI.length < 1) {
      setMessageDlgContents(language.errorMsg.deviceAPI);
      setOpenMessageDlg(true);
      return;
    }

    setOpenYesNoDlg(true);
  };

  const handleClose = () => {
    props.onClose();
  };

  const onCloseMessageDlg = () => {
    setMessageDlgContents("");
    setOpenMessageDlg(false);
  };

  const onApplyYesNoDlg = () => {
    setOpenYesNoDlg(false);
    if (!auth || !auth.uuid) return dispatch(logout());

    setBackDrop(true);
    const runTime = new Date().getTime();
    const apiPath = isUpdate ? "api/update/device" : "api/insert/device";

    const device = {
      device_pkey: devicePkey,
      device_name: deviceName,
      serial_num: serialNumber,
      api: deviceAPI,
      purchase_date: moment(purchaseDate).utc(),
      repair_date: moment(repairDate).utc(),
      battery_change_date: moment(batteryChangeDate).utc(),
      repair_count: repairCount,
      battery_change_count: batteryChangeCount,
      info: deviceInfo,
      location: deviceLocation,
      lat: deviceLat,
      lng: deviceLng,
    };

    sendBackend(apiPath, { uuid: auth.uuid, device }, (success, res) => {
      setBackDrop(false);
      if (!success || !res || !res.data) {
        if (res.data && res.data.code === 3000) {
          dispatch(logout());
          if (props.onClose) props.onClose();
          return;
        } else if (res.data && res.data.code === 5000) {
          setMessageDlgContents(language.errorMsg.idExists);
          setOpenMessageDlg(true);
        }

        return;
      }

      if (apiCallTime !== runTime) return;

      if (props.onApply) props.onApply();
    });

    apiCallTime = runTime;
  };

  const onCloseYesNoDlg = () => {
    setOpenYesNoDlg(false);
  };

  const renderAddress = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: "20px",
          right: "70px",
          width: "300px",
          opacity: 0.8,
        }}
      >
        <Paper sx={paper.type1}>
          <Grid
            container
            alignItems="stretch"
            justifyContent="flex-end"
            style={{ height: "100%" }}
          >
            <Grid item xs>
              <TextField
                value={searchAddress}
                placeholder={language.address}
                sx={textfield.type1}
                onChange={(e) => setSearchAddress(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      sx={{ visibility: searchAddress ? "none" : "hidden" }}
                      onClick={() => onSearchAddress("")}
                    >
                      <CloseRoundedIcon style={icons.gray_20} />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <IconButton onClick={() => onSearchAddress(searchAddress)}>
                <SearchRoundedIcon style={fonts.b_20_w} />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  };
  const renderInputTool = () => {
    return (
      <div
        style={{
          position: "absolute",
          top: "15px",
          right: "15px",
          opacity: 0.8,
          borderRadius: 5,
          backgroundColor: colors.white,
        }}
      >
        <IconButton onClick={() => setShowInput(!showInput)}>
          {showInput ? (
            <VisibilityOffIcon style={fonts.b_20_b} />
          ) : (
            <VisibilityIcon style={fonts.b_20_b} />
          )}
        </IconButton>
      </div>
    );
  };

  const renderInput = () => {
    return (
      <Grid
        container
        direction="column"
        alignItems="stretch"
        style={{
          position: "absolute",
          top: "65px",
          right: "15px",
          width: "380px",
          padding: "5px 0px",
          opacity: 0.8,
          borderRadius: 5,
          backgroundColor: colors.white,
        }}
      >
        <Grid container alignItems="center" style={{ padding: "5px 20px" }}>
          <Grid item style={{ width: "130px", textAlign: "center" }}>
            <Typography sx={fonts.b_16_b}>{language.deviceName}</Typography>
          </Grid>
          <Grid item xs style={{ paddingLeft: "10px" }}>
            <TextField
              value={deviceName}
              placeholder={language.deviceName}
              sx={textfield.type2}
              inputProps={{ maxLength: 20 }}
              onChange={onChangeDeviceName}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" style={{ padding: "5px 20px" }}>
          <Grid item style={{ width: "130px", textAlign: "center" }}>
            <Typography sx={fonts.b_16_b}>{language.serialNumber}</Typography>
          </Grid>
          <Grid item xs style={{ paddingLeft: "10px" }}>
            <TextField
              value={serialNumber}
              placeholder={language.serialNumber}
              sx={textfield.type2}
              inputProps={{ maxLength: 20 }}
              onChange={onChangeSerialNumber}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" style={{ padding: "5px 20px" }}>
          <Grid item style={{ width: "130px", textAlign: "center" }}>
            <Typography sx={fonts.b_16_b}>{language.api}</Typography>
          </Grid>
          <Grid item xs style={{ paddingLeft: "10px" }}>
            <TextField
              value={deviceAPI}
              placeholder={language.api}
              sx={textfield.type2}
              inputProps={{ maxLength: 20 }}
              onChange={onChangeDeviceAPI}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" style={{ padding: "5px 20px" }}>
          <Grid item style={{ width: "130px", textAlign: "center" }}>
            <Typography sx={fonts.b_16_b}>{language.purchaseDate}</Typography>
          </Grid>
          <Grid item xs style={{ paddingLeft: "10px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                sx={datepicker.type1}
                value={purchaseDate && purchaseDate.toDate()}
                format="yyyy-MM-dd"
                onChange={(val) => setPurchaseDate(moment(val))}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container alignItems="center" style={{ padding: "5px 20px" }}>
          <Grid item style={{ width: "130px", textAlign: "center" }}>
            <Typography sx={fonts.b_16_b}>{language.repairDate}</Typography>
          </Grid>
          <Grid item xs style={{ paddingLeft: "10px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                sx={datepicker.type1}
                value={repairDate && repairDate.toDate()}
                format="yyyy-MM-dd"
                onChange={(val) => setRepairDate(moment(val))}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container alignItems="center" style={{ padding: "5px 20px" }}>
          <Grid item style={{ width: "130px", textAlign: "center" }}>
            <Typography sx={fonts.b_16_b}>
              {language.batteryChangeDate}
            </Typography>
          </Grid>
          <Grid item xs style={{ paddingLeft: "10px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                sx={datepicker.type1}
                value={batteryChangeDate && batteryChangeDate.toDate()}
                format="yyyy-MM-dd"
                onChange={(val) => setBatteryChangeDate(moment(val))}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container alignItems="center" style={{ padding: "5px 20px" }}>
          <Grid item style={{ width: "130px", textAlign: "center" }}>
            <Typography sx={fonts.b_16_b}>{language.repairCount}</Typography>
          </Grid>
          <Grid item xs style={{ paddingLeft: "10px" }}>
            <TextField
              value={repairCount}
              placeholder={language.repairCount}
              sx={textfield.type2}
              type="number"
              onChange={onChangeRepairCount}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" style={{ padding: "5px 20px" }}>
          <Grid item style={{ width: "130px", textAlign: "center" }}>
            <Typography sx={fonts.b_16_b}>
              {language.batteryChangeCount}
            </Typography>
          </Grid>
          <Grid item xs style={{ paddingLeft: "10px" }}>
            <TextField
              value={batteryChangeCount}
              placeholder={language.batteryChangeCount}
              sx={textfield.type2}
              type="number"
              onChange={onChangeBatteryChangeCount}
            />
          </Grid>
        </Grid>
        {/* <Grid container alignItems="center" style={{ padding: "5px 20px" }}>
          <Grid item style={{ width: "130px", textAlign: "center" }}>
            <Typography sx={fonts.b_16_b}>{language.info}</Typography>
          </Grid>
          <Grid item xs style={{ paddingLeft: "10px" }}>
            <TextField
              value={deviceInfo}
              placeholder={language.info}
              sx={textfield.type2}
              multiline
              rows={4}
              inputProps={{ maxLength: 500 }}
              onChange={(e) => setDeviceInfo(e.target.value)}
            />
          </Grid>
        </Grid> */}
        <Grid container alignItems="center" style={{ padding: "5px 20px" }}>
          <Grid item style={{ width: "130px", textAlign: "center" }}>
            <Typography sx={fonts.b_16_b}>{language.location}</Typography>
          </Grid>
          <Grid item xs style={{ paddingLeft: "10px" }}>
            <TextField
              value={deviceLocation}
              placeholder={language.location}
              sx={textfield.type2}
              multiline
              rows={4}
              inputProps={{ maxLength: 500 }}
              onChange={(e) => setDeviceLocation(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          style={{ padding: "10px 20px 5px" }}
        >
          <Grid item>
            <Button sx={button.type1} onClick={handleApply}>
              <Typography sx={fonts.b_16_w}>{language.save}</Typography>
            </Button>
          </Grid>
          <Grid item style={{ paddingLeft: 10 }}>
            <Button sx={button.type1} onClick={handleClose}>
              <Typography sx={fonts.b_16_w}>{language.close}</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {openMessageDlg && (
        <MessageDlg
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          contents={messageDlgContents}
          onClose={onCloseMessageDlg}
        />
      )}
      {openYesNoDlg && (
        <YesNoDlg
          isOpen={openYesNoDlg}
          title={yesNoTitle}
          contents={yesNoContents}
          apply={language.save}
          close={language.close}
          onApply={onApplyYesNoDlg}
          onClose={onCloseYesNoDlg}
        />
      )}
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={props.isOpen}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="device-management-dialog-title"
      >
        <DialogTitle sx={dialog.toolbar} id="device-management-dialog-title">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            style={{ height: "36px" }}
          >
            <Grid item xs>
              <Typography sx={fonts.b_16_w}>{props.title}</Typography>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                aria-label="close"
                onClick={handleClose}
                style={{ color: "#ffffff" }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent
          style={{ position: "relative", padding: "0px", minHeight: 800 }}
        >
          <div style={{ position: "absolute", height: "100%", width: "100%" }}>
            <GoogleMap
              location={
                Array.isArray(props.device) && props.device.length > 0
                  ? { lat: props.device[0].lat, lng: props.device[0].lng }
                  : undefined
              }
              onClickMap={onClickMap}
            />
          </div>
          {/* {renderAddress()} */}
          {renderInputTool()}
          {showInput && renderInput()}
        </DialogContent>
      </Dialog>
      <Backdrop open={backDrop} style={{ zIndex: 4001, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default DeviceDlg;
