import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import Draggable from "react-draggable";
// import Geocode from "react-geocode";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  TextField,
  Button,
  Radio,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import fonts from "../../fonts";
import icons from "../../icons";
import colors from "../../colors";

import { MessageDlg, YesNoDlg } from "..";
import { dialog, button, textfield, radio } from "./styles";

import moment from "moment-timezone";
import Language from "../../language";

import { logout } from "../../redux";
import { sendBackend, setDateFormat } from "../../utils";

function PaperComponent(props) {
  const ref = useRef(undefined);
  return (
    <Draggable
      handle="#person-management-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={ref}
    >
      <div
        ref={ref}
        {...props}
        style={{ borderRadius: 8, backgroundColor: "#ffffff" }}
      />
    </Draggable>
  );
}

let apiCallTime = undefined;
function PersonDlg(props) {
  const dispatch = useDispatch();
  const language = Language();

  const [inputID, setInputID] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [inputName, setInputName] = useState("");
  const [inputCompany, setInputCompany] = useState("");
  const [inputDepartment, setInputDepartment] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [inputAuthType, setInputAuthType] = useState(0);

  const [isUpdate, setIsUpdate] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);

  const messageDlgTitle = language.warning;
  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgContents, setMessageDlgContents] = useState("");

  const yesNoTitle = language.save;
  const yesNoContents = language.msg.saveMsg;
  const [openYesNoDlg, setOpenYesNoDlg] = useState(false);

  const [backDrop, setBackDrop] = useState(false);

  const auth = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    if (!auth.isLoggedIn) return handleClose();
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (Array.isArray(props.selected)) {
      if (props.selected.length > 0) {
        const person = props.selected[0];
        setIsUpdate(true);
        setInputID(person.user_id);
        setInputName(person.name);
        setInputCompany(person.company);
        setInputDepartment(person.department);
        setInputDescription(person.description);
        setInputAuthType(person.auth_type);
      }
    }
  }, []);

  const onChangeID = (e) => {
    setInputID(e.target.value);
  };

  const onChangePassword = (e) => {
    setInputPassword(e.target.value);
  };

  const onChangeName = (e) => {
    setInputName(e.target.value);
  };

  const onChangeCompany = (e) => {
    setInputCompany(e.target.value);
  };

  const onChangeDepartment = (e) => {
    setInputDepartment(e.target.value);
  };

  const onChangeDescription = (e) => {
    setInputDescription(e.target.value);
  };

  const onChangeAuthType = (e) => {
    setInputAuthType(parseInt(e.target.value));
  };

  const handleApply = () => {
    if (inputID.length < 4) {
      setMessageDlgContents(language.errorMsg.inputID);
      setOpenMessageDlg(true);
      return;
    }
    if (!isUpdate && inputPassword.length < 4) {
      setMessageDlgContents(language.errorMsg.inputPassword);
      setOpenMessageDlg(true);
      return;
    }
    if (inputName.length < 1) {
      setMessageDlgContents(language.errorMsg.inputName);
      setOpenMessageDlg(true);
      return;
    }
    if (inputCompany.length < 1) {
      setMessageDlgContents(language.errorMsg.inputCompany);
      setOpenMessageDlg(true);
      return;
    }
    if (inputAuthType < 0) {
      setMessageDlgContents(language.errorMsg.inputAuthType);
      setOpenMessageDlg(true);
      return;
    }

    setOpenYesNoDlg(true);
  };

  const onApplyYesNoDlg = () => {
    setOpenYesNoDlg(false);
    if (!auth || !auth.uuid) return dispatch(logout());

    setBackDrop(true);
    const runTime = new Date().getTime();
    const apiPath = isUpdate ? "api/update/person" : "api/insert/person";

    const person = {
      id: inputID,
      password: inputPassword,
      name: inputName,
      company: inputCompany,
      department: inputDepartment,
      description: inputDescription,
      auth_type: inputAuthType,
    };

    sendBackend(apiPath, { uuid: auth.uuid, person }, (success, res) => {
      setBackDrop(false);
      if (!success || !res || !res.data) {
        if (res.data && res.data.code === 3000) {
          dispatch(logout());
          if (props.onClose) props.onClose();
          return;
        } else if (res.data && res.data.code === 5000) {
          setMessageDlgContents(language.errorMsg.idExists);
          setOpenMessageDlg(true);
        }

        return;
      }

      if (apiCallTime !== runTime) return;

      if (props.onApply) props.onApply();
    });

    apiCallTime = runTime;
  };

  const handleClose = () => {
    props.onClose();
  };

  const onCloseMessageDlg = () => {
    setMessageDlgContents("");
    setOpenMessageDlg(false);
  };

  const onCloseYesNoDlg = () => {
    setOpenYesNoDlg(false);
  };

  return (
    <>
      {openMessageDlg && (
        <MessageDlg
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          contents={messageDlgContents}
          onClose={onCloseMessageDlg}
        />
      )}
      {openYesNoDlg && (
        <YesNoDlg
          isOpen={openYesNoDlg}
          title={yesNoTitle}
          contents={yesNoContents}
          apply={language.save}
          close={language.close}
          onApply={onApplyYesNoDlg}
          onClose={onCloseYesNoDlg}
        />
      )}
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={props.isOpen}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="person-management-dialog-title"
      >
        <DialogTitle sx={dialog.toolbar} id="person-management-dialog-title">
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            style={{ height: "36px" }}
          >
            <Grid item xs>
              <Typography sx={fonts.b_16_w}>{props.title}</Typography>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                aria-label="close"
                onClick={handleClose}
                style={{ color: "#ffffff" }}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: "0px" }}>
          <Grid container direction="column">
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              style={{ padding: "20px 20px 0px" }}
            >
              <Grid item style={{ minWidth: "150px", textAlign: "center" }}>
                <Typography style={fonts.b_18_b}>{language.id}</Typography>
              </Grid>
              <Grid item xs>
                <TextField
                  value={inputID}
                  disabled={isUpdate}
                  placeholder={language.id}
                  sx={textfield.type2}
                  InputProps={{ maxLength: 50 }}
                  onChange={onChangeID}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              style={{ padding: "20px 20px 0px" }}
            >
              <Grid item style={{ minWidth: "150px", textAlign: "center" }}>
                <Typography style={fonts.b_18_b}>
                  {language.password}
                </Typography>
              </Grid>
              <Grid item xs>
                <TextField
                  value={inputPassword}
                  placeholder={language.password}
                  sx={textfield.type2}
                  type={passwordShow ? "text" : "password"}
                  InputProps={{
                    maxLength: 50,
                    endAdornment: (
                      <IconButton
                        onClick={() => setPasswordShow(!passwordShow)}
                      >
                        {passwordShow ? (
                          <VisibilityOffIcon style={icons.gray_20} />
                        ) : (
                          <VisibilityIcon style={icons.gray_20} />
                        )}
                      </IconButton>
                    ),
                  }}
                  onChange={onChangePassword}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              style={{ padding: "20px 20px 0px" }}
            >
              <Grid item style={{ minWidth: "150px", textAlign: "center" }}>
                <Typography style={fonts.b_18_b}>{language.name}</Typography>
              </Grid>
              <Grid item xs>
                <TextField
                  value={inputName}
                  placeholder={language.name}
                  sx={textfield.type2}
                  InputProps={{ maxLength: 50 }}
                  onChange={onChangeName}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              style={{ padding: "20px 20px 0px" }}
            >
              <Grid item style={{ minWidth: "150px", textAlign: "center" }}>
                <Typography style={fonts.b_18_b}>{language.company}</Typography>
              </Grid>
              <Grid item xs>
                <TextField
                  value={inputCompany}
                  placeholder={language.company}
                  sx={textfield.type2}
                  InputProps={{ maxLength: 50 }}
                  onChange={onChangeCompany}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              style={{ padding: "20px 20px 0px" }}
            >
              <Grid item style={{ minWidth: "150px", textAlign: "center" }}>
                <Typography style={fonts.b_18_b}>
                  {language.department}
                </Typography>
              </Grid>
              <Grid item xs>
                <TextField
                  value={inputDepartment}
                  placeholder={language.department}
                  sx={textfield.type2}
                  InputProps={{ maxLength: 50 }}
                  onChange={onChangeDepartment}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              style={{ padding: "20px 20px 0px" }}
            >
              <Grid item style={{ minWidth: "150px", textAlign: "center" }}>
                <Typography style={fonts.b_18_b}>
                  {language.description}
                </Typography>
              </Grid>
              <Grid item xs>
                <TextField
                  value={inputDescription}
                  placeholder={language.description}
                  sx={textfield.type2}
                  minRows={4}
                  maxRows={4}
                  multiline={true}
                  InputProps={{ maxLength: 50 }}
                  onChange={onChangeDescription}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              style={{ padding: "20px 20px 0px" }}
            >
              <Grid item style={{ minWidth: "150px", textAlign: "center" }}>
                <Typography style={fonts.b_18_b}>
                  {language.authType}
                </Typography>
              </Grid>
              <Grid
                xs
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Radio
                    sx={radio.type1}
                    checked={inputAuthType === 0}
                    onChange={onChangeAuthType}
                    value={0}
                    name="radio-buttons"
                    inputProps={{ "aria-label": language.authType }}
                  />
                </Grid>
                <Grid item style={{ padding: "0px 5px" }}>
                  <Typography style={fonts.b_16_b}>
                    {language.auth[0]}
                  </Typography>
                </Grid>
                <Grid item>
                  <Radio
                    sx={radio.type1}
                    checked={inputAuthType === 1}
                    onChange={onChangeAuthType}
                    value={1}
                    name="radio-buttons"
                    inputProps={{ "aria-label": language.authType }}
                  />
                </Grid>
                <Grid item style={{ padding: "0px 5px" }}>
                  <Typography style={fonts.b_16_b}>
                    {language.auth[1]}
                  </Typography>
                </Grid>
                <Grid item>
                  <Radio
                    sx={radio.type1}
                    checked={inputAuthType === 2}
                    onChange={onChangeAuthType}
                    value={2}
                    name="radio-buttons"
                    inputProps={{ "aria-label": language.authType }}
                  />
                </Grid>
                <Grid item style={{ padding: "0px 5px" }}>
                  <Typography style={fonts.b_16_b}>
                    {language.auth[2]}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              style={{ padding: "20px 20px 15px 10px" }}
            >
              <Grid item>
                <Button sx={button.type1} onClick={handleApply}>
                  <Typography sx={fonts.b_16_w}>{language.save}</Typography>
                </Button>
              </Grid>
              <Grid item style={{ paddingLeft: 10 }}>
                <Button sx={button.type1} onClick={handleClose}>
                  <Typography sx={fonts.b_16_w}>{language.close}</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Backdrop open={backDrop} style={{ zIndex: 4001, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default PersonDlg;
