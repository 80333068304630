import React from "react";

import { makeStyles } from "@mui/styles";

import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  CartesianGrid,
} from "recharts";

const styles = makeStyles(({ theme }) => ({
  chartView: {
    position: "relative",
    height: "100%",
    minWidth: "200px",
    minHeight: "200px",
    border: "1px solid",
    borderColor: "#acacac",
  },
  chartBox: { position: "absolute", width: "100%", height: "100%" },
}));

function ReLineChart(props) {
  const classes = styles();
  const data = props.data;
  const option = props.option;

  if (!Array.isArray(data) || !option)
    return (
      <div className={classes.chartView}>
        <div className={classes.chartBox}></div>
      </div>
    );

  const renderLine = (idx, line_option, dataKey) => {
    return (
      <Line
        key={`line-${idx}`}
        type={line_option.type}
        dataKey={dataKey}
        stroke={line_option.stroke}
        fillOpacity={line_option.fillOpacity}
        strokeWidth={line_option.strokeWidth}
        isAnimationActive={line_option.isAnimationActive}
      />
    );
  };

  const renderXAxis = (params) => {
    return (
      <XAxis
        hasTick
        dataKey={params.dataKey}
        dy={params.dy}
        dx={params.dx}
        domain={params.domain}
        type={params.type}
        scale={params.scale}
        ticks={params.ticks}
        tickFormatter={params.tickFormatter}
      />
    );
  };

  const renderYAxis = (params) => {
    return (
      <YAxis
        dataKey={params.dataKey}
        domain={params.domain}
        dy={params.dy}
        dx={params.dx}
        scale={params.scale}
        ticks={params.ticks}
        tickFormatter={params.tickFormatter}
      />
    );
  };

  const renderCartesianGrid = (params) => {
    return <CartesianGrid strokeDasharray={params.strokeDasharray} />;
  };

  const renderTooltip = (params) => {
    return (
      <Tooltip
        cursor={params.cursor}
        labelStyle={params.labelStyle}
        contentStyle={params.contentStyle}
        isAnimationActive={params.isAnimationActive}
        labelFormatter={params.labelFormatter}
        formatter={params.formatter}
      />
    );
  };

  return (
    <div className={classes.chartView}>
      <div className={classes.chartBox}>
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={props.margin ? props.margin : undefined}
          >
            {option.xaxis ? renderXAxis(option.xaxis) : undefined}
            {option.yaxis ? renderYAxis(option.yaxis) : undefined}
            {option.tooltip ? renderTooltip(option.tooltip) : undefined}
            {option.cartesianGrid
              ? renderCartesianGrid(option.cartesianGrid)
              : undefined}
            {option.line && option.line.count > 0
              ? [...Array(option.line.count).keys()].map((idx) => {
                  const area_option =
                    Array.isArray(option.line.option) &&
                    option.line.option.length > idx
                      ? option.line.option[idx]
                      : undefined;

                  const dataKey =
                    Array.isArray(option.line.dataKey) &&
                    option.line.dataKey.length > idx
                      ? option.line.dataKey[idx]
                      : undefined;
                  return renderLine(idx, area_option, dataKey);
                })
              : undefined}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default ReLineChart;
