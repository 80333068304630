import { createSlice } from "@reduxjs/toolkit";

const headerInitial = {
  visibility: false,
  state: 0,
  moving: false,
  isFullScreen: false,
};

const headerSlice = createSlice({
  name: "header",
  initialState: headerInitial,
  reducers: {
    setHeaderMoving: (state, { payload }) => {
      if (payload.state !== -1) state.state = payload.state;
      state.moving = payload.moving;
    },
    setHeaderVisibility: (state, { payload }) => {
      state.visibility = payload;
    },
    setFullScreen: (state, { payload }) => {
      state.isFullScreen = payload;
    },
  },
});

export default headerSlice;
