import { createSlice } from "@reduxjs/toolkit";

const authInitial = {
  isLoggedIn: false,
  name: undefined,
  uuid: undefined,
  auth_type: -1,
};

const authSlice = createSlice({
  name: "auth",
  initialState: authInitial,
  reducers: {
    login: (state, { payload }) => {
      state.isLoggedIn = true;
      state.name = payload.name;
      state.uuid = payload.uuid;
      state.auth_type = payload.auth_type;
    },
    logout: (state, _) => {
      state.isLoggedIn = authInitial.isLoggedIn;
      state.name = authInitial.name;
      state.uuid = authInitial.uuid;
      state.auth_type = authInitial.auth_type;
    },
  },
});

export default authSlice;
