import React, { useCallback, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { Grid } from "@mui/material";

import "./App.css";
import "./ag-grid-them-type-0.css";

import {
  D_Header,
  D_Login,
  D_Dashboard,
  D_Monitoring,
  D_Notification,
  D_Report,
  D_Device,
  D_Person,
} from "./containers";

import styles from "./styles";

function App(props) {
  const screenHandle = useFullScreenHandle();
  const header = useSelector((state) => state.header, shallowEqual);

  // useEffect(() => {
  //   if (!screenHandle) return;

  //   if (header.isFullScreen) screenHandle.enter();
  //   else screenHandle.exit();
  // }, [header.isFullScreen, screenHandle]);


  const handleFullScreen = useCallback(
    (state, handle) => {
      if (handle === screenHandle) {
        console.log("Screen 1 went to", state, handle);
      }
    },
    [screenHandle]
  );

  const moblieRender = () => {
    return <></>;
  };

  const desktopRender = () => {
    return (
      <Grid
        container
        direction="column"
        alignItems="stretch"
        style={styles.app}
      >
        <Grid item>
          {header.visibility ? (
            <D_Header
              {...props}
              onFullEnter={screenHandle.enter}
              onFullExit={screenHandle.exit}
            />
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs style={{ overflow: "auth" }}>
          <Routes>
            <Route exact path="/" element={<D_Dashboard {...props} />} />
            <Route path="/dashboard" element={<D_Dashboard {...props} />} />
            <Route path="/login" element={<D_Login {...props} />} />
            <Route path="/monitoring" element={<D_Monitoring {...props} />} />
            <Route
              path="/notification"
              element={<D_Notification {...props} />}
            />
            <Route path="/report" element={<D_Report {...props} />} />
            <Route path="/device" element={<D_Device {...props} />} />
            <Route path="/person" element={<D_Person {...props} />} />
          </Routes>
        </Grid>
      </Grid>
    );
  };

  return (
    <div style={styles.app}>
      <MobileView style={styles.app}>{desktopRender()}</MobileView>
      <BrowserView style={styles.app}>{desktopRender()}</BrowserView>
    </div>
  );
}

export default App;
