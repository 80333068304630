import React from "react";

import { makeStyles } from "@mui/styles";

import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  CartesianGrid,
} from "recharts";

const styles = makeStyles(({ theme }) => ({
  chartView: {
    position: "relative",
    width: "100%",
    height: "100%",
    minHeight: "100px",
    border: "1px solid",
    borderColor: "#acacac",
  },
  chartBox: { position: "absolute", width: "100%", height: "100%" },
}));

function ReAreaCharts(props) {
  const classes = styles();
  const data = props.data;
  const option = props.option;

  if (!Array.isArray(data) || !option)
    return (
      <div className={classes.chartView}>
        <div className={classes.chartBox}></div>
      </div>
    );

  const renderArea = (params, idx, area_option) => {
    return (
      <Area
        key={idx}
        type={area_option.type}
        dataKey={params.sensor}
        stroke={params.eColor}
        strokeWidth={area_option.strokeWidth ? area_option.strokeWidth : 1}
        fillOpacity={1}
        fill={`url(#${params.sensor}-Gradient)`}
        isAnimationActive={area_option.isAnimationActive}
      />
    );
  };

  const renderXAxis = (params) => {
    return (
      <XAxis
        hasTick
        color="#fff"
        dataKey={params.dataKey}
        dy={params.dy}
        dx={params.dx}
        domain={params.domain}
        type={params.type}
        scale={params.scale}
        ticks={params.ticks}
        tickFormatter={params.tickFormatter}
      />
    );
  };

  const renderYAxis = (params) => {
    return (
      <YAxis
        color="#fff"
        dataKey={params.dataKey}
        domain={params.domain}
        dy={params.dy}
        dx={params.dx}
        scale={params.scale}
        ticks={params.ticks}
        tickFormatter={params.tickFormatter}
      />
    );
  };

  const renderCartesianGrid = (params) => {
    return <CartesianGrid strokeDasharray={params.strokeDasharray} />;
  };

  const renderTooltip = (params) => {
    return (
      <Tooltip
        cursor={params.cursor}
        labelStyle={params.labelStyle}
        contentStyle={params.contentStyle}
        isAnimationActive={params.isAnimationActive}
        labelFormatter={params.labelFormatter}
        formatter={params.formatter}
      />
    );
  };

  const renderDefs = (params) => {
    const id = `${params.sensor}-Gradient`;
    return (
      <linearGradient key={id} id={id} x1="0" y1="0" x2="0" y2="1">
        {params.sColor && (
          <stop
            offset={`5%`}
            stopColor={params.sColor}
            stopOpacity={params.sOpacity}
          />
        )}
        {params.eColor && (
          <stop
            offset={`95%`}
            stopColor={params.eColor}
            stopOpacity={params.eOpacity}
          />
        )}
      </linearGradient>
    );
  };

  return (
    <div className={classes.chartView}>
      <div className={classes.chartBox}>
        <ResponsiveContainer>
          <AreaChart data={data} margin={props.margin && props.margin}>
            {option.defs && (
              <defs>
                {Array.isArray(option.defs.data) &&
                  option.defs.data.map((params, idx) => {
                    return renderDefs(params);
                  })}
              </defs>
            )}
            {option.xaxis && renderXAxis(option.xaxis)}
            {option.yaxis && renderYAxis(option.yaxis)}
            {option.tooltip && renderTooltip(option.tooltip)}
            {option.cartesianGrid && renderCartesianGrid(option.cartesianGrid)}
            {option.area &&
              Array.isArray(option.area.data) &&
              option.area.data.map((params, idx) => {
                const area_option = option.area.option;
                return renderArea(params, idx, area_option);
              })}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default ReAreaCharts;
