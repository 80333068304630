const styles = {
  company: "#303064",
  company1: "#4D4D8F",
  company2: "#222246",
  company3: "#8E56F5",
  company4: "#171730",
  white: "#ffffff",
  red: "#ff0000",
  red1: "#FF9999",
  green: "#00ff00",
  blue: "#0000ff",
  blue1: "#99CCFF",
  orange: "#ffb443",
  yellow: "#ffc215",
  yellow1: "#FFCC66",
  black: "#000000",
  gray: "#aaaaaa",
  gray1: "#acacac",
};

export default styles;
