import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  Button,
  Typography,
  TextField,
  Paper,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";

import RefreshIcon from "@mui/icons-material/Refresh";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { logout, setHeaderVisibility, setHeaderMoving } from "../../../redux";

import {
  Header,
  AgGrid,
  PersonDlg,
  MessageDlg,
  YesNoDlg,
} from "../../../components";

import icons from "../../../icons";
import fonts from "../../../fonts";
import styles from "../styles";
import { button, table } from "../components";
import { textfield, paper } from "../components";

import { sendBackend, setDateFormat } from "../../../utils";

import moment from "moment-timezone";
import Language from "../../../language";

let apiCallTime = undefined;
function Person(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = Language();

  const columnDefs = {
    sortable: true,
    resizable: true,
  };
  const gridColumnDefs = [
    {
      headerName: language.id,
      field: "user_id",
      width: 250,
      checkboxSelection: true,
    },
    { headerName: language.name, field: "name", width: 150 },
    { headerName: language.company, field: "company", width: 150 },
    { headerName: language.department, field: "department", width: 150 },
    { headerName: language.description, field: "description", width: 450 },
    { headerName: language.authType, field: "auth", width: 150 },
    { headerName: language.createdDate, field: "created_date", width: 150 },
    { headerName: language.updatedDate, field: "updated_date", width: 150 },
    { headerName: language.connectedDate, field: "connected_date", width: 150 },
  ];

  const [gridData, setGridData] = useState([]);

  const [selectedPerson, setSelectedPerson] = useState([]);

  const [search, setSearch] = useState("");
  const [selectedPage, setSelectedPage] = useState(1);
  const [endPage, setEndPage] = useState(1);

  const [openPersonDlg, setOpenPersonDlg] = useState(false);
  const [personDlgTitle, setPersonDlgTitle] = useState("");

  const messageDlgTitle = language.warning;
  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgContents, setMessageDlgContents] = useState("");

  const [openYesNoDlg, setOpenYesNoDlg] = useState(false);
  const [yesnoDlgTitle, setYesNoDlgTitle] = useState("");
  const [yesnoDlgContents, setYesNoDlgContents] = useState("");

  const [backDrop, setBackDrop] = useState(false);

  const auth = useSelector((state) => state.auth, shallowEqual);
  const header = useSelector((state) => state.header, shallowEqual);

  useEffect(() => {
    if (!auth.isLoggedIn) return navigate("/login", { replace: true });
    if (auth && auth.auth_type < 2)
      return navigate("/dashboard", { replace: true });

    onSearchTable({ search: "", page: 1 });
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (!header.visibility) dispatch(setHeaderVisibility(true));
  }, [header.visibility]);

  useEffect(() => {
    if (header.state !== 5)
      dispatch(setHeaderMoving({ state: 5, moving: false }));
  }, [header.state]);

  const onSearchTable = (params) => {
    if (!auth || !auth.uuid) return dispatch(logout());

    params.tz = moment.tz.guess();

    setBackDrop(true);
    const runTime = new Date().getTime();
    const apiPath = "api/search/person";
    sendBackend(apiPath, { uuid: auth.uuid, ...params }, (success, res) => {
      setBackDrop(false);
      if (!success || !res || !res.data) {
        if (res.data && res.data.code === 3000) {
          dispatch(logout());
          return navigate("/login", { replace: true });
        }
        return;
      }

      if (apiCallTime !== runTime) return;
      if (res.data.endPage) setEndPage(res.data.endPage);

      if (!Array.isArray(res.data.personList)) return;

      const personList = res.data.personList.map((item) => {
        let datetime = moment.utc(item.created_date).local();
        item.created_date = datetime.format("YYYY-MM-DD");
        datetime = moment.utc(item.updated_date).local();
        item.updated_date = datetime.format("YYYY-MM-DD");
        datetime = moment.utc(item.connected_date).local();
        item.connected_date = datetime.format("YYYY-MM-DD");

        item.auth = language.auth[item.auth_type];

        return item;
      });

      setGridData(personList);
    });

    apiCallTime = runTime;
  };

  const onInsertPerson = (ref) => {
    if (!ref) return;
    ref.current.api.deselectAll();
    setSelectedPerson([]);
    setPersonDlgTitle(language.insert);
    setOpenPersonDlg(true);
  };

  const onUpdatePerson = (ref) => {
    if (!ref) return;
    const selected = ref.current.api.getSelectedRows();
    if (!Array.isArray(selected) || selected.length !== 1) {
      setMessageDlgContents(language.errorMsg.personSelect);
      setOpenMessageDlg(true);
      return;
    }

    setSelectedPerson(selected);
    setPersonDlgTitle(language.update);
    setOpenPersonDlg(true);
  };

  const onDeletePerson = (ref) => {
    if (!ref) return;

    const selected = ref.current.api.getSelectedRows();
    if (!Array.isArray(selected) || selected.length < 1) {
      setMessageDlgContents(language.errorMsg.personSelect1);
      setOpenMessageDlg(true);
      return;
    }

    let person = "";
    selected.forEach((item, idx) => {
      if (idx !== 0) person += ", ";

      person += item.user_id;
    });

    setSelectedPerson(selected);
    setYesNoDlgTitle(language.delete);
    setYesNoDlgContents(language.msg.deleteMsg.replace("X", person));
    setOpenYesNoDlg(true);
  };

  const onChangePrevPage = () => {
    if (selectedPage < 2) return;

    setGridData([]);
    setSelectedPage(selectedPage - 1);
  };

  const onChangeNextPage = () => {
    if (selectedPage === endPage) return;

    setGridData([]);
    setSelectedPage(selectedPage + 1);
  };

  const handleRowDoubleClicked = (e) => {
    if (!e) return;
    e.api.deselectAll();

    setSelectedPerson([e.data]);
    setPersonDlgTitle(language.update);
    setOpenPersonDlg(true);
  };

  const onCloseMessageDlg = () => {
    setMessageDlgContents("");
    setOpenMessageDlg(false);
  };

  const onApplyYesNoDlg = () => {
    onCloseYesNoDlg();
    if (yesnoDlgTitle !== language.delete) return;

    if (!auth || !auth.uuid) return dispatch(logout());

    setBackDrop(true);
    const runTime = new Date().getTime();
    const apiPath = "api/delete/person";

    sendBackend(
      apiPath,
      { uuid: auth.uuid, personList: selectedPerson },
      (success, res) => {
        setBackDrop(false);
        if (!success || !res || !res.data) {
          if (res.data && res.data.code === 3000) {
            dispatch(logout());
            if (props.onClose) props.onClose();
            return;
          }

          return;
        }

        if (apiCallTime !== runTime) return;

        setGridData([]);
        setSearch("");
        setSelectedPage(1);
        onSearchTable({ search: "", page: 1 });
      }
    );

    apiCallTime = runTime;
  };

  const onCloseYesNoDlg = () => {
    setYesNoDlgTitle("");
    setYesNoDlgContents("");
    setOpenYesNoDlg(false);
  };

  const handlePersonDlgApply = () => {
    setSearch("");
    setSelectedPage(1);
    setGridData([]);
    onSearchTable({ search: "", page: 1 });
    handlePersonDlgClose();
  };

  const handlePersonDlgClose = () => {
    setOpenPersonDlg(false);
  };

  const renderTableView = () => {
    return (
      <Grid xs item container direction="column">
        <Grid item xs style={{ padding: "20px 0px 20px" }}>
          <AgGrid
            tableStyle={table.type1}
            tableContentsStyle={table.contents1}
            rowData={gridData}
            defaultColDef={columnDefs}
            columnDefs={gridColumnDefs}
            suppressSizeToFit={true}
            suppressMovableColumns={true}
            rowMultiSelectWithClick={true}
            rowSelection="multiple"
            overlayLoadingTemplate={"<span></span>"}
            overlayNoRowsTemplate={"<span></span>"}
            renderControl={renderTableControlView}
            onRowDoubleClicked={handleRowDoubleClicked}
          />
        </Grid>
        {endPage > 1 && (
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item style={{ padding: "10px 5px" }}>
              <Button
                sx={button.type2_unfocused}
                onClick={() => onChangePrevPage()}
              >
                {language.prev}
              </Button>
            </Grid>
            <Grid item style={{ padding: "10px 20px" }}>
              <Typography
                style={fonts.b_16_w}
              >{`${selectedPage} / ${endPage}`}</Typography>
            </Grid>
            <Grid item style={{ padding: "10px 0px 10px 5px" }}>
              <Button
                sx={button.type2_unfocused}
                onClick={() => onChangeNextPage()}
              >
                {language.next}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderTableControlView = (gridRef) => {
    return (
      <Grid container direction="row" alignItems="center" sx={table.toolbar1}>
        {/* 버튼 리스트 */}
        <Grid item xl lg md sm={12} xs={12}>
          <Grid container alignItems="center">
            {/* 추가 버튼 */}
            <Grid item style={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <Button sx={button.table} onClick={() => onInsertPerson(gridRef)}>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <AddRoundedIcon
                      sx={{ mt: 1, mr: 1 }}
                      style={fonts.b_20_w}
                    />
                  </Grid>
                  <Grid item style={{ paddingTop: "1px" }}>
                    <Typography style={fonts.b_14_w}>
                      {language.insert}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            {/* 수정 버튼 */}
            <Grid item style={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <Button sx={button.table} onClick={() => onUpdatePerson(gridRef)}>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <CreateRoundedIcon
                      sx={{ mt: 1, mr: 1 }}
                      style={fonts.b_20_w}
                    />
                  </Grid>
                  <Grid item style={{ paddingTop: "1px" }}>
                    <Typography style={fonts.b_14_w}>
                      {language.update}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            {/* 삭제 버튼 */}
            <Grid item style={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <Button sx={button.table} onClick={() => onDeletePerson(gridRef)}>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <DeleteIcon sx={{ mt: 1, mr: 1 }} style={fonts.b_20_w} />
                  </Grid>
                  <Grid item style={{ paddingTop: "1px" }}>
                    <Typography style={fonts.b_14_w}>
                      {language.delete}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            {/* 내보내기 버튼 */}
            {/* <Grid item style={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <Button sx={button.table}>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <FileDownloadRoundedIcon
                      sx={{ mt: 1, mr: 1 }}
                      style={fonts.b_20_w}
                    />
                  </Grid>
                  <Grid item style={{ paddingTop: "1px" }}>
                    <Typography style={fonts.b_14_w}>
                      {language.refresh}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid> */}
            {/* 인쇄버튼 */}
            {/* <Grid item style={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <Button sx={button.table}>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <LocalPrintshopRoundedIcon
                      sx={{ mt: 1, mr: 1 }}
                      style={fonts.b_20_w}
                    />
                  </Grid>
                  <Grid item style={{ paddingTop: "1px" }}>
                    <Typography style={fonts.b_14_w}>
                      {language.print}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid> */}
          </Grid>
        </Grid>
        {/* <Grid
          item
          xl={4}
          lg={4}
          md={5}
          sm={12}
          xs={12}
          style={{ padding: "10px 20px 0px 10px" }}
        >
          <Paper sx={paper.type1}>
            <Grid
              container
              alignItems="stretch"
              justifyContent="flex-end"
              style={{ height: "100%" }}
            >
              <Grid item xs>
                <TextField
                  value={search}
                  placeholder={language.search}
                  sx={textfield.type1}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{ visibility: search ? "none" : "hidden" }}
                        onClick={() => setSearch("")}
                      >
                        <CloseRoundedIcon style={icons.gray_20} />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => {
                    setGridData([]);
                    setSelectedPage(1);
                    onSearchTable({ search, page: 1 });
                  }}
                >
                  <SearchRoundedIcon style={fonts.b_20_w} />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}
      </Grid>
    );
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={styles.screens}
    >
      {openMessageDlg && (
        <MessageDlg
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          contents={messageDlgContents}
          onClose={onCloseMessageDlg}
        />
      )}
      {openYesNoDlg && (
        <YesNoDlg
          isOpen={openYesNoDlg}
          title={yesnoDlgTitle}
          contents={yesnoDlgContents}
          apply={language.delete}
          close={language.close}
          onApply={onApplyYesNoDlg}
          onClose={onCloseYesNoDlg}
        />
      )}
      {openPersonDlg && (
        <PersonDlg
          isOpen={openPersonDlg}
          title={personDlgTitle}
          selected={selectedPerson}
          onApply={handlePersonDlgApply}
          onClose={handlePersonDlgClose}
        />
      )}
      <Grid item>
        <Header pageName={language.personManagement} />
      </Grid>
      {renderTableView()}
      <Backdrop open={backDrop} style={{ zIndex: 1001, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default Person;
