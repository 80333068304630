import { createSlice } from "@reduxjs/toolkit";

const dashboardInitial = {
  deviceList: [],
  alarmList: [],
  latestList: [],
  nowWeather: undefined,
  sensorList: [],
  selectedDevice: undefined,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: dashboardInitial,
  reducers: {
    updateLatestData: (state, { payload }) => {
      state.deviceList = payload.deviceList;
      state.alarmList = payload.alarmList;
      state.latestList = payload.latestList;
      state.nowWeather = payload.nowWeather;
      state.sensorList = payload.sensorList;
      state.selectedDevice = payload.selectedDevice;
    },
  },
});

export default dashboardSlice;
