import React, { useRef } from "react";
import Draggable from "react-draggable";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Button,
} from "@mui/material";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import fonts from "../../fonts";
import icons from "../../icons";
import colors from "../../colors";

import { dialog, button, textfield } from "./styles";

import Language from "../../language";

function PaperComponent(props) {
  const ref = useRef(undefined);
  return (
    <Draggable
      handle="#yes-no-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      nodeRef={ref}
    >
      <div
        ref={ref}
        {...props}
        style={{ borderRadius: 8, backgroundColor: "#ffffff" }}
      />
    </Draggable>
  );
}

function YesNoDlg(props) {
  const language = Language();

  const onApply = () => {
    props.onApply();
  };

  const onClose = () => {
    props.onClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={props.isOpen}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="yes-no-dialog-title"
    >
      <DialogTitle sx={dialog.toolbar} id="yes-no-dialog-title">
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{ height: "36px" }}
        >
          <Grid item xs>
            <Typography sx={fonts.b_16_w}>{props.title}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              aria-label="close"
              onClick={onClose}
              style={{ color: "#ffffff" }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <Grid container direction="column" alignItems="stretch">
          <Grid item style={{ paddingTop: "20px", textAlign: "center" }}>
            <Typography sx={fonts.b_20_b}>{props.contents}</Typography>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            style={{ padding: "20px 20px 10px" }}
          >
            <Grid item>
              <Button sx={button.type1} onClick={onApply}>
                <Typography sx={fonts.b_16_w}>{props.apply}</Typography>
              </Button>
            </Grid>
            <Grid item style={{ paddingLeft: 10 }}>
              <Button sx={button.type1} onClick={onClose}>
                <Typography sx={fonts.b_16_w}>{props.close}</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default YesNoDlg;
