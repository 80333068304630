import { useTranslation } from "react-i18next";

const Language = () => {
  const { t } = useTranslation();

  return {
    service: t("title"),
    home: t("home"),
    monitoring: t("monitoring"),
    notification: t("notification"),
    report: t("report"),
    deviceManagement: t("deviceManagement"),
    personManagement: t("personManagement"),
    id: t("id"),
    password: t("password"),
    login: t("login"),
    logout: t("logout"),
    loginFailed: t("loginFailed"),
    location: t("location"),
    close: t("close"),
    apply: t("apply"),
    realTime: t("realTime"),
    deviceList: t("deviceList"),
    sensorState: t("sensorState"),
    deviceName: t("deviceName"),
    info: t("info"),
    sensorAbnormal: t("sensorAbnormal"),
    sensorNormal: t("sensorNormal"),
    arc: t("arc"),
    battery: t("battery"),
    co: t("co"),
    flood: t("flood"),
    float: t("float"),
    humidity: t("humidity"),
    methane: t("methane"),
    rssi: t("rssi"),
    tamper: t("tamper"),
    temperature: t("temperature"),
    weather: t("weather"),
    precipitation: t("precipitation"),
    deviceNumber: t("deviceNumber"),
    serialNumber: t("serialNumber"),
    purchaseDate: t("purchaseDate"),
    repairDate: t("repairDate"),
    batteryChangeDate: t("batteryChangeDate"),
    repairCount: t("repairCount"),
    batteryChangeCount: t("batteryChangeCount"),
    dayAgo: t("dayAgo"),
    weekAgo: t("weekAgo"),
    monthAgo: t("monthAgo"),
    period: t("period"),
    toDay: t("toDay"),
    prev: t("prev"),
    next: t("next"),
    prevDay: t("prevDay"),
    nextDay: t("nextDay"),
    sensorList: t("sensorList"),
    all: t("all"),
    dataRefresh: t("dataRefresh"),
    export: t("export"),
    print: t("print"),
    createdDate: t("createdDate"),
    contents: t("contents"),
    search: t("search"),
    graph: t("graph"),
    api: t("api"),
    refresh: t("refresh"),
    insert: t("insert"),
    update: t("update"),
    delete: t("delete"),
    address: t("address"),
    warning: t("warning"),
    min: t("min"),
    max: t("max"),
    avg: t("avg"),
    state: t("state"),
    normal: t("normal"),
    error: t("error"),
    name: t("name"),
    company: t("company"),
    department: t("department"),
    description: t("description"),
    authType: t("authType"),
    updatedDate: t("updatedDate"),
    connectedDate: t("connectedDate"),
    save: t("save"),
    auth: {
      0: t("auth.user"),
      1: t("auth.operator"),
      2: t("auth.administrator"),
    },
    msg: {
      saveMsg: t("msg.saveMsg"),
      deleteMsg: t("msg.deleteMsg"),
    },
    errorMsg: {
      inspection: t("errorMsg.inspection"),
      devcieSelect: t("errorMsg.deviceSelect"),
      devcieSelect1: t("errorMsg.deviceSelect1"),
      personSelect: t("errorMsg.personSelect"),
      personSelect1: t("errorMsg.personSelect1"),
      period: t("errorMsg.period"),
      inputID: t("errorMsg.inputID"),
      inputPassword: t("errorMsg.inputPassword"),
      inputName: t("errorMsg.inputName"),
      inputCompany: t("errorMsg.inputCompany"),
      idExists: t("errorMsg.idExists"),
      deviceName: t("errorMsg.deviceName"),
      serialNumber: t("errorMsg.serialNumber"),
      deviceAPI: t("errorMsg.deviceAPI"),
    },
  };
};

export default Language;
