import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";
import moment from "moment-timezone";

export const useBackListener = (callback) => {
  const navigator = useContext(UNSAFE_NavigationContext).navigator;

  useEffect(() => {
    const listener = ({ location, action }) => {
      console.log("listener", { location, action });
      if (action === "POP") {
        callback({ location, action });
      }
    };

    const unlisten = navigator.listen(listener);
    return unlisten;
  }, [callback, navigator]);
};

export const isEmpty = (val) => {
  if (val === null) return true;
  if (val === undefined) return true;
  if (val === "") return true;
  if (val !== null && typeof val === "object" && !Object.keys(val).length)
    return true;
  return false;
};

export const getLocale = () => {
  let defaultLocale = navigator.language || navigator.userLanguage;
  if (isEmpty(defaultLocale)) {
    defaultLocale = "en";
  }
  return defaultLocale;
};

export const setDateFormat = (datetime) => {
  let _datetime = moment(moment(datetime).format("YYYY-MM-DD HH:mm:ss"));
  return _datetime.set("hour", 0).set("minute", 0).set("second", 0);
};

export const onChangeDateToString = (datetime) => {
  return moment.utc(datetime).format("YYYY-MM-DD HH:mm:ss");
};

export const onChangeDateToStringUTC = (datetime) => {
  return moment.utc(datetime).local().format("YYYY-MM-DD HH:mm:ss");
};

export const onChangeStringToType = (type, value, bool) => {
  switch (type) {
    case "float":
      return parseFloat(value);
    case "int":
      return parseInt(value);
    case "bool":
      return parseInt(value) ? bool[1] : bool[0];
    default:
      return value;
  }
};
