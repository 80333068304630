export const i18n_en = {
  title: "IOT Integrated Control",
  realTime: "Real Time",
  home: "Home",
  monitoring: "Monitoring",
  notification: "Notification",
  report: "Report",
  deviceManagement: "Device Management",
  personManagement: "Person Management",
  deviceList: "Device List",
  id: "ID",
  password: "Password",
  login: "Login",
  logout: "Logout",
  loginFailed: "Please check your ID and password.",
  deviceName: "Device Name",
  info: "Info",
  sensorState: "Sensor State",
  sensorAbnormal: "Abnormal",
  sensorNormal: "Normal",
  arc: "Arc",
  battery: "Battery",
  co: "CO",
  flood: "Flood",
  float: "Float",
  humidity: "Humidity",
  methane: "Methane",
  rssi: "Rssi",
  tamper: "Tamper",
  temperature: "Temperature",
  weather: "Weather",
  precipitation: "Precipitation (1 hour)",
  deviceNumber: "Device Number",
  serialNumber: "Serial Number",
  purchaseDate: "Purchase Date",
  repairDate: "Repair Date",
  batteryChangeDate: "battery Change Date",
  repairCount: "Repair Count",
  batteryChangeCount: "battery Change Count",
  dayAgo: "day",
  weekAgo: "week",
  monthAgo: "month",
  period: "Period",
  toDay: "To Day",
  prev: "Prev",
  next: "Next",
  prevDay: "prev Day",
  nextDay: "next Day",
  sensorList: "Sensor List",
  all: "ALL",
  dataRefresh: "Data Refresh",
  export: "Export",
  print: "Print",
  createdDate: "Created Date",
  contents: "Contents",
  search: "Search",
  graph: "Graph",
  api: "API",
  refresh: "Refresh",
  insert: "Insert",
  update: "Update",
  delete: "Delete",
  address: "Address",
  apply: "Apply",
  close: "Close",
  warning: "Warning",
  min: "Min",
  max: "Max",
  avg: "Avg",
  state: "State",
  normal: "Normal",
  warning: "Warning",
  error: "Error",
  name: "Name",
  company: "Company",
  department: "Department",
  description: "Description",
  authType: "Auth",
  updatedDate: "Updated Date",
  connectedDate: "Connected Date",
  save: "Save",
  location: "Location",
  auth: {
    user: "User",
    operator: "Operator",
    administrator: "Administrator",
  },
  msg: {
    saveMsg: "Do you want to save it?",
    deleteMsg: "Do you want to delete 'X'?",
  },
  errorMsg: {
    inspection: "It's under inspection.",
    deviceSelect: "Please select one device.",
    deviceSelect1: "Please select device.",
    personSelect: "Please select one person.",
    personSelect1: "Please select person.",
    period: "Please check the period.",
    inputID: "Please enter person id. (At least 4 characters)",
    inputPassword: "Please enter person password. (At least 4 characters)",
    inputName: "Please enter person name.",
    inputCompany: "Please enter person company.",
    idExists: "The ID already exists.",
    deviceName: "Please enter device name",
    serialNumber: "Please enter device serial number",
    deviceAPI: "Please enter device api",
  },
  Default: {
    title: "IOT Integrated Control",
  },
  Login: {
    id: "ID",
    password: "Password",
    login: "Login",
    close: "Close",
    checkedMsg: "Please check your ID and password.",
  },
  Header: {
    logout: "Logout",
    welcome: "Welcome",
  },
  Sensor: {
    arc: "arc",
    battery: "battery",
    co: "co",
    flood: "flood",
    humidity: "humidity",
    methane: "methane",
    rssi: "rssi",
    tamper: "tamper",
    temperature: "temperature",
  },
  Graph: {
    device_list: "Device List",
    device_number: "Device Number",
    sensor_list: "Sensor List",
    device_name: "Device Name",
    serial_name: "Serial Number",
    purchase_date: "Purchase Date",
    repair_date: "Repair Date",
    battery_date: "Battery Date",
    repair_count: "Repair Count",
    battery_change_count: "Battery Change Count",
    period: "Period",
    day_age: "Day",
    week_age: "Week",
    month_age: "Month",
    monthly: "Monthly",
    weekly: "Weekly",
    daily: "Daily",
    time: "Time",
    toDay: "ToDay",
    prev: "Prev",
    next: "Next",
    search: "Search",
    close: "Cloase",
    selected_all: "Selected All",
    clear_all: "Clear All",
    normal: "Normal",
    error: "Error",
  },
  Home: {
    title: "Home",
    home: {
      real_time: "Real-time update",
      device_list: "Device List",
      device_number: "Device Number",
      sensor_status: "Sensor Status",
      weather_title: "Weather",
      location_title: "Gyeyang-dong, Incheon",
      info: "Info",
      t1h: "Temperatures",
      rn1: "1 hour of precipitation",
      reh: "Humidity",
      event_on: "Event On",
      event_clear: "Event Clear",
      sensor: "Sensor",
      close: "Close",
      no_data: "No Data",
      normal: "Normal",
      error: "Error",
    },
  },
  Monitoring: {
    title: "Monitoring",
    home: {
      real_time: "Real-time update",
      device_number: "Device Number",
      create_date: "Create Datetime",
      min: "Min",
      max: "Max",
      avg: "Avg",
      history: "History",
      next: "Next",
      prev: "Prev",
      normal: "Normal",
      warning: "Warning",
      error: "Error",
    },
    report: {
      device_list: "Device List",
      device_number: "Device Number",
      create_date: "Create Datetime",
      min: "Min",
      max: "Max",
      avg: "Avg",
      normal: "Normal",
      warning: "Warning",
      error: "Error",
      search: "Search",
      next: "Next",
      prev: "Prev",
      close: "Close",
      searchMsg: "Please Selected Device",
    },
    graph: {
      device_list: "Device List",
      device_number: "Device Number",
      create_date: "Create Datetime",
      min: "Min",
      max: "Max",
      avg: "Avg",
      normal: "Normal",
      warning: "Warning",
      error: "Error",
      search: "Search",
      status: "Status",
      searchMsg: "Please Selected Device",
    },
  },
  Event: {
    title: "Event",
    export: "Export",
    print: "Print",
    search: "Search",
    prev: "Prev",
    next: "Next",
    event_list: "Event List",
    event_on: "Event On",
    event_clear: "Event Clear",
    create_date: "Create Date",
    device_number: "Device Number",
    sensor: "Sensor",
    value: "Value",
    min_value: "Min Value",
    max_value: "Max Value",
    status: "Status",
    close: "Close",
    device_list: "Device List",
    sensor_list: "Sensor List",
    selected_all: "Selected All",
    clear_all: "Clear All",
    inspectionMsg: "Is under inspection",
  },
  Management: {
    title: "Management",
    refresh: "Refresh",
    add: "Add",
    modified: "Modified",
    remove: "Remove",
    export: "Export",
    print: "Print",
    search: "Search",
    device_number: "Device Number",
    device_name: "Device Name",
    serial_number: "Serial Number",
    api: "API",
    purchase_date: "Purchase Date",
    repair_date: "Repair Date",
    battery_date: "Battery Date",
    repair_count: "Repair Count",
    battery_change_count: "Battery Change Count",
    info: "Info",
    no_selected_msg: "Please Selected Device",
    mul_selected_msg: "Please Selected One Device",
    retry_msg: "Please Retry",
    remove_check_msg: "Are you sure you want to delete the device?",
    inspectionMsg: "Is under inspection",
    close: "Close",
    dialog: {
      info_placeholder: "Up to 500 characters",
      latitude: "Latitude",
      longitude: "Longitude",
      save: "Save",
      cancel: "Cancel",
      save_check_msg: "Do you want to save",
      yes: "Yes",
      no: "No",
      close: "Close",
    },
  },
  Report: {
    title: "Report",
    graph: "Graph",
    export: "Export",
    print: "Print",
    search: "Search",
    prev: "Prev",
    next: "Next",
    device_list: "Device List",
    sensor_list: "Sensor List",
    selected_all: "Selected All",
    clear_all: "Clear All",
    close: "Close",
    event_on: "Event On",
    event_clear: "Event Clear",
    inspectionMsg: "Is under inspection",
  },
  DeviceManagement: {
    title: "DeviceManagement",
    device_number: "Device Number",
    device_name: "Device Name",
    device_serial_number: "Serial Number",
    device_api: "API",
    device_purchase_date: "Purchase Date",
    device_repair_date: "Repair Date",
    device_battery_date: "Battery Date",
    device_repair_count: "Repair Count",
    device_battery_change_count: "Battery Change Count",
    device_info: "Info",
    device_latitude: "Latitude",
    device_longitude: "Longitude",
    created_date: "Created Date",
    updated_date: "Updated Date",
    connected_date: "Connected Date",
    refresh: "Refresh",
    insert: "Insert",
    update: "Update",
    delete: "Delete",
    export: "Export",
    print: "Print",
    search: "Search",
    ok: "Ok",
    confirm: "Confirm",
    apply: "Apply",
    close: "Close",
    yes: "Yes",
    no: "No",
    select_one_msg: "Please Select One User",
    no_select_msg: "Please Select User",
    delete_user_msg: "Are you sure you want to delete 'X' ?",
    inspection_msg: "Is under inspection",
    apply_msg: "Would you like to apply?",
    device_number_msg: "Please input Device Number",
    device_name_msg: "Please input Device Name",
    device_serial_number_msg: "Please input Device Serial Number",
  },
  UserManagement: {
    title: "UserManagement",
    id: "ID",
    password: "Password",
    name: "Name",
    company: "Company",
    department: "Department",
    description: "Description",
    auth_type: "Auth Type",
    created_date: "Created Date",
    updated_date: "Updated Date",
    connected_date: "Connected Date",
    refresh: "Refresh",
    insert: "Insert",
    update: "Update",
    delete: "Delete",
    export: "Export",
    print: "Print",
    search: "Search",
    auth0: "Guest",
    auth1: "Operator",
    auth2: "Administrator",
    ok: "Ok",
    confirm: "Confirm",
    apply: "Apply",
    close: "Close",
    yes: "Yes",
    no: "No",
    select_one_msg: "Please Select One User",
    no_select_msg: "Please Select User",
    delete_user_msg: "Are you sure you want to delete 'X' ?",
    inspection_msg: "Is under inspection",
    apply_msg: "Would you like to apply?",
    id_msg: "Please input ID (at least 4 characters)",
    password_msg: "Please input Password (at least 4 characters)",
    name_msg: "Please input Name",
  },
};
