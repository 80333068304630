import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Grid,
  Button,
  IconButton,
  Typography,
  TextField,
  Paper,
  List,
  ListItem,
  Backdrop,
  CircularProgress,
  Popover,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import MinimizeRoundedIcon from "@mui/icons-material/MinimizeRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";

import { AreaChart, MessageDlg } from "..";

import fonts from "../../fonts";
import icons from "../../icons";

import { containers } from "./styles";
import { button, combobox, list, table, datepicker } from "./styles";
import { textfield, paper, iconbutton } from "./styles";

import Language from "../../language";
import { logout } from "../../redux";
import {
  sendBackend,
  onChangeDateToStringUTC,
  onChangeStringToType,
  setDateFormat,
} from "../../utils";

import moment from "moment-timezone";

let apiCallTime = undefined;
function SensorGraphDlg(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = Language();

  const [deviceList, setDeviceList] = useState([]);
  const [sensorList, setSensorList] = useState([]);
  const [sensorType, setSensorType] = useState({});

  const [selectedDevice, setSelectedDevice] = useState(undefined);
  const [selectedSensor, setSelectedSensor] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(0);

  const [startDate, setStartDate] = useState(
    setDateFormat(moment().local().add(-1, "days"))
  );
  const [endDate, setEndDate] = useState(setDateFormat(moment().local()));

  const [graphData, setGraphData] = useState(undefined);

  const [deviceListRef, setDeviceListRef] = useState(undefined);
  const openDeviceList = Boolean(deviceListRef);

  const [sensorListRef, setSensorListRef] = useState(undefined);
  const openSensorList = Boolean(sensorListRef);

  const messageDlgTitle = language.warning;
  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgContents, setMessageDlgContents] = useState("");

  const [backDrop, setBackDrop] = useState(false);

  const auth = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    if (!auth.isLoggedIn) return onClose();
  }, [auth.isLoggedIn]);

  useEffect(() => {
    if (props.device) {
      const device =
        Array.isArray(props.device) && props.device.length > 0
          ? { ...props.device[0] }
          : { ...props.device };

      if (device.purchase_date)
        device.purchase_date = onChangeDateToStringUTC(device.purchase_date);
      else device.purchase_date = "-";
      if (device.repair_date)
        device.repair_date = onChangeDateToStringUTC(device.repair_date);
      else device.repair_date = "-";
      if (device.battery_change_date)
        device.battery_change_date = onChangeDateToStringUTC(
          device.battery_change_date
        );
      else device.battery_change_date = "-";

      if (!device.repair_count) device.repair_count = 0;
      if (!device.battery_change_count) device.battery_change_count = 0;

      setSelectedDevice(device);
      onSearchDeviceList(
        setTimeout(() =>
          onSearchSensorList({ deviceList: [device] }, (params) => {
            if (props.sensor) setSelectedSensor([props.sensor]);
            else if (Array.isArray(params)) setSelectedSensor(params);
          })
        )
      );
    }
  }, [props.device, props.sensor]);

  const onSearchDeviceList = (callback) => {
    if (!auth || !auth.uuid) return onClose();

    setBackDrop(true);
    const runTime = new Date().getTime();
    const apiPath = "api/search/device";
    sendBackend(apiPath, { uuid: auth.uuid }, (success, res) => {
      setBackDrop(false);
      if (!success || !res || !res.data) {
        if (res.data && res.data.code === 3000) {
          dispatch(logout());
          return navigate("/login", { replace: true });
        }
        return;
      }

      if (apiCallTime !== runTime) return;

      if (res.data.deviceList) setDeviceList(res.data.deviceList);
      if (callback) callback();
    });

    apiCallTime = runTime;
  };

  const onSearchSensorList = (params, callback) => {
    if (!auth || !auth.uuid) return onClose();

    setBackDrop(true);
    const runTime = new Date().getTime();
    const apiPath = "api/search/sensor";
    sendBackend(apiPath, { uuid: auth.uuid, ...params }, (success, res) => {
      setBackDrop(false);
      if (!success || !res || !res.data) {
        if (res.data && res.data.code === 3000) {
          dispatch(logout());
          return navigate("/login", { replace: true });
        }
        return;
      }

      if (apiCallTime !== runTime) return;

      if (res.data.sensorList) {
        const _sensorType = {};
        res.data.sensorList.forEach((item) => {
          _sensorType[item.sensor] = item.type;
        });

        setSensorType(_sensorType);
        setSensorList(res.data.sensorList);

        const device = params.deviceList[0];
        onSearchGraphData({ device, startDate, endDate });
      }

      if (callback) callback(res.data.sensorList);
    });

    apiCallTime = runTime;
  };

  const onSearchGraphData = (params) => {
    if (!auth || !auth.uuid) return onClose();

    params.startDate = moment(params.startDate).utc();
    params.endDate = moment(params.endDate).utc().add(1, "days");

    if (params.endDate.valueOf() < params.startDate.valueOf()) {
      setMessageDlgContents(language.errorMsg.period);
      setOpenMessageDlg(true);
      return;
    }

    setBackDrop(true);
    const runTime = new Date().getTime();
    const apiPath = "api/search/graph";

    sendBackend(apiPath, { uuid: auth.uuid, ...params }, (success, res) => {
      setBackDrop(false);
      if (!success || !res || !res.data) {
        if (res.data && res.data.code === 3000) {
          dispatch(logout());
          return navigate("/login", { replace: true });
        }
        return;
      }

      if (apiCallTime !== runTime) return;

      if (Array.isArray(res.data.graphData) && res.data.graphData.length > 0) {
        let _graphData = res.data.graphData.map((item) => {
          item.datetime = moment.utc(item.created_date).local();
          item.created_date = item.datetime.format("YYYY-MM-DD HH:mm:ss");

          if (!item.sensor || !item.state) return item;

          item.sensor = item.sensor.split(",");
          item.state = item.state.split(",");

          item.sensor.forEach((sensor, idx) => {
            item[sensor] = onChangeStringToType(
              sensorType[sensor],
              item.state[idx],
              [language.normal, language.error]
            );
          });

          delete item.sensor;
          delete item.state;
          return item;
        });

        setGraphData(_graphData);
      } else {
        const times = params.endDate.diff(params.startDate, "hours");
        if (!times) return;

        const _graphData = [];
        const created_date = params.startDate.local().add(1, "hours");
        for (const time of new Array(times / 2 - 1)) {
          _graphData.push({
            created_date: created_date.format("YYYY-MM-DD HH:mm:ss"),
          });
          created_date.add(2, "hours");
        }

        setGraphData(_graphData);
      }
    });

    apiCallTime = runTime;
  };

  const handleDeviceList = (e) => {
    const target = e.currentTarget;
    onSearchDeviceList(setTimeout(() => setDeviceListRef(target)));
  };

  const handleSensorList = (e) => {
    const target = e.currentTarget;
    setSensorListRef(target);
  };

  const onChangeSensor = (val) => {
    if (!val) {
      if (sensorList.length === selectedSensor.length) {
        setSelectedSensor([]);
      } else setSelectedSensor([...sensorList]);
    } else {
      let selected = [...selectedSensor];

      const checked = selected.findIndex((item) => item.sensor === val.sensor);

      if (checked !== -1) selected.splice(checked, 1);
      else selected.push(val);

      setSelectedSensor(selected);
    }

    setSensorListRef(undefined);
  };

  const onChangeDevice = (params) => {
    setSelectedDevice(params);
    setDeviceListRef(undefined);
    setSelectedSensor([]);
    setTimeout(() => onSearchSensorList({ deviceList: [params] }, undefined));
  };

  const onChangePeriodType = (type, end = undefined) => {
    let start = undefined;
    switch (type) {
      case 0:
        start = setDateFormat(moment(end ? end : endDate).add(-1, "days"));
        break;
      case 1:
        start = setDateFormat(moment(end ? end : endDate).add(-1, "weeks"));
        break;
      case 2:
        start = setDateFormat(moment(end ? end : endDate).add(-1, "months"));
        break;
      case 3:
        break;
      default:
        return;
    }

    setSelectedPeriod(type);

    if (start) setStartDate(start);
    setTimeout(() =>
      onSearchGraphData({ device: selectedDevice, startDate: start, endDate })
    );
  };

  const onChangeStartDate = (val) => {
    let end = endDate;
    const start = setDateFormat(moment(val));

    if (start.valueOf() > endDate.valueOf())
      end = setDateFormat(moment(val).add(1, "days"));

    setStartDate(start);
    setEndDate(end);

    setTimeout(() =>
      onSearchGraphData({
        device: selectedDevice,
        startDate: start,
        endDate: end,
      })
    );
  };

  const onChangeEndDate = (val) => {
    let start = startDate;
    const end = setDateFormat(moment(val));

    if (end.valueOf() < startDate.valueOf())
      start = setDateFormat(moment(val).add(-1, "days"));

    setStartDate(start);
    setEndDate(end);
    setTimeout(() =>
      onSearchGraphData({
        device: selectedDevice,
        startDate: start,
        endDate: end,
      })
    );
  };

  const handleControlButton = (idx) => {
    switch (idx) {
      case 0:
        return onClickPrev();
      case 1:
        return onClickToDay();
      case 2:
        return onClickNext();
      default:
        return;
    }
  };

  const onClickPrev = () => {
    let start = setDateFormat(moment(startDate).add(-1, "days"));
    let end = setDateFormat(moment(endDate).add(-1, "days"));

    setStartDate(start);
    setEndDate(end);
    setTimeout(() =>
      onSearchGraphData({
        device: selectedDevice,
        startDate: start,
        endDate: end,
      })
    );
  };

  const onClickNext = () => {
    let start = setDateFormat(moment(startDate).add(1, "days"));
    let end = setDateFormat(moment(endDate).add(1, "days"));

    setStartDate(start);
    setEndDate(end);
    setTimeout(() =>
      onSearchGraphData({
        device: selectedDevice,
        startDate: start,
        endDate: end,
      })
    );
  };

  const onClickToDay = () => {
    let end = setDateFormat(moment().local());
    let start = startDate;

    if (end.valueOf() < start.valueOf())
      start = setDateFormat(moment().local().add(-1, "days"));

    setStartDate(start);
    setEndDate(end);

    if (selectedPeriod !== 3)
      setTimeout(() => onChangePeriodType(selectedPeriod, end));
    else
      setTimeout(() =>
        onSearchGraphData({
          device: selectedDevice,
          startDate: start,
          endDate: end,
        })
      );
  };

  const onClose = () => {
    props.onClose();
  };

  const onCloseMessageDlg = () => {
    setMessageDlgContents("");
    setOpenMessageDlg(false);
  };

  const renderDeviceView = () => {
    const deviceName = selectedDevice
      ? `${language.deviceName} [${selectedDevice.device_name}]`
      : language.deviceList;
    const device = {
      serialNumber: selectedDevice ? selectedDevice.serial_num : "-",
      purchaseDate: selectedDevice ? selectedDevice.purchase_date : "-",
      repairDate: selectedDevice ? selectedDevice.repair_date : "-",
      batteryChangeDate: selectedDevice
        ? selectedDevice.battery_change_date
        : "-",
      repairCount: selectedDevice ? selectedDevice.repair_count : "-",
      batteryChangeCount: selectedDevice
        ? selectedDevice.battery_change_count
        : "-",
    };

    return (
      <Grid container alignItems="center" style={{ padding: "10px 0px 20px" }}>
        <Grid item xl={2} lg={3} md={4} sm={12} xs={12}>
          <Grid container alignItems="center" style={{ minWidth: "230px" }}>
            {/* 뒤로가기 */}
            <Grid item xl={2} lg={2} md={2}>
              <IconButton sx={iconbutton.type1} onClick={onClose}>
                <ArrowBackRoundedIcon />
              </IconButton>
            </Grid>
            {/* 장비 목록 드롭다운 */}
            <Grid item xl={10} lg={10} md={10}>
              <Button sx={combobox.type1} onClick={handleDeviceList}>
                <Typography style={fonts.n_14_w}>{deviceName}</Typography>
                <ArrowDropDownRoundedIcon sx={icons.white_32} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* 장비 정보 */}
        <Grid item xl lg md sm xs style={{ padding: "5px" }}>
          <Grid container>
            {Object.keys(device).map((val, idx) => {
              return (
                <Grid item key={`device-data-${idx}`}>
                  <Grid container>
                    <Grid item style={{ padding: "0px 10px" }}>
                      <Typography
                        style={fonts.n_14_w}
                      >{`${language[val]}: `}</Typography>
                    </Grid>
                    <Grid item style={{ padding: "0px 5px" }}>
                      <Typography style={fonts.n_14_gr}>
                        {device[val]}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderPeriodView = () => {
    const periodType = [
      language.dayAgo,
      language.weekAgo,
      language.monthAgo,
      language.period,
    ];

    const controlButton = [language.prevDay, language.toDay, language.nextDay];
    const period = `${startDate.format("YYYY-MM-DD")} ~ ${endDate.format(
      "YYYY-MM-DD"
    )}`;

    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        style={containers.periodBar}
      >
        {/* 기간 타입 버튼 */}
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <Grid container>
            {periodType.map((val, idx) => {
              const isFocused = selectedPeriod === idx;
              return (
                <Grid
                  xl={3}
                  lg={3}
                  md={6}
                  sm={3}
                  xs={3}
                  item
                  key={`period-type-${idx}`}
                  style={{ padding: "5px" }}
                >
                  <Button
                    disabled={isFocused}
                    sx={
                      isFocused ? button.type1_focused : button.type1_unfocused
                    }
                    onClick={() => {
                      onChangePeriodType(idx);
                    }}
                  >
                    <Typography style={fonts.n_16_w}>{val}</Typography>
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        {/* 기간 표시 및 설정 */}
        <Grid item xl lg md={6} sm={12} xs={12} style={{ padding: "5px" }}>
          {selectedPeriod !== 3 ? (
            <Grid container justifyContent="center">
              <Grid item style={containers.periodView}>
                <Typography style={fonts.n_18_w}>{period}</Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    sx={datepicker.type1}
                    value={startDate.toDate()}
                    format="yyyy-MM-dd"
                    onChange={onChangeStartDate}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item style={{ padding: "0px 10px" }}>
                <Typography style={fonts.b_16_w}>{"-"}</Typography>
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    sx={datepicker.type1}
                    value={endDate.toDate()}
                    format="yyyy-MM-dd"
                    onChange={onChangeEndDate}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          )}
        </Grid>
        {/* 이동 버튼 */}
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <Grid container justifyContent="flex-end">
            {controlButton.map((val, idx) => {
              return (
                <Grid
                  xl={3}
                  lg={3}
                  md={4}
                  sm={4}
                  xs={4}
                  item
                  key={`date-menu-${idx}`}
                  style={{ padding: "5px" }}
                >
                  <Button
                    sx={button.type1_unfocused}
                    onClick={() => handleControlButton(idx)}
                  >
                    <Typography style={fonts.n_16_w}>{val}</Typography>
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderSensorItem = (val, idx) => {
    const title = val.sensor;
    return (
      <Grid item key={`sensor-item-${idx}`} style={{ padding: "0px 10px" }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <svg width={0} height={0}>
              <linearGradient
                key={`${title}-${idx}-linearColors`}
                id={`${title}-${idx}-linearColors`}
                x1={0}
                y1={0}
                x2={0}
                y2={1}
              >
                <stop
                  offset={0}
                  stopColor={val.sColor}
                  stopOpacity={val.sOpacity}
                />
                <stop
                  offset={1}
                  stopColor={val.eColor}
                  stopOpacity={val.eOpacity}
                />
              </linearGradient>
            </svg>
            <SquareRoundedIcon
              style={{ verticalAlign: "middle" }}
              sx={{ fill: `url(#${title}-${idx}-linearColors)` }}
            />
          </Grid>
          <Grid item style={{ padding: "0px 5px" }}>
            <Typography style={fonts.n_14_w}>{language[title]}</Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderGraphView = () => {
    const _sensorList = `${language.sensorList} [${selectedSensor.length} / ${sensorList.length}]`;
    let _minValue = 0;
    let _maxValue = 0;

    return (
      <Grid xs item container direction="column" style={containers.graphView}>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ padding: "20px 20px 10px" }}
        >
          <Grid item>
            <Button
              sx={button.type1_unfocused}
              onClick={() =>
                onSearchGraphData({
                  device: selectedDevice,
                  startDate,
                  endDate,
                })
              }
            >
              <Typography style={fonts.n_16_w}>
                {language.dataRefresh}
              </Typography>
            </Button>
          </Grid>
          <Grid
            xs
            item
            container
            justifyContent="center"
            style={{ overflow: "auto" }}
          >
            {Array.isArray(selectedSensor) &&
              selectedSensor.map((val, idx) => {
                return renderSensorItem(val, idx);
              })}
          </Grid>
          <Grid item style={{ minWidth: "180px" }}>
            <Button sx={combobox.type1} onClick={handleSensorList}>
              <Typography style={fonts.n_14_w}>{_sensorList}</Typography>
              <ArrowDropDownRoundedIcon sx={icons.white_32} />
            </Button>
          </Grid>
        </Grid>
        <Grid xs item style={{ padding: "10px 20px 20px", minHeight: "300px" }}>
          <AreaChart
            data={graphData}
            margin={{ top: 20, right: 40, left: 20, bottom: 0 }}
            option={{
              xaxis: {
                dataKey: "created_date",
                domain: ["auto", "auto"],
                scale: "auto",
                type: "category",
                dy: 5,
                dx: -5,
              },
              yaxis: {
                domain: ["auto", "auto"],
              },
              defs: {
                data: sensorList,
              },
              area: {
                data: selectedSensor,
                option: {
                  type: "monotone",
                  strokeWidth: 3,
                  isAnimationActive: false,
                },
              },
              cartesianGrid: { strokeDasharray: "3 3" },
              tooltip: {
                cursor: { fill: "#000000" },
                labelStyle: { color: "#ffffff" },
                contentStyle: { backgroundColor: "#000000" },
                isAnimationActive: false,
                labelFormatter: (val) => {
                  if (!val) return "";
                  let datetime = moment(val);
                  return datetime.format("YYYY-MM-DD HH:mm:ss");
                },
                formatter: (val, dataKey, tmp) => {
                  let _sensor = selectedSensor.find((_item) => {
                    if (_item.sensor === dataKey) return _item;
                  });

                  switch (_sensor.type) {
                    case "bool":
                      return val;
                    case "float":
                      return `${parseFloat(val)} ${_sensor.unit}`;
                    case "int":
                      return `${parseInt(val)} ${_sensor.unit}`;
                    default:
                      return val;
                  }
                },
              },
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const renderDevicePopover = () => {
    return (
      <Popover
        anchorEl={deviceListRef}
        open={openDeviceList}
        onClose={() => setDeviceListRef(undefined)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock={true}
        style={{ marginTop: "2px" }}
      >
        <List sx={containers.comboboxView}>
          {Array.isArray(deviceList) &&
            deviceList.map((val, idx) => {
              return (
                <ListItem key={`device-combo-${idx}`} sx={list.item1}>
                  <Button
                    sx={combobox.item1}
                    onClick={() => onChangeDevice(val)}
                  >
                    <Typography style={fonts.n_14_w}>
                      {val.device_name}
                    </Typography>
                  </Button>
                </ListItem>
              );
            })}
        </List>
      </Popover>
    );
  };

  const renderSensorPopupItem = (val, idx) => {
    let isSelected = false;
    const sensorName = val ? language[val.sensor] : language.all;

    if (val) {
      const checked = selectedSensor.findIndex(
        (item) => item.sensor === val.sensor
      );
      if (checked !== -1) isSelected = true;
    } else {
      if (sensorList.length !== selectedSensor.length) isSelected = false;
      else isSelected = true;
    }
    const iconStyle = { paddingRight: "10px" };
    return (
      <ListItem key={`sensor-combo-${idx}`} sx={list.item1}>
        <Button sx={combobox.item2} onClick={() => onChangeSensor(val)}>
          {isSelected ? (
            <CheckBoxRoundedIcon sx={{ ...icons.gray_24, ...iconStyle }} />
          ) : (
            <CheckBoxOutlineBlankRoundedIcon
              sx={{ ...icons.gray_24, ...iconStyle }}
            />
          )}
          <Typography style={fonts.n_14_w}>{sensorName}</Typography>
        </Button>
      </ListItem>
    );
  };

  const renderSensorPopup = () => {
    return (
      <Popover
        anchorEl={sensorListRef}
        open={openSensorList}
        onClose={() => setSensorListRef(undefined)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock={true}
        style={{ marginTop: "2px" }}
      >
        <List sx={containers.comboboxView}>
          {renderSensorPopupItem(undefined, -1)}
          {Array.isArray(sensorList) &&
            sensorList.map((val, idx) => {
              return renderSensorPopupItem(val, idx);
            })}
        </List>
      </Popover>
    );
  };

  if (!auth.isLoggedIn) {
    navigate("/login", { replace: true });
    return <></>;
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={containers.contents}
    >
      {openMessageDlg && (
        <MessageDlg
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          contents={messageDlgContents}
          onClose={onCloseMessageDlg}
        />
      )}
      {openDeviceList && renderDevicePopover()}
      {openSensorList && renderSensorPopup()}
      {/* 상단 장비 정보 바 */}
      {renderDeviceView()}
      {/* 기간 설정 바 */}
      {renderPeriodView()}
      {/* 그래프 */}
      {renderGraphView()}
      <Backdrop open={backDrop} style={{ zIndex: 1001, color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}

export default SensorGraphDlg;
